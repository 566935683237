
import React, { Fragment, useState } from 'react';
import { BaseUrl } from '../constants/global'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer,Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

async function loginUser(credentials) {
    
    var token1 = JSON.parse('[{"token":""}]');
   
    var url = `${BaseUrl}/api/token`;
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };
    
    let bodyParameters = credentials;

    var resp = await axios.post(
        url,
        bodyParameters,
        config
    ).then((response) => {                
        token1 = response.data;
    }).catch((error) => {

    });

     return token1;
}

function Login({ setToken }) {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    
    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const handleRegister = () => {
        navigate('/register');
    };

    const handleGuestLogin = () => {
        navigate('/guestlogin');
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleSubmit = async e => {
        var btnLogin = document.getElementById("btnLogin");
        btnLogin.disabled = true;

        if(e) e.preventDefault();
       
        if(userName!='' && password!='')
        {
            const token = await loginUser({
                userName,
                password
            });

            setpopupMsgType("light");
            if(token.status.toUpperCase()=="SUCCESS")
            {
                setpopupHeaderMsg(token.header);
                setpopupDetailedMsg(token.message);
                setShowPopupMsg(true);
                setToken(token);    
                document.body.style.backgroundColor = '#fff';

                if(token!=""){
                    navigate('/');
                }
            }
            else
            {
                setpopupMsgType("warning");
                setpopupHeaderMsg(token.header);
                setpopupDetailedMsg(token.message);
                setShowPopupMsg(true);
                btnLogin.disabled = false;
            }
        }        
        else{
            setpopupMsgType("warning");
            setpopupHeaderMsg("Invalid Credential");
            setpopupDetailedMsg("Please enter userid and password.");
            setShowPopupMsg(true);
            btnLogin.disabled = false;
        }
        
        btnLogin.disabled = false;
    }

    return (
        <Fragment>

            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* <!-- Section Login --> */}
            <section id="verify-userWrapper">
                <div className="row cus-row">
                    <div className="col-lg-6 col-md-6">
                        <div className="verify_userWrapperLeft">
                            <div className="verify_userLeftOuter">
                                <img src="../assets/images/logo.png" width="100px" alt="" />
                                <h3>User Login</h3>
                                <ul className="list-unstyled mb-0 d-flex gap-4 justify-content-center">
                                    <li className="active"><a  onClick={handleLogin}>Login</a></li>
                                    <li><a onClick={handleRegister}>Register</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="verify_userWrapperRight">
                                <img src="../assets/images/logo.png" width="100px" alt="" />
                                <div className="form-group w-75 mt-4">
                                    <div className="form-row mb-3">
                                        <label htmlFor="userName">{t('usernameoremail')}</label>
                                        <input type="text" id="userName" className="form-control" onChange={e => setUserName(e.target.value)} />
                                    </div>
                                    <div className="form-row mb-3">
                                        <label htmlFor="userPass">{t('password')}</label>
                                        <input type="password" id="userPass" className="form-control" onChange={e => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-check mb-3 d-flex justify-content-between">
                                        <div>
                                            <input className="form-check-input rounded-0" type="checkbox" value="" id="rememberCheck" />
                                            <label className="form-check-label" htmlFor="rememberCheck">{t('rememberme')}</label>
                                        </div>
                                        {/* <a href="forgotpassword.php">{t('forgotpassword')} ?</a> */}
                                    </div>
                                    <div className="form-row mb-3 d-flex gap-3">
                                        <button className="hero_btn w-50" id='btnLogin' type="submit">{t('login')}</button>
                                        {/* <Link className="hero_btn btn_invers w-50" to="/guestlogin">Guest Login</Link> */}
                                        <button className="hero_btn btn_invers w-50" onClick={handleRegister} >{t('register')}</button>

                                    </div>
                                    <p>{t('donothavelogindetail')}? <a onClick={handleRegister}><b>{t('register')}</b></a></p>
                                    <p>{t('downloadapp')} :</p>
                                    <img src="../assets/images/icons/google-play.svg" width="100px" alt="" />
                                    <img src="../assets/images/icons/apple.svg" width="100px" alt="" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* <!-- End Section Login --> */}
        </Fragment>
    )
}
export default Login;

// Login.propTypes = {
//     setToken: PropTypes.func.isRequired
//   }
