import React, { useEffect, useState, Fragment } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl } from '../constants/global'
import AccNav from '../components/AccNav'
import { ToastContainer, Toast } from 'react-bootstrap';
import {   
    Link
  } from "react-router-dom";


function MySavedSearch() {
    const { token, setToken } = useToken();
    const [saveSerches, setSaveSerches] = useState([]);   
    const [saveSercheParams, setSaveSercheParams] = useState([]);

    async  function getSaveSearchesData()
    {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(`${BaseUrl}/api/v1/myaccount/getusersavedsearchdata`,config).then((response) => {            
            setSaveSerches(response.data.datasavedsearch);
            setSaveSercheParams(response.data.datasavedsearchparams);
           
        });
    }


    useEffect(() => {

       
        getSaveSearchesData();

      

    }, []);

    const SavedSearchParamsItem = (props) => {

        let single_search_params_items = saveSercheParams.filter((e) => e.userSearchId === props.userSearchId);
        if (single_search_params_items.length > 0) {

            return( 
                <div className="saved_searchInner">
                    <div className="saved_searchHeading d-flex justify-content-between align-items-center ">
                        <h5 className="mb-0">{props.searchName}</h5>
                        <ul className="mb-0 list-unstyled d-flex gap-3 me-sm-3 me-0">
                            <li>
                                <Link  to={`/${single_search_params_items[0].itemType=="2"?"giaitemsearch":single_search_params_items[0].itemType=="3"?"exclusiveitemsearch":"itemsearch"}/${props.userSearchId}`}><img src="../assets/images/ssedit.svg" width="20px" alt="" /></Link>
                            </li>
                            <li>
                                <Link ><img src="../assets/images/sstrash.svg" width="20px" alt="" /></Link>
                            </li>
                            <li>
                                <Link to={`/${single_search_params_items[0].itemType=="2"?"giaitemlist":single_search_params_items[0].itemType=="3"?"exclusiveitemlist":"itemlist"}/${props.userSearchId}`}><img src="../assets/images/sssearch.svg" width="20px" alt="" /></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="saved_searchBody">
                        <ul className="list-unstyled d-flex flex-wrap mb-0">
                            <li><p>Type : <span> {single_search_params_items[0].itemType=="2"?"GIA":single_search_params_items[0].itemType=="3"?"EXCLUSIVE":"DEFAULT"} </span></p></li>
                            <li><p>Shape : <span> {single_search_params_items[0].shape_Params} </span></p></li>
                            <li><p>COlor : <span> {single_search_params_items[0].color_Params} </span></p></li>
                            <li><p>Carat : <span> {single_search_params_items[0].carat_Params} </span></p></li>
                            <li><p>Clarity : <span> {single_search_params_items[0].clarity_Params} </span></p></li>
                        </ul>
                    </div>
                </div>
            )
        }
        else {
            return <div></div>
        }
    }

    return (
        <Fragment>
            <Header />
            <Sidebar />
            <section id="profile-pageSection">
                <AccNav />

                <div className="profile-pageSection mt-2 mb-4">
                    <div className="max-container container">

                       
                            <div className="saved_searchwrapper">
                                {
                                      saveSerches.map(savesearch => (
                                            <div>
                                                <SavedSearchParamsItem userSearchId={savesearch.userSearchId} searchName={savesearch.searchName}/>
                                            </div>
                                        ))
                                    }
                                
                            

                                {/* <div className="saved_searchInner">
                                    <div className="saved_searchHeading d-flex justify-content-between align-items-center ">
                                        <h5 className="mb-0">Search Title</h5>
                                        <ul className="mb-0 list-unstyled d-flex gap-3 me-sm-3 me-0">
                                            <li>
                                                <button><img src="../assets/images/ssedit.svg" width="20px" alt="" /></button>
                                            </li>
                                            <li>
                                                <button><img src="../assets/images/sstrash.svg" width="20px" alt="" /></button>
                                            </li>
                                            <li>
                                                <button><img src="../assets/images/sssearch.svg" width="20px" alt="" /></button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="saved_searchBody">
                                        <ul className="list-unstyled d-flex flex-wrap mb-0">
                                            <li><p>Shape : <span> em</span></p></li>
                                            <li><p>COlor : <span> em</span></p></li>
                                            <li><p>Tinge : <span> em</span></p></li>
                                            <li><p>cut : <span> em</span></p></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="saved_searchInner">
                                    <div className="saved_searchHeading d-flex justify-content-between align-items-center ">
                                        <h5 className="mb-0">Search Title</h5>
                                        <ul className="mb-0 list-unstyled d-flex gap-3 me-sm-3 me-0">
                                            <li>
                                                <button><img src="../assets/images/ssedit.svg" width="20px" alt="" /></button>
                                            </li>
                                            <li>
                                                <button><img src="../assets/images/sstrash.svg" width="20px" alt="" /></button>
                                            </li>
                                            <li>
                                                <button><img src="../assets/images/sssearch.svg" width="20px" alt="" /></button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="saved_searchBody">
                                        <ul className="list-unstyled d-flex flex-wrap mb-0">
                                            <li><p>Shape : <span> em</span></p></li>
                                            <li><p>COlor : <span> em</span></p></li>
                                            <li><p>Tinge : <span> em</span></p></li>
                                            <li><p>cut : <span> em</span></p></li>
                                        </ul>
                                    </div>
                                </div> */}


                            </div>


                            

                    </div>
                </div>
            </section>


        </Fragment>
    )
}
export default MySavedSearch;

