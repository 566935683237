import React, { useEffect,useState,useRef,Fragment } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import { useNavigate,useParams } from 'react-router-dom';
import { ToastContainer,Toast } from 'react-bootstrap';
import { Modal,Form,Input } from 'antd';
import Select from "react-select";
import { isVisible } from '@testing-library/user-event/dist/utils';
import { useTranslation } from 'react-i18next';
import  commonFunctionClass from '../constants/commonFunctionClass';

function ItemSearch() {
    const navigate = useNavigate();
    let { searchid } = useParams();
    const { t, i18n } = useTranslation();

    const _itemType = useRef('3');
    const _formName = useRef('EXCLUSIVE STOCK');
    const _itemTypeName = useRef('EXCLUSIVE STOCK');
    const _category = useRef('ADVANCESEARCH');
    const [itemFormControlRight, setitemFormControlRight] = useState([]);
    const _searchItemParameterList = useRef({});

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const {token, setToken } = useToken();
    
    const [itemShapes, setitemShapes] = useState([]);
    const [itemCarats, setitemCarats] = useState([]);
    const [itemColors, setitemColors] = useState([]);
    const [itemClarities, setitemClarities] = useState([]);   
    const [itemCuts, setitemCuts] = useState([]);
    const [itemPolishs, setItemPolishs] = useState([]);
    const [itemSymmentry, setItemSymmentry] = useState([]);
    const [itemFluorescences, setItemFluorescences] = useState([]);
    const [itemLabs, setItemLabs] = useState([]);
    const [countries, setCountries] = useState([]);

    const [caratAddFrom, setCaratAddFrom] = useState('');
    const [caratAddTo, setCaratAddTo] = useState('');

    const [discountFrom, setDiscountFrom] = useState('');
    const [discountTo, setDiscountTo] = useState('');

    const [pricetFrom, setPriceFrom] = useState('');
    const [priceTo, setPriceTo] = useState('');

    const [tablePercentageFrom, setTablePercentageFrom] = useState('');
    const [tablePercentageTo, setTablePercentageTo] = useState('');

    const [depthPercentageFrom, setDepthPercentageFrom] = useState('');
    const [depthPercentageTo, setDepthPercentageTo] = useState('');


    const [ratioFrom, setRatioFrom] = useState('');
    const [ratioTo, setRatioTo] = useState('');

    const [lengthFrom, setLengthFrom] = useState('');
    const [lengthTo, setLengthTo] = useState('');

    const [widthFrom, setWidthFrom] = useState('');
    const [widthTo, setWidthTo] = useState('');

    const [depthFrom, setDepthFrom] = useState('');
    const [depthTo, setDepthTo] = useState('');

    const [crownAngleFrom, setCrownAngleFrom] = useState('');
    const [crownAngleTo, setCrownAngleTo] = useState('');
    
    const [crownHeightFrom, setCrownHeightFrom] = useState('');
    const [crownHeightTo, setCrownHeightTo] = useState('');

    const [girdlePercentageFrom, setGirdlePercentageFrom] = useState('');
    const [girdlePercentageTo, setGirdlePercentageTo] = useState('');

    const [pavilionAngleFrom, setPavilionAngleFrom] = useState('');
    const [pavilionAngleTo, setPavilionAngleTo] = useState('');

    const [pavilionDepthFrom, setpavilionDepthFrom] = useState('');
    const [pavilionDepthTo, setpavilionDepthTo] = useState('');

    const [lowerHalfFrom, setLowerHalfFrom] = useState('');
    const [lowerHalfTo, setLowerHalfTo] = useState('');
    
    const [starLengthFrom, setStarLengthFrom] = useState('');        
    const [starLengthTo, setStarLengthTo] = useState('');
    

    const [totalFilteritems, setTotalFilteritems] = useState(0);
  

    const [searchCarats, setSearchCarats] = useState({selectedcarats:[]});
    const [searchColors, setSearchColors] = useState({selectedcolors:[]});
    const [searchShapes, setSearchShapes] = useState({selectedshapes:[]});
    const [searchClarities, setSearchClarities] = useState({selectedclarities:[]});

    const [searchCuts, setSearchCuts] = useState({ selectedcuts:[] });
    const [searchPolishes, setSearchPolishes] = useState({ selectedpolishes:[] });
    const [searchSymmetries, setSearchSymmetries] = useState({ selectedsymmentries:[] });
    const [searchFluorescences, setSearchFluorescences] = useState({ selectedfluorescences:[] });
    const [searchLabs, setSearchLabs] = useState({ selectedlabs:[] });   

    const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);  
    const [countryOptionList, setCountryOptionList] = useState([]);
      
    const [coloTypeList, setcoloTypeList]=useState([{VALUE:'WHITE', LABLE:"White"},{VALUE:'FANCY', LABLE:'Fancy'},{VALUE:'RANGE', LABLE: 'Range'}]);
    const colorType = useRef("WHITE");
    const isFirstLoad = useRef(0);
    
    const handleColorTypeClick=async(e)=>{
        let colorTypeValue=e.target.dataset.mapvalue;
        colorType.current=colorTypeValue;
    
        changeColor(colorType.current);
    }
     
    const changeColor=async(colorTypeValue)=>{
        
        let nav = document.querySelectorAll(".colorType");
        let navName = document.getElementById('Id_'+colorTypeValue);
    
        nav.forEach((element) => element.classList.remove('active'));
        navName.classList.add('active');
        
        let xtraParams= `<COLORTYPE>${colorType.current}</COLORTYPE>`;
        CommonParameterFilter(xtraParams,"COLOR");
    }

    function handleCountrySelect(data) {
        setSelectedCountryOptions(data);
    }

    const bindItemCount = async () =>
    {   
        if(isFirstLoad.current==1)
        {
            let data = await bindSearchparams();
        }
        else
        {
            let data = await getSearchParam("");
        }

        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const res = await axios.post( 
            `${BaseUrl}/api/v1/items/getItemCount`,
            _searchItemParameterList.current,
            saerchconfig
          ).then((response) => {
            setTotalFilteritems(response.data.totalitems);
        })
        .catch((error) => {
           console.error(error.response.status);
           if(error.response.status==401)
           {
               localStorage.clear();
               window.location.href=HomeUrl;
           }
        });
    }
    
    const bindSearchData = async (searchType) =>
    {   
        let data = await getSearchParam(searchType);
        let data2 = await bindSearchparams();
    }

    const bindSearchparams = async () =>
    {
        const { selectedcarats } = searchCarats;
        let _caratMapValues='';
   
        if(selectedcarats.length>0)
        {
            _caratMapValues=selectedcarats.join(',');
        }     

        const { selectedcolors } = searchColors;
        let _colorMapValues=selectedcolors.join(',');

        const { selectedshapes } = searchShapes;
        let _shapeMapValues=selectedshapes.join(',');

        const { selectedclarities } = searchClarities;
        let _clarityMapValues=selectedclarities.join(',');

        const { selectedcuts } = searchCuts;
        let _cutMapValues=selectedcuts.join(',');

        const { selectedpolishes } = searchPolishes;
        let _polishMapValues=selectedpolishes.join(',');

        const { selectedsymmentries } = searchSymmetries;
        let _symmentryMapValues=selectedsymmentries.join(',');

        const { selectedfluorescences } = searchFluorescences;
        let _fluorescenceMapValues=selectedfluorescences.join(',');

        const { selectedlabs } = searchLabs;
        let _labMapValues=selectedlabs.join(',');

        let selectedcountry='';   
        selectedCountryOptions.map(country=>(selectedcountry=selectedcountry +','+ country.value));
    
        _searchItemParameterList.current["shape_Params"]=_shapeMapValues;
        _searchItemParameterList.current["carat_Params"]=_caratMapValues;
        _searchItemParameterList.current["color_Params"]=_colorMapValues;
        _searchItemParameterList.current["colorType_Params"] = colorType.current;
        _searchItemParameterList.current["clarity_Params"]=_clarityMapValues;  
        _searchItemParameterList.current["cut_Params"]= _cutMapValues;
        _searchItemParameterList.current["polish_Params"]=_polishMapValues;
        _searchItemParameterList.current["symmentry_Params"]=_symmentryMapValues;
        _searchItemParameterList.current["fluorescence_Params"]= _fluorescenceMapValues;
        _searchItemParameterList.current["lab_Params"]=_labMapValues;
        _searchItemParameterList.current["discount_From"]=discountFrom;
        _searchItemParameterList.current["discount_To"]=discountTo;
        _searchItemParameterList.current["price_From"]=pricetFrom;
        _searchItemParameterList.current["price_To"]=priceTo;
        _searchItemParameterList.current["countryOfOrigin_Params"]=selectedcountry;
        _searchItemParameterList.current["table_Percentage_From"]=tablePercentageFrom;
        _searchItemParameterList.current["table_Percentage_To"]=tablePercentageTo;
        _searchItemParameterList.current["depth_Percentage_From"]=depthPercentageFrom;
        _searchItemParameterList.current["depth_Percentage_To"]=depthPercentageTo;
        _searchItemParameterList.current["ratio_From"]=ratioFrom;
        _searchItemParameterList.current["ratio_To"]=ratioTo;
        _searchItemParameterList.current["length_From"]=lengthFrom;
        _searchItemParameterList.current["length_To"]=lengthTo;
        _searchItemParameterList.current["width_From"]=widthFrom;
        _searchItemParameterList.current["width_To"]=widthTo;
        _searchItemParameterList.current["depth_From"]=depthFrom;
        _searchItemParameterList.current["depth_To"]=depthTo;
        _searchItemParameterList.current["crown_Angle_From"]=crownAngleFrom;
        _searchItemParameterList.current["crown_Angle_To"]=crownAngleTo;
        _searchItemParameterList.current["crown_Height_From"]=crownHeightFrom;
        _searchItemParameterList.current["crown_Height_To"]=crownHeightTo;
        _searchItemParameterList.current["girdle_Percentage_From"]=girdlePercentageFrom;
        _searchItemParameterList.current["girdle_Percentage_To"]=girdlePercentageTo;
        _searchItemParameterList.current["pavilion_Angle_From"]=pavilionAngleFrom;
        _searchItemParameterList.current["pavilion_Angle_To"]=pavilionAngleTo;
        _searchItemParameterList.current["pavilion_Depth_From"]=pavilionDepthFrom;
        _searchItemParameterList.current["pavilion_Depth_To"]=pavilionDepthTo;
        _searchItemParameterList.current["lower_Half_From"]=lowerHalfFrom;
        _searchItemParameterList.current["lower_Half_To"]=lowerHalfTo;
        _searchItemParameterList.current["star_Length_From"]=starLengthFrom;
        _searchItemParameterList.current["star_Length_To"]=starLengthTo;
        _searchItemParameterList.current["itemType"]= _itemType.current;
    }

    const getSearchParam = async (searchType) =>
    {
        const data = await commonFunctionClass().getSearchParam(token,_itemType.current,searchType == "ITEMCOUNT"?"-1":searchid);
        _searchItemParameterList.current=data;
    }
  
    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight(data);
        }
        else if(_mode.toUpperCase() == "SHAPE")
        {
            setitemShapes(data);
        }
        else if(_mode.toUpperCase() == "CARAT")
        {
            setitemCarats(data);
        }
        else if(_mode.toUpperCase() == "COLOR")
        {
            setitemColors(data);
        }
        else if(_mode.toUpperCase() == "CLARITY")
        {
            setitemClarities(data);
        }
        else if(_mode.toUpperCase() == "CUT")
        {
            setitemCuts(data);
        }
        else if(_mode.toUpperCase() == "POLISH")
        {
            setItemPolishs(data);
        }
        else if(_mode.toUpperCase() == "SYMMETRY")
        {
            setItemSymmentry(data);
        }
        else if(_mode.toUpperCase() == "FLUORESCENCES")
        {
            setItemFluorescences(data);
        }
        else if(_mode.toUpperCase() == "LAB")
        {
            setItemLabs(data);
        }
        else if(_mode.toUpperCase() == "COUNTRYOFORIGIN")
        {
            setCountryOptionList(data);
        }
    }

    const handleSaveSearchModalShow = () => setIsModalVisible(true);
 
    const getData = async () => {
       let data = await getSearchParam("");
        if(_searchItemParameterList.current.shape_Params!=''){
            const shapesArray = _searchItemParameterList.current.shape_Params.split(",");
            setSearchShapes({selectedshapes:shapesArray})
        }

        if(_searchItemParameterList.current.clarity_Params!=''){
            const clarityArray = _searchItemParameterList.current.clarity_Params.split(",");            
            setSearchClarities({selectedclarities:clarityArray});
        }

        if(_searchItemParameterList.current.color_Params!=''){
            const colorArray = _searchItemParameterList.current.color_Params.split(",");    
            setSearchColors({selectedcolors:colorArray});
        }

        if(_searchItemParameterList.current.carat_Params!=''){
            const caratArray = _searchItemParameterList.current.carat_Params.split(",");    
            setSearchCarats({selectedcarats:caratArray});
        }

        if(_searchItemParameterList.current.colorType_Params!=''){
            colorType.current = _searchItemParameterList.current.colorType_Params;
        }
       
        changeColor(colorType.current);


        if( _searchItemParameterList.current.cut_Params!=''){
            const cutArray = _searchItemParameterList.current.cut_Params.split(",");    
            setSearchCuts({selectedcuts:cutArray});
        }

        if( _searchItemParameterList.current.polish_Params!=''){
            const polishArray = _searchItemParameterList.current.polish_Params.split(",");    
            setSearchPolishes({selectedpolishes:polishArray});
        }

        if( _searchItemParameterList.current.symmentry_Params!=''){
            const symmentryArray = _searchItemParameterList.current.symmentry_Params.split(",");    
            setSearchSymmetries({selectedsymmentries:symmentryArray});
        }

        if( _searchItemParameterList.current.fluorescence_Params!=''){
            const fluorescenceArray = _searchItemParameterList.current.fluorescence_Params.split(",");    
            setSearchFluorescences({selectedfluorescences:fluorescenceArray});
        }


        if( _searchItemParameterList.current.lab_Params!=''){
            const labArray = _searchItemParameterList.current.lab_Params.split(",");    
            setSearchLabs({selectedlabs:labArray});
        }
        
        if( _searchItemParameterList.current.discount_From !=''){             
            setDiscountFrom(_searchItemParameterList.current.discount_From.toString());
        }      
        if( _searchItemParameterList.current.discount_To !=''){             
            setDiscountTo(_searchItemParameterList.current.discount_To.toString());
        }      
        
        if( _searchItemParameterList.current.price_From !=''){             
            setPriceFrom(_searchItemParameterList.current.price_From.toString());
        }  

        if( _searchItemParameterList.current.price_To !=''){             
            setPriceTo(_searchItemParameterList.current.price_To.toString());
        }               

        if (_searchItemParameterList.current.countryOfOrigin_Params !='') {
            const countryArray = _searchItemParameterList.current.countryOfOrigin_Params.split(",");
            setSelectedCountryOptions(countryArray);  
        }

        if( _searchItemParameterList.current.table_Percentage_From !=''){             
            setTablePercentageFrom(_searchItemParameterList.current.table_Percentage_From.toString());
        }               
        if( _searchItemParameterList.current.table_Percentage_To !=''){             
            setTablePercentageTo(_searchItemParameterList.current.table_Percentage_To.toString());
        }   

        if( _searchItemParameterList.current.depth_Percentage_From !=''){             
            setDepthPercentageFrom(_searchItemParameterList.current.depth_Percentage_From.toString());
        }               
        if( _searchItemParameterList.current.depth_Percentage_To !=''){             
            setDepthPercentageTo(_searchItemParameterList.current.depth_Percentage_To.toString());
        }   

        if( _searchItemParameterList.current.ratio_From !=''){             
            setRatioFrom(_searchItemParameterList.current.ratio_From.toString());
        }               
        if( _searchItemParameterList.current.ratio_To !=''){             
            setRatioTo(_searchItemParameterList.current.ratio_To.toString());
        }   

        if( _searchItemParameterList.current.length_From !=''){             
            setLengthFrom(_searchItemParameterList.current.length_From.toString());
        }               
        if( _searchItemParameterList.current.length_To !=''){             
            setLengthTo(_searchItemParameterList.current.length_To.toString());
        }   

        if( _searchItemParameterList.current.width_From !=''){             
            setWidthFrom(_searchItemParameterList.current.width_From.toString());
        }               
        if( _searchItemParameterList.current.width_To !=''){             
            setWidthTo(_searchItemParameterList.current.width_To.toString());
        }   

        if( _searchItemParameterList.current.depth_From !=''){             
            setDepthFrom(_searchItemParameterList.current.depth_From.toString());
        }               
        if( _searchItemParameterList.current.depth_To !=''){             
            setDepthTo(_searchItemParameterList.current.depth_To.toString());
        }   

        if( _searchItemParameterList.current.crown_Angle_From !=''){             
            setCrownAngleFrom(_searchItemParameterList.current.crown_Angle_From.toString());
        }               
        if( _searchItemParameterList.current.crown_Angle_To !=''){             
            setCrownAngleTo(_searchItemParameterList.current.crown_Angle_To.toString());
        }   


        if( _searchItemParameterList.current.crown_Height_From !=''){             
            setCrownHeightFrom(_searchItemParameterList.current.crown_Height_From.toString());
        }               
        if( _searchItemParameterList.current.crown_Height_To !=''){             
            setCrownHeightTo(_searchItemParameterList.current.crown_Height_To.toString());
        }   


        if( _searchItemParameterList.current.girdle_Percentage_From !=''){             
            setGirdlePercentageFrom(_searchItemParameterList.current.girdle_Percentage_From.toString());
        }               
        if( _searchItemParameterList.current.girdle_Percentage_To !=''){             
            setGirdlePercentageTo(_searchItemParameterList.current.girdle_Percentage_To.toString());
        }   


        if( _searchItemParameterList.current.pavilion_Angle_From !=''){             
            setPavilionAngleFrom(_searchItemParameterList.current.pavilion_Angle_From.toString());
        }               
        if( _searchItemParameterList.current.pavilion_Angle_To !=''){             
            setPavilionAngleTo(_searchItemParameterList.current.pavilion_Angle_To.toString());
        }   

        if( _searchItemParameterList.current.pavilion_Depth_From !=''){             
            setpavilionDepthFrom(_searchItemParameterList.current.pavilion_Depth_From.toString());
        }               
        if( _searchItemParameterList.current.pavilion_Depth_To !=''){             
            setpavilionDepthTo(_searchItemParameterList.current.pavilion_Depth_To.toString());
        }   


        if( _searchItemParameterList.current.lower_Half_From !=''){             
            setLowerHalfFrom(_searchItemParameterList.current.lower_Half_From.toString());
        }               
        if( _searchItemParameterList.current.lower_Half_To !=''){             
            setLowerHalfTo(_searchItemParameterList.current.lower_Half_To.toString());
        }   


        if( _searchItemParameterList.current.star_Length_From != ''){             
            setStarLengthFrom(_searchItemParameterList.current.star_Length_From.toString());
        }               
        if( _searchItemParameterList.current.star_Length_To !=''){             
            setStarLengthTo(_searchItemParameterList.current.star_Length_To.toString());
        } 
    }
   
    useEffect(() => {
        CommonParameterFilter('',"FORMCONTROL");
        CommonParameterFilter('',"SHAPE");
        CommonParameterFilter('',"CARAT");
        
        let xtraParams= `<COLORTYPE>${colorType.current}</COLORTYPE>`;
        CommonParameterFilter(xtraParams,"COLOR");
        CommonParameterFilter('',"CLARITY");
        CommonParameterFilter('',"CUT");
        CommonParameterFilter('',"POLISH");
        CommonParameterFilter('',"SYMMETRY");
        CommonParameterFilter('',"FLUORESCENCES");
        CommonParameterFilter('',"LAB");
        CommonParameterFilter('',"COUNTRYOFORIGIN");
      
        if(searchid.toString()!="-1" && searchid.toString().length>0)
        {
            getData();            
            bindItemCount();
        }
        else
        {
            getSearchParam("ITEMCOUNT");
            searchid = -1;
        }
    },[]);
    

    useEffect(() => {
        bindItemCount();
        isFirstLoad.current=1;
    },[searchCarats,searchColors,searchShapes,searchClarities,searchCuts,searchPolishes,searchSymmetries,searchFluorescences,searchLabs]);

  const handleSearchNow = () => {
    if(searchid.toString()=="-1" && searchid.toString().length<=10)
        saveSearchId(true);
    else
    {
        searchData();
    }
  }


 async function saveSearchId(_isNavigate)
 {
    let _token=token;
    
    let data1 = await bindSearchData("");

    const saerchconfig = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const res = await axios.post( 
        `${BaseUrl}/api/v1/items/putsearch/${searchid}`,
        _searchItemParameterList.current,
        saerchconfig
      ).then((response) => {
        if(response.status==200)
        {
            searchid = response.data.searchid;
            if(_isNavigate)
            {
                navigate('/exclusiveitemlist/'+searchid+'');
            }

        }
        else if(response.status==401)
        {           
            localStorage.clear();
            window.location.href=HomeUrl;
        }
    })
    .catch((error) => {
       console.error(error.response.status);
       if(error.response.status==401)
       {
           localStorage.clear(); 
           //navigate('/');
           window.location.href=HomeUrl;    
       }
       
    });    
    
    return searchid;
 }

 async function searchData(){
    let _token=token;  
    
    const saerchconfig = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data= await bindSearchData("");

    const res = await axios.post( 
        `${BaseUrl}/api/v1/items/putsearch/${searchid}`,
        _searchItemParameterList.current,
        saerchconfig
      ).then((response) => {
        searchid = response.data.searchid;
        navigate('/exclusiveitemlist/'+searchid+'');
        setTotalFilteritems(response.data.totalfilteritem);
    })
    .catch((error) => {
        console.error(error.response.status);
        if(error.response.status==401)
        {
            localStorage.clear(); 
            window.location.href=BaseUrl;    
        }
        
     });
  }

 async function CaratClick(e){
    return new Promise(function(resolve,reject)
    {
       const { selectedcarats } = searchCarats;
       let carat_mapval=e.target.dataset.caratmapvalue;      
     
       let check=selectedcarats.includes(carat_mapval)
       if(check){
           setSearchCarats({
            selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
            });
            e.target.className='';        
        }
        else{
           setSearchCarats({
                selectedcarats:[...selectedcarats,carat_mapval]
              });
              e.target.className='active';        
        }  
        
        resolve("Carat Clicked" )
       
        }
    )

}

const handleCaratClick=async(e)=>{

   const { selectedcarats } = searchCarats;
   let carat_mapval=e.target.dataset.caratmapvalue;      
 
   let check=selectedcarats.includes(carat_mapval)
   if(check){
       setSearchCarats({
        selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
        });
        e.target.className='';        
    }
    else{
        setSearchCarats({
            selectedcarats:[...selectedcarats,carat_mapval]
          });
          e.target.className='active';        
    }          
}


const handleRemoveCaratClick=(e)=>{
    const { selectedcarats } = searchCarats; 
    let carat_mapval=e.target.dataset.caratmapvalue;        
    let check=selectedcarats.includes(carat_mapval);

    if(check){
        setSearchCarats({
         selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
         });
         
     }

    
     
 }
 

 const handleAddCaratClick=(e)=>{
    const { selectedcarats } = searchCarats;
 
    if(caratAddFrom!='' && caratAddTo!='')
    {
        if(!isNaN(caratAddFrom) && !isNaN(caratAddTo) )
        {
            let carat_mapval=caratAddFrom +'-'+caratAddTo;     
            let check=selectedcarats.includes(carat_mapval)
            if(!check){
                setSearchCarats({
                    selectedcarats:[...selectedcarats,carat_mapval]
                })

                setCaratAddFrom('');
                setCaratAddTo('');           
            }

           
         }
    }
      
 }



const handleColorClick=(e)=>{
    const { selectedcolors } = searchColors;

    let color_mapval=e.target.dataset.colormapvalue;      
  
    let check=selectedcolors.includes(color_mapval)
    if(check){
        setSearchColors({
            selectedcolors: selectedcolors.filter((e) => e !== color_mapval)
         });
         e.target.className=''; 
     }
     else{
         setSearchColors({
            selectedcolors:[...selectedcolors,color_mapval]
           });
           e.target.className='active';
     }  
     
  
 }


 const handleShapeClick=(e)=>{
    const { selectedshapes} = searchShapes;

    let shape_mapval=e.target.dataset.shapemapvalue;      
    
    const el = document.getElementById(shape_mapval);            

    let check=selectedshapes.includes(shape_mapval)
    if(check){
        setSearchShapes({
            selectedshapes: selectedshapes.filter((e) => e !== shape_mapval)
         });
         el.className='';

        
     }
     else{
         setSearchShapes({
            selectedshapes:[...selectedshapes,shape_mapval]
           });
           el.className='active';         
          
     }

    
 }

 const handleClarityClick=(e)=>{
    const { selectedclarities} = searchClarities;

    let clarity_mapval=e.target.dataset.claritymapvalue;      
  
    let check=selectedclarities.includes(clarity_mapval)
    if(check){
        setSearchClarities({
            selectedclarities: selectedclarities.filter((e) => e !== clarity_mapval)
         });
         e.target.className=''; 
     }
     else{
         setSearchClarities({
            selectedclarities:[...selectedclarities,clarity_mapval]
           });
           e.target.className='active';
     }   
     
    
 }


 const handleCutClick=(e)=>{
    const { selectedcuts} = searchCuts;

    let cut_mapval=e.target.dataset.cutmapvalue;        
  
    let check=selectedcuts.includes(cut_mapval);

 
    if(check){
        setSearchCuts({
            selectedcuts: selectedcuts.filter((e) => e !== cut_mapval)
         });
         e.target.className=''; 
     }
     else{
         setSearchCuts({
            selectedcuts:[...selectedcuts,cut_mapval]
           });
           e.target.className='active';
     }  
   
 }
 

 const handlePolishClick=(e)=>{
    const { selectedpolishes} = searchPolishes;
    let polish_mapval=e.target.dataset.polishmapvalue;        
  
    let check=selectedpolishes.includes(polish_mapval);

 
    if(check){
        setSearchPolishes({
            selectedpolishes: selectedpolishes.filter((e) => e !== polish_mapval)
         });
         e.target.className=''; 
     }
     else{
         setSearchPolishes({
            selectedpolishes:[...selectedpolishes,polish_mapval]
           });
           e.target.className='active';
     }  
     
     
 }
 
 const handleSymmentryClick=(e)=>{
    const { selectedsymmentries} = searchSymmetries;
    let symmentry_mapval=e.target.dataset.symmentrymapvalue;        
  
    let check=selectedsymmentries.includes(symmentry_mapval);

    if(check){
        setSearchSymmetries({
            selectedsymmentries: selectedsymmentries.filter((e) => e !== symmentry_mapval)
         });
         e.target.className=''; 
     }
     else{
         setSearchSymmetries({
            selectedsymmentries:[...selectedsymmentries,symmentry_mapval]
           });
           e.target.className='active';
     }  
     
    
 }
 

  
 const handleFluorescenceClick=(e)=>{


    const { selectedfluorescences} = searchFluorescences;
    let fluorescence_mapval=e.target.dataset.fluorescencemapvalue;        
  
    let check=selectedfluorescences.includes(fluorescence_mapval);

 
    if(check){
        setSearchFluorescences({
            selectedfluorescences: selectedfluorescences.filter((e) => e !== fluorescence_mapval)
         });
         e.target.className=''; 
     }
     else{
         setSearchFluorescences({
            selectedfluorescences:[...selectedfluorescences,fluorescence_mapval]
           });
           e.target.className='active';
     }  
     
     
 }

   
 const handleLabClick=(e)=>{
    const { selectedlabs} = searchLabs;
    let lab_mapval=e.target.dataset.labmapvalue;          
    let check=selectedlabs.includes(lab_mapval);

    if(check){
        setSearchLabs({
            selectedlabs: selectedlabs.filter((e) => e !== lab_mapval)
         });
         e.target.className='';
     }
     else{
         setSearchLabs({
            selectedlabs:[...selectedlabs,lab_mapval]
           });
           e.target.className='active';
     } 
     
    
 }
 


const [isModalVisible, setIsModalVisible] = useState(false);

function isValidNumber(input) {
    var pattern = /^-?\d+(\.\d+)?$/;
    return pattern.test(input);
  }
  
const handleDiscountFromChange = event => {
      setDiscountFrom(event.target.value);
  
  };

  const handleDiscountToChange = event => {
      setDiscountTo(event.target.value);
   
  };


  const handlePriceFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setPriceFrom(event.target.value);
    }
  };

  const handlePriceToChange = event => {   
    if (!isNaN(event.target.value)) {
      setPriceTo(event.target.value);
    }
  };


  const handleTablePercentageFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setTablePercentageFrom(event.target.value);
    }
  };

  const handleTablePercentageToChange = event => {   
    if (!isNaN(event.target.value)) {
      setTablePercentageTo(event.target.value);
    }
  };

  
  const handleDepthPercentageFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setDepthPercentageFrom(event.target.value);
    }
  };

  const handleDepthPercentageToChange = event => {   
    if (!isNaN(event.target.value)) {
      setDepthPercentageTo(event.target.value);
    }
  };


  const handleRatioFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setRatioFrom(event.target.value);
    }
  };

  const handleRatioToChange = event => {   
    if (!isNaN(event.target.value)) {
      setRatioTo(event.target.value);
    }
  };


  const handleLengthFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setLengthFrom(event.target.value);
    }
  };

  const handleLengthToChange = event => {   
    if (!isNaN(event.target.value)) {
      setLengthTo(event.target.value);
    }
  };


  const handleWidthFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setWidthFrom(event.target.value);
    }
  };

  const handleWidthToChange = event => {   
    if (!isNaN(event.target.value)) {
      setWidthTo(event.target.value);
    }
  };

  const handleDepthFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setDepthFrom(event.target.value);
    }
  };

  const handleDepthToChange = event => {   
    if (!isNaN(event.target.value)) {
      setDepthTo(event.target.value);
    }
  };

  const handleCrownAngleFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setCrownAngleFrom(event.target.value);
    }
  };

  const handleCrownAngleToChange = event => {   
    if (!isNaN(event.target.value)) {
      setCrownAngleTo(event.target.value);
    }
  };

  const handleCrownHeightFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setCrownHeightFrom(event.target.value);
    }
  };

  const handleCrownHeightToChange = event => {   
    if (!isNaN(event.target.value)) {
      setCrownHeightTo(event.target.value);
    }
  };


  const handleGirdlePercentagetFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setGirdlePercentageFrom(event.target.value);
    }
  };

  const handleGirdlePercentagetToChange = event => {   
    if (!isNaN(event.target.value)) {
      setGirdlePercentageTo(event.target.value);
    }
  };

  const handlePavilionAngleFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setPavilionAngleFrom(event.target.value);
    }
  };

  const handlePavilionAngleToChange = event => {   
    if (!isNaN(event.target.value)) {
      setPavilionAngleTo(event.target.value);
    }
  };


  const handlePavilionDepthFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setpavilionDepthFrom(event.target.value);
    }
  };

  const handlePavilionDepthToChange = event => {   
    if (!isNaN(event.target.value)) {
      setpavilionDepthTo(event.target.value);
    }
  };

  const handleLowerHalfFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setLowerHalfFrom(event.target.value);
    }
  };

  const handleLowerHalfToChange = event => {   
    if (!isNaN(event.target.value)) {
      setLowerHalfTo(event.target.value);
    }
  };

  const handleStarLengthFromChange = event => {   
    if (!isNaN(event.target.value)) {
      setStarLengthFrom(event.target.value);
    }
  };

  const handleStarLengthToChange = event => {   
    if (!isNaN(event.target.value)) {
      setStarLengthTo(event.target.value);
    }
  };

  const hidhenfornow = {
    display: "none"
  };


  const handleModal = async (titleinputval) =>
  {   
    searchid = await saveSearchId(false);
    const data = await commonFunctionClass().saveSearch(token,navigate,_itemType.current,searchid, titleinputval);

    setpopupMsgType(data[0].POPUPMSGTYPE);
    setpopupHeaderMsg(data[0].POPUPHEADERMSG);
    setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
    setShowPopupMsg(true);

    setIsModalVisible(false);

    navigate('/exclusiveitemlist/'+searchid+'');
      
  }
  
  function ModalSaveSearch() {
      return (
          <Modal title="Search & Save"
              open={isModalVisible}
              onOk={() => {
                  const titleinput = document.getElementById("SearchTitle");
  
                  let titleinputval=titleinput.value;
  
                  if(titleinputval !=""){                    
                      handleModal(titleinputval);
                  }
                  else{
                      setpopupMsgType("warning");
                      setpopupHeaderMsg("Search title missing");
                      setpopupDetailedMsg("Please enter search title atleast 5 character.");
                      setShowPopupMsg(true);
                  }
                 
              }}
              onCancel={() => {
                  setIsModalVisible(false);
              }}>
  
              <Form>
                      <Form.Item
                          label="SearchTitle"
                          name="username"
                          rules={[{ required: true, message: 'Please input your username!' }]}
                      >
                     <Input  id='SearchTitle' name='SearchTitle' />
                    </Form.Item>
              </Form>
  
          </Modal>
      )
  }
  

    return (
        <Fragment>
            <Header />
            <Sidebar />

            <ModalSaveSearch/>          

            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>


            <section id="search-stone">
                <div className="container max-container">
                    <div className="select-stone--wrapper caret" id="advanced-search--list">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="select-stone--label">{t('shape')}:</div>
                            </div>
                            <div className="col-lg-12">
                                <div className="select-stone--shape">
                                    <ul className="list-unstyled d-flex mb-0">
                                        <li data-bs-toggle="tooltip" data-bs-placement="bottom" aria-label="Round">
                                            All
                                        </li>
                                        {
                                            itemShapes.map(shape=>(
                                                searchShapes.selectedshapes.includes(shape.MAPVALUE)?
                                                <li key={shape.MAPVALUE} id={shape.MAPVALUE} data-bs-toggle="tooltip" className='active' data-bs-placement="bottom" title={shape.SHAPENAME}  aria-label={shape.SHAPENAME}>
                                                <img  src={shape.IMAGEURL} className="img-fluid px-2" alt="" onClick={handleShapeClick} data-shapemapvalue={shape.MAPVALUE}  />
                                                    {/* {shape.shapeName} */}
                                                </li>
                                                :
                                                <li  key={shape.MAPVALUE} id={shape.MAPVALUE} data-bs-toggle="tooltip"  data-bs-placement="bottom" title={shape.SHAPENAME}  aria-label={shape.SHAPENAME}>
                                                    <img  src={shape.IMAGEURL} className="img-fluid px-2" alt="" onClick={handleShapeClick}  data-shapemapvalue={shape.MAPVALUE} />
                                                    {/* {shape.shapeName} */}
                                                </li>
                                            ))                                       
                                            }     

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-2">
                                <div className="select-stone--label mb-0">{t('carat')}:</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="select-stone--shape">
                                    <div className="form-group d-flex gap-2 flex-wrap align-items-end mb-2">
                                        <div className="form-row col-2">                                            
                                            <input type="text" value={caratAddFrom} onChange={e => setCaratAddFrom(e.target.value)} placeholder={t('from')}  className="form-control bg-white mb-0" />
                                        </div>
                                        <div className="form-row col-2">
                                            <input type="text" value={caratAddTo} onChange={e => setCaratAddTo(e.target.value)} placeholder={t('to')} className="form-control bg-white mb-0" />
                                        </div>
                                        <button className="hero_btn" onClick={handleAddCaratClick}>Add Carat</button>

                                        <div className="list-unstyled mb-0 d-flex gap-2 flex-wrap box_range">
                                            {
                                                searchCarats.selectedcarats.length>0
                                                ?
                                                 searchCarats.selectedcarats.map(scarat=>(
                                                    <p key={scarat}>
                                                        <span>{scarat}</span>
                                                        <button className='btn-close' data-caratmapvalue={scarat} onClick={ handleRemoveCaratClick } ></button>
                                                   </p>
                                                 ))
                                                 :''
                                            }
                                        </div>
                                    </div>
                                    <ul className="list-unstyled d-flex mb-0">
                                           {
                                                itemCarats.map(carat=>(
                                                    searchCarats.selectedcarats.includes(carat.MAPVALUE)?
                                                     <li  key={carat.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleCaratClick} data-caratmapvalue={carat.MAPVALUE} title={carat.CARATNO} >{carat.CARATNO}</li>
                                                     :
                                                     <li key={carat.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleCaratClick} data-caratmapvalue={carat.MAPVALUE} title={carat.CARATNO} >{carat.CARATNO}</li>
                                               ))
                                            }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-2">
                                <div className="select-stone--label mb-0">{t('color')}:</div><br/>
                                <ul class="list-unstyled d-flex mb-0 colorTypeNav">
                                    {
                                        coloTypeList.map(type=>(                                               
                                            colorType.current == type.VALUE?
                                            <li  key={type.VALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" className='colorType active' id={'Id_'+type.VALUE} onClick={handleColorTypeClick} data-mapvalue={type.VALUE} title={type.VALUE} >{type.LABLE}</li>
                                            :
                                            <li key={type.VALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" className='colorType ' onClick={handleColorTypeClick} id={'Id_'+type.VALUE} data-mapvalue={type.VALUE} title={type.VALUE} >{type.LABLE}</li>
                                        
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="col-lg-10">
                                <div className="select-stone--shape">
                                    <ul className="list-unstyled d-flex mb-0">
                                        {
                                                itemColors.map(color=>(
                                                    searchColors.selectedcolors.includes(color.MAPVALUE)?
                                                    <li key={color.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleColorClick}  data-colormapvalue={color.MAPVALUE}  title={color.COLORNAME}>{color.COLORNAME}</li>
                                                    :
                                                    <li key={color.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleColorClick}  data-colormapvalue={color.MAPVALUE}  title={color.COLORNAME}>{color.COLORNAME}</li>
                                                ))
                                         }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-2">
                                <div className="select-stone--label mb-0">{t('clarity')}:</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="select-stone--shape">
                                    <ul className="list-unstyled d-flex mb-0">
                                       {
                                            itemClarities.map(clarity=>(
                                                searchClarities.selectedclarities.includes(clarity.MAPVALUE)?
                                                <li key={clarity.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleClarityClick}  title={clarity.CLARITYNAME} data-claritymapvalue={clarity.MAPVALUE} >{clarity.CLARITYNAME}</li>
                                                :
                                                <li key={clarity.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleClarityClick} title={clarity.CLARITYNAME} data-claritymapvalue={clarity.MAPVALUE} >{clarity.CLARITYNAME}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                       
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row p-0 align-items-center">
                                    <div className="col-lg-4">
                                        <div className="select-stone--label mb-0">{t('cut')}:</div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="select-stone--shape">
                                            <ul className="list-unstyled d-flex mb-0">
                                                {
                                                     itemCuts.map(cut=>(
                                                        searchCuts.selectedcuts.includes(cut.MAPVALUE)?
                                                        <li key={cut.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleCutClick}  title={cut.CUTNAME} data-cutmapvalue={cut.MAPVALUE} >{cut.CUTNAME}</li>
                                                        :
                                                        <li key={cut.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleCutClick} title={cut.CUTNAME} data-cutmapvalue={cut.MAPVALUE} >{cut.CUTNAME}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-0 align-items-center" style={hidhenfornow}>
                                    <div className="col-lg-4">
                                        <div className="select-stone--label mb-0">{t('polish')}:</div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="select-stone--shape">
                                            <ul className="list-unstyled d-flex mb-0">
                                                {
                                                     itemPolishs.map(polish=>(
                                                        searchPolishes.selectedpolishes.includes(polish.MAPVALUE)?
                                                        <li key={polish.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handlePolishClick}  title={polish.POLISHNAME} data-polishmapvalue={polish.MAPVALUE} >{polish.POLISHNAME}</li>
                                                        :
                                                        <li key={polish.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handlePolishClick} title={polish.POLISHNAME} data-polishmapvalue={polish.MAPVALUE} >{polish.POLISHNAME}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-0 align-items-center" style={hidhenfornow}>
                                    <div className="col-lg-4">
                                        <div className="select-stone--label mb-0">{t('symmentry')}:</div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="select-stone--shape">
                                            <ul className="list-unstyled d-flex mb-0">
                                               {
                                                     itemSymmentry.map(symmentry=>(
                                                        searchSymmetries.selectedsymmentries.includes(symmentry.MAPVALUE)?
                                                        <li key={symmentry.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleSymmentryClick}  title={symmentry.SYMMETRYNAME} data-symmentrymapvalue={symmentry.MAPVALUE} >{symmentry.SYMMETRYNAME}</li>
                                                        :
                                                        <li key={symmentry.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleSymmentryClick} title={symmentry.SYMMETRYNAME} data-symmentrymapvalue={symmentry.MAPVALUE} >{symmentry.SYMMETRYNAME}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row p-0">
                                    <div className="col-lg-6">
                                        <div className="col-lg-12">
                                            <div className="select-stone--label mb-3">{t('fluorescenc')}:</div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="select-stone--shape">
                                                <ul className="list-unstyled d-flex mb-0">
                                                   {
                                                        itemFluorescences.map(fluorescence=>(
                                                            searchFluorescences.selectedfluorescences.includes(fluorescence.MAPVALUE)?
                                                            <li key={fluorescence.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleFluorescenceClick}  title={fluorescence.FLUORESCENCENAME} data-fluorescencemapvalue={fluorescence.MAPVALUE} >{fluorescence.FLUORESCENCENAME}</li>
                                                            :
                                                            <li key={fluorescence.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleFluorescenceClick} title={fluorescence.FLUORESCENCENAME} data-fluorescencemapvalue={fluorescence.MAPVALUE} >{fluorescence.FLUORESCENCENAME}</li>
                                                        ))
                                                   }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="col-lg-12">
                                            <div className="select-stone--label mb-3">{t('lab')}:</div>
                                        </div>
                                        <div className="col-lg-10">
                                            <div className="select-stone--shape">
                                                <ul className="list-unstyled d-flex mb-0">
                                                  {
                                                        itemLabs.map(lab=>(
                                                            searchLabs.selectedlabs.includes(lab.MAPVALUE)?
                                                            <li key={lab.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleLabClick}  title={lab.LABNAME} data-labmapvalue={lab.MAPVALUE} >{lab.LABNAME}</li>
                                                            :
                                                            <li key={lab.MAPVALUE}  data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleLabClick} title={lab.LABNAME} data-labmapvalue={lab.MAPVALUE} >{lab.LABNAME}</li>
                                                        ))
                                                   }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center" style={hidhenfornow}>
                            <div className="col-lg-2">
                                <div className="select-stone--label mb-0">Price & Disc %</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="select-stone--shape">
                                    <div className="form-group d-flex gap-2 flex-wrap align-items-end mb-2">
                                        <div className="form-row col-2">
                                            <h6>Disc %</h6>
                                            <label htmlFor="">From</label>
                                            <input type="number"  min="-100"  value={discountFrom} onChange={handleDiscountFromChange} className="form-control bg-white mb-0" />
                                        </div>
                                        <div className="form-row col-2">
                                            <label htmlFor="">To</label>
                                            <input type="number"  min="-100" value={discountTo} onChange={handleDiscountToChange} className="form-control bg-white mb-0" />
                                        </div>
                                        <div className="form-row col-2">
                                            <h6>Price / Carat</h6>
                                            <label htmlFor="">From</label>
                                            <input type="text"  value={pricetFrom} onChange={handlePriceFromChange} className="form-control bg-white mb-0" />
                                        </div>
                                        <div className="form-row col-2">
                                            <label htmlFor="">To</label>
                                            <input type="text" value={priceTo} onChange={handlePriceToChange} className="form-control bg-white mb-0" />
                                        </div>

                                        <div className="form-row col-3">
                                            <h6 htmlFor="">Country Of Origin</h6>
                                            <Select
                                                options={countryOptionList}
                                                placeholder="Select"
                                                value={selectedCountryOptions}
                                                onChange={handleCountrySelect}
                                                isSearchable={true}
                                                isMulti
                                             />
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center" style={hidhenfornow}>

                            <div className="accordion mb-5" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tab1" aria-expanded="true" aria-controls="tab1">
                                            <div className="col-lg-12">
                                                <div className="select-stone--label mb-0">Parameters</div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="tab1" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="row p-0">

                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Table%</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={tablePercentageFrom} onChange={handleTablePercentageFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={tablePercentageTo} onChange={handleTablePercentageToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Depth%</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={depthPercentageFrom} onChange={handleDepthPercentageFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={depthPercentageTo} onChange={handleDepthPercentageToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Ratio</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={ratioFrom} onChange={handleRatioFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={ratioTo} onChange={handleRatioToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>

                                            <div className="row p-0">
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Length</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={lengthFrom} onChange={handleLengthFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={lengthTo} onChange={handleLengthToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Width</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={widthFrom} onChange={handleWidthFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={widthTo} onChange={handleWidthToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Depth</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={depthFrom} onChange={handleDepthFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={depthTo} onChange={handleDepthToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>

                                            <div className="row p-0">

                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Crown Angle</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={crownAngleFrom} onChange={handleCrownAngleFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={crownAngleTo} onChange={handleCrownAngleToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Crown Height</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={crownHeightFrom} onChange={handleCrownHeightFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={crownHeightTo} onChange={handleCrownHeightToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Girdle%</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={girdlePercentageFrom} onChange={handleGirdlePercentagetFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={girdlePercentageTo} onChange={handleGirdlePercentagetToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>

                                            <div className="row p-0">

                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Pavilion Angle</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={pavilionAngleFrom} onChange={handlePavilionAngleFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={pavilionAngleTo} onChange={handlePavilionAngleToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Pavilion Depth</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={pavilionDepthFrom} onChange={handlePavilionDepthFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={pavilionDepthTo} onChange={handlePavilionDepthToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Lower Half</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={lowerHalfFrom} onChange={handleLowerHalfFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={lowerHalfTo} onChange={handleLowerHalfToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>

                                            <div className="row p-0">
                                                <div className="col-lg-4">
                                                    <div className="col-lg-12">
                                                        <div className="select-stone--shape">
                                                            <h6>Star Length</h6>
                                                            <div className="form-group d-flex gap-2 align-items-end mb-2">
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">From</label>
                                                                    <input type="text" value={starLengthFrom} onChange={handleStarLengthFromChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                                <div className="form-row col-6">
                                                                    <label htmlFor="">To</label>
                                                                    <input type="text" value={starLengthTo} onChange={handleStarLengthToChange} className="form-control bg-white mb-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                {/* <!-- Modify Search Button --> */}
                {/* style="display: block;" */}

                <div className="modify-search--btnWrapper d-block" >
                    <div className="container max-container">
                        <div>
                            {/* <!-- <div className="col-9"> --> */}
                            <div className="modify-search--btnWrapper-outer-1st w-100">
                            <div className="modify-search--btnWrapper--left d-md-block d-flex justify-content-between p-lg-0 p-3">
                                    <button onClick={handleSearchNow}>{t('search')} ({totalFilteritems})</button>
                                    <button onClick={handleSaveSearchModalShow}>{t('save&search')}</button>
                                    <button className="d-md-inline-block d-none">{t('addanothersearch')}</button>
                                    {/* <button>MATCH PAIR</button> */}
                                    <span className="dropdown">
                                        <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t('more')}
                                        </button>
                                        <ul className="dropdown-menu p-0 rounded-0" >
                                        <li><a className="dropdown-item d-md-none" href="">ADD ANOTHER SEARCH</a></li>
                                            <li><a className="dropdown-item" href="">NEW ARRIVAL</a></li>
                                            <li><a className="dropdown-item" href="">BID TO BUY</a></li>
                                            <li><a className="dropdown-item" href="#">RESET</a></li>
                                        </ul>
                                    </span>
                                </div>
                                {/* <button className="mob-menu-btn">
                                    <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                    <span className="d-block">Search Stone</span>
                                </button> */}
                            </div>
                            {/* <!-- </div> --> */}
                            {/* <!-- <div className="col-3"> --> */}
                            {/* <div className="modify-search--btnWrapper-outer-2nd">
                                <button className="mob-menu-btn">
                                    <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                    <span className="d-block">Confirm Stone</span>
                                </button>
                            </div>
                            <div className="modify-search--btnWrapper-outer-3rd">
                                <button className="mob-menu-btn">
                                    <span className="d-block"><img src="../assets/images/icons/foot_menu.svg" width="18px" alt="" /></span>
                                    <span className="d-block">More</span>
                                </button>
                            </div> */}
                            {/* <!-- </div> --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- End Modify Search Button --> */}
            </section>
        </Fragment>
    )
}
export default ItemSearch;