import React, { useEffect, useState, Fragment,Component } from 'react';

export default class ParentChildComponent extends Component {
  render() {
    return (
        <div>
            <h1>This is a child component</h1>
            <h1 style={{ color: this.props.color }}>
                GeeksForGeeks
            </h1>
        </div>
    );
}
};