import {useTranslation} from 'react-i18next'
import i18next from "i18next"
import React, { useEffect,useState} from 'react';

const Languageoption = () => {

    const {t} = useTranslation();
    const [lang, setLang] = useState('');

    useEffect(() => { 
        if(localStorage.getItem('language')==null){
           i18next.changeLanguage('ru'); 
           localStorage.setItem('language', 'ru');  
           setLang('ru');
        }
        else{
            let lang=localStorage.getItem('language');
            i18next.changeLanguage(lang);  
            setLang(lang);         
        }

    }, '');


    const handleClick=(e)=>{
        i18next.changeLanguage(e.target.value);   
        localStorage.setItem('language', e.target.value);       
    }
    
    return(
        <div >
            <select onChange={handleClick} className='slctLang' >   
            {
                lang=='en'?
                <option selected value={'en'}>English</option>
                :
                <option  value={'en'}>English</option>
            }           
              {
                lang=='ru'?
                <option selected value={'ru'}>Russia</option>
                :
                <option value={'ru'}>Russia</option>               
            }    
                
            </select>
        </div>
    )
}
export default Languageoption;