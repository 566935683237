import React, { Fragment } from 'react'

function ForgotPassword() {

    return (
        <Fragment>
            {/* <!-- Section Forgot Password --> */}
            <section id="verify-userWrapper">
                <div class="row cus-row">
                    <div class="col-lg-6 col-md-6">
                        <div class="verify_userWrapperLeft">
                            <div class="verify_userLeftOuter">
                                <img src="../assets//images/logo.svg" width="70px" alt="" />
                                <h3>Forgot Password</h3>
                                <ul class="list-unstyled mb-0 d-flex gap-4 justify-content-center">
                                    <li><a href="login.php">Login</a></li>
                                    <li><a href="register.php">Register</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="verify_userWrapperRight">
                            <img src="../assets//images/logo.svg" width="70px" alt="" />
                            <div class="form-group w-75 mt-4">
                                <div class="form-row mb-3">
                                    <label for="userName">User Name/Email</label>
                                    <input type="text" id="userName" class="form-control" />
                                </div>
                                <div class="form-row mb-3 d-flex gap-3">
                                    <button class="hero_btn w-50">Submit</button>
                                </div>
                                <p>Back To <a href="login.php"><b>SIGN IN</b></a></p>
                                <img src="../assets//images/icons/google-play.svg" width="100px" alt="" />
                                <img src="../assets//images/icons/apple.svg" width="100px" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Section Forgot Password --> */}
        </Fragment>
    )
}
export default ForgotPassword;