import React, { useEffect, useState, Fragment,Component } from 'react';
import { BaseUrl, HomeUrl } from '../constants/global';
import axios from "axios";

class commonComponent extends React.Component {     
    
    constructor(props) {
        super(props);
        this.ItemFormControlRight= [];
    }
    
    checkFormControlRightOrNo(itemFormControlRight,ColumnName)
    {
        return itemFormControlRight.some(data => (
            data.CONTROLNAME.toUpperCase()==ColumnName.toUpperCase() && data.ISVISIBLE == true
        ))
    }

    async getSelectedItemData(itemid,_itemType,token, navigate) {        
        let data = [];

        let url= `${BaseUrl}/api/v1/items/getAlterNativeItem?Id=${itemid}&Type=${_itemType}`;

        let _token = token;
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        const res = await axios.get(
            url,
            saerchconfig
        ).then((response) => {
            let _resalternatedata = JSON.parse(response.data.data);
            data=_resalternatedata;
        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');
                window.location.href=HomeUrl;    
            }            
         });
         

         return data
    }

    render() {
        return ''
    }
  }

  
export default commonComponent;