import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

function Footer() {
    
    const { t, i18n } = useTranslation();

    return (
        <Fragment>
            <footer>
                <div className="container">
                    <div className="text-white text-center">
                        <h2 className="section-title">GET IN TOUCH</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer_col text-center">
                                <img src="../assets/images/icons/bg.png" alt="" />
                                <h3>{t('moscowfooter')}</h3>
                                <h5>{t('moscowcompanyfooter')}</h5>
                                <p>
                                {t('moscowaddressfooter')}
                                </p>
                                <a href="tel:+74957211919">+74957211919</a>  
                                <div className="mt-4">
                                    <a href="mailto:diamond.imperial@mail.ru">diamond.imperial@mail.ru</a>                                    
                                </div>

                            </div>
                        </div>
                      
                        <div className="col-lg-6">
                            <div className="footer_col text-center">
                                <img src="../assets/images/icons/bg.png" alt="" />
                                <h3>{t('kostromafooter')}</h3>
                                <h5>{t('kostromacompanyfooter')}</h5>
                                <p>
                                {t('kostromaaddressfooter')}
                               </p>
                               <a href="tel:+74942452500">+74942452500</a>
                               <div className="mt-4">
                                    <a href="mailto:diamond.imperial@mail.ru">diamond.imperial@mail.ru</a>                                    
                                </div>
                            </div>
                        </div>
                    </div>

                 
                    <div className="row mt-5">

                         <div className="col-lg-6">
                            <div className="footer_col border-0 text-center">
                                <img src="../assets/images/icons/bg.png" alt="" />
                                <h3>{t('petersburgfooter')}</h3>
                                <h5>{t('petersburgcompanyfooter')}</h5>
                                <p>
                                {t('petersburgaddressfooter')}
                               </p>
                               <a href="tel:+781233663">+781233663</a>
                               <div className="mt-4">
                                    <a href="mailto:info@dilight.ru">info@dilight.ru</a>                                    
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="footer_col text-center">
                                <img src="../assets/images/icons/bg.png" alt="" />
                                <h3>{t('vladivostokfooter')}</h3>
                                <h5>{t('vladivostokcompanyfooter')}</h5>                               
                                <p>
                                    {t('vladivostokaddressfooter')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="div-footer-social">
                            <ul className="list-unstyled d-flex justify-content-center">
                                <h4 >Let's Connect</h4>
                                <li><img src="../assets/images/icons/fb.png" alt="" /></li>
                                <li><img src="../assets/images/icons/ins.png" alt="" /></li>
                                <li><img src="../assets/images/icons/tw.png" alt="" /></li>
                                <li><img src="../assets/images/icons/wm.png" alt="" /></li>
                            </ul>
                            <p>Copyright © 2023 Imperial Diamond LLC | Legal and Privacy</p>
                        </div>
                    </div>
                </div>
            </footer>

        </Fragment>
    )
}
export default Footer;