
import React, { useEffect, useState, Fragment } from 'react'

import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import { ToastContainer,Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import  commonFunctionClass from '../constants/commonFunctionClass';
import StoneInformationSearch from '../components/StoneInformationSearch';


function Sidebar() {
    const { token, setToken } = useToken();
    const navigate = useNavigate();
    const [orderItemNotifications, setOrderItemNotifications] = useState([]);

    const [_itemTypeState1,setitemTypeState1] = useState('1');
    const [_formNameState1,setformNameState1] = useState('MOSCOW STOCK');
    const [_itemTypeNameState1,setitemTypeNameState1] = useState('MOSCOW STOCK');
    const [_categoryState1,setcategoryState1] = useState('ADVANCESEARCH');
    const [selectedItemState1, setSelectedItemState1] = useState([]);
    const [alternateItemsState1, setAlternateItemsState1] = useState([]);
    const [compareItemsState1, setCompareItemsState1] = useState([]);
    const [itemFormControlRight1, setitemFormControlRight1] = useState([]);
    const [itemImageSource1, setItemImageSource1] = useState('');
    const [itemVideoSource1, setItemVideoSource1] = useState('');
    const [itemCertificateSource1, setItemCertificateSource1] = useState('');
    const [lotIdList1, setlotIdList1] = useState('');

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);
    
    const CommonParameterFilter = async (_mode, _itemType, _category,_formName, _xtraParam) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType,_category,_formName,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight1(data);
        }
        
    }
    
    const getData =  async (_itemId, _itemType, _category, _formName) =>
    {
        setlotIdList1(_itemId);
        const value = await CommonParameterFilter("FORMCONTROL",_itemType,_category,_formName,"");

        const data = await commonFunctionClass().getStoneInfo(token,navigate,_itemId,_itemType);
        if(data.length>0)
        {
            setSelectedItemState1(data[0]);

            setItemImageSource1(data[0].IMAGE);
            setItemVideoSource1(data[0].V360VIDEO);
            setItemCertificateSource1(data[0].CERTLINK);
            
            const data1 = await commonFunctionClass().getAlterNativeItem(token,navigate,_itemId,_itemType);
            setAlternateItemsState1(data1);
            document.getElementById("btnLotIdCertIdClick1").click();
        }
        else
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Data not found");
            setpopupDetailedMsg("Entered stone not found..");
            setShowPopupMsg(true);
        }
    }

    const searchData = async () =>
    {
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let strLotIdCertId = document.getElementById('txtLotIdCertId').value;

        let d1 = await axios.get(
            `${BaseUrl}/api/v1/items/findStoneByLotIdOrCertificateID?StoneIdOrCertId=${strLotIdCertId}`,
            saerchconfig
        ).then((response) => {
            if(response.data.status.toUpperCase()=="ERROR")
            {
                setShowPopupMsg(true);
                setpopupMsgType("warning");
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
            }
            else
            {
                var data = JSON.parse(response.data.data);
                if(data.length>0)
                {
                    setformNameState1(data[0].FORMNAME);
                    setitemTypeNameState1(data[0].ITEMTYPENAME);
                    setitemTypeState1(data[0].ITEMTYPE);
                    setcategoryState1(data[0].CATEGORY);
                    getData(data[0].ITEMID,data[0].ITEMTYPE,data[0].CATEGORY,data[0].FORMNAME)
                }
                else
                {
                    setShowPopupMsg(true);
                    setpopupMsgType("warning");
                    setpopupHeaderMsg("Lot/Certificate not found");
                    setpopupDetailedMsg("Entered Lot Id/Certificate Id not found...");
                }
            }
        })
        .catch((error) => {
            console.log(error);
            if(error.response.status==401)
            {
                localStorage.clear();
                navigate('/');
                window.location.href=HomeUrl;
            }
         });
    }
    
    useEffect(() => {
    }, []);


    function logOut() { 
        localStorage.clear() 
        navigate('/');
      }
    
    return (
        <Fragment>
            
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* <!-- Manege Side Menu Drawer --> */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="sideMenuToggle" aria-labelledby="sideMenuToggleLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                        id="btnclose"></button>
                </div>
                {/* <!-- Side Menu Toggle Body --> */}
                <div className="offcanvas-body">
                    <ul className="nav nav-tabs btn-wrapper border-0" id="header-btnWrapper" role="header-btnWrapper">
                        <li className="nav-item" role="presentation">
                            <button alt="Search" id="search" data-bs-toggle="tab" data-bs-target="#search-tab" type="button"
                                role="tab" aria-controls="search-tab" aria-selected="false">
                                <img src="../../assets/images/search-icon.svg" width="32px" alt=""/>
                                <span>Search</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button alt="Notification" id="notification" data-bs-toggle="tab" data-bs-target="#notification-tab"
                                type="button" role="tab" aria-controls="notification-tab" aria-selected="false">
                                <img src="../assets/images/icons/foot_menu.svg" width="18px" />
                                <span>Notification 1</span>
                            </button>
                        </li>
                    </ul>                    
                    <hr/>
                    <div className="tab-content" id="header-btnWrapperA">
                        <div className="tab-pane fade show active" id="search-tab" role="tabpanel" aria-labelledby="search" tabIndex="0">
                            <div className='row'>
                                <div className='col col-md-9'>
                                    <div className="">
                                        <input type="text" name="txtLotIdCertId" className="form-control" id="txtLotIdCertId"
                                            placeholder="LotId / Certificate No" />
                                    </div>
                                </div>
                                <div className='col col-md-3'>
                                    <button className='hero_btn active' id='btnLotIdCertIdClick' onClick={searchData}><img src="../../assets/images/search-icon.svg" width="30px" alt=""/></button>
                                </div>
                                <button data-bs-toggle="offcanvas" data-bs-target="#diamond-cartSideBaar" id='btnLotIdCertIdClick1' aria-controls="diamond-cartSideBaar" style={{display:'none'}}>hide</button>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="notification-tab" role="tabpanel" aria-labelledby="notification" tabIndex="1">
                            <div className="notification-side--wrapper">
                                <h5>Recent Notification</h5>
                                {
                                    orderItemNotifications.map(item=>(
                                        <ul className="notification-list list-unstyled">
                                        <li className="notification-item">
                                            <h6>Your Order(#{item.orderNo}) Has Been {item.itemOrderStatus} for Stone No {item.lotid}</h6>
                                            <p>Successfully Stone(S) Have Been {item.itemOrderStatus}.</p>
                                        </li>
                                    </ul>
                                    ))
                                }                               
                                <a href="">View all notification</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Side Menu Toggle Body --> */}
            </div>
            {/* <!-- End Manege Side Menu Drawer --> */}
            <StoneInformationSearch
                            ItemType={_itemTypeState1}
                            FormName={_formNameState1}
                            ItemTypeName={_itemTypeNameState1}
                            Category={_categoryState1}
                            ItemFormControlRight= {itemFormControlRight1}
                            SelectedItem = {selectedItemState1}
                            AlternateItems = {alternateItemsState1}
                            ItemImageSource = {itemImageSource1}
                            ItemVideoSource = {itemVideoSource1}
                            ItemCertificateSource = {itemCertificateSource1}
                            CompareItems = {compareItemsState1}
            />
        </Fragment>
    )
}
export default Sidebar;