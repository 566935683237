import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Index from './pages/Index'
import ItemSearch from './pages/ItemSearch';
import ItemList from './pages/ItemList';
import Login from './pages/Login';
import Register from './pages/Register';
import GuestLogin from './pages/GuestLogin';
import ForgotPassword from './pages/ForgotPassword';

import CustomOrder from './pages/CustomOrder';
import CustomOrderDetails from './pages/CustomOrderDetails';
import Cart from './pages/Cart';

import Order from './pages/Order';

import Watch from './pages/Watch';
import Landing from './pages/Landing';
import useToken from './components/useToken';

import MyAccountSummary from './pages/MyAccountSummary';

import MyAccountDetail from './pages/MyAccountDetail';
import ChangePassword from './pages/ChangePassword';
import MySavedSearch from './pages/MySavedSearch';

import GIAItemSearch from './pages/GIAItemSearch';
import GIAItemList from './pages/GIAItemList';

import ExclusiveItemList from './pages/ExclusiveItemList';
import ExclusiveItemSearch from './pages/ExclusiveItemSearch';

import ParentComponent from './pages/ParentComponent';
import ParentFunction from './pages/ParentFunction';

import NotFound from './pages/NotFound';
import Gallery from './pages/Gallery';



function App() {

  const { token, setToken } = useToken();


  
  if(!token) {
    return (
     <Router>
        <Routes>
            <Route exact path="/"  element={<Landing/>}/> 
            <Route exact path="/login"  element={<Login setToken={setToken}/>}/> 
            <Route exact path="/register"  element={<Register/>}/>     
            <Route exact path="/guestlogin"  element={<GuestLogin/>}/>       
            <Route exact path="/gallery"  element={<Gallery/>}/> 
            <Route exact path="/forgotpassword"  element={<ForgotPassword/>}/> 
            <Route exact path="*"  element={<NotFound/>}/> 
        </Routes>
      </Router>
    )
      //return <Login setToken={setToken} />
  }

  return (
    <Router>
      <div className="App" style={{background:"#fff"}}>

        <Routes>
          
           <Route exact path="/"  element={<Index/>}/>
           <Route exact path="/itemsearch/:searchid"  element={<ItemSearch/>}/>
           <Route exact path="/itemlist"  element={<ItemList/>}/>  
           <Route exact path="/itemlist/:searchid"  element={<ItemList/>}/>  

           <Route exact path="/register"  element={<Register/>}/>     
           <Route exact path="/guestlogin"  element={<GuestLogin/>}/>    
           <Route exact path="/forgotpassword"  element={<ForgotPassword/>}/>  
           
           <Route exact path="/customorder"  element={<CustomOrder/>}/>   
           <Route exact path="/customorderdetails"  element={<CustomOrderDetails/>}/> 

           <Route exact path="/cart"  element={<Cart/>}/>   
           <Route exact path="/order"  element={<Order/>}/>   
           <Route exact path="/watch"  element={<Watch/>}/>
           <Route exact path="/mysavedsearch"  element={<MySavedSearch/>}/>         
           
           <Route exact path="/myaccount/summary"  element={<MyAccountSummary/>}/>
           <Route exact path="/myaccount/detail"  element={<MyAccountDetail/>}/>
           <Route exact path="/myaccount/changepassword"  element={<ChangePassword/>}/>           
           
                      
           <Route exact path="/giaitemsearch/:searchid"  element={<GIAItemSearch/>}/>
           <Route exact path="/giaitemlist/:searchid"  element={<GIAItemList/>}/>
           
           {/* <Route exact path="/exclusiveitemsearch/:searchid"  element={<ExclusiveItemSearch/>}/> */}
           <Route exact path="/exclusiveitemlist/:searchid"  element={<ExclusiveItemList/>}/>
           <Route exact path="/exclusiveitemsearch/:searchid"  element={<ExclusiveItemSearch/>}/>

           <Route exact path="/parentcomponent"  element={<ParentComponent/>}/>
           <Route exact path="/parentfunction"  element={<ParentFunction/>}/>
           
           <Route exact path="*"  element={<NotFound/>}/> 
        </Routes>
        
      </div>
    </Router>

 
  );
}

export default App;
