import React, { useEffect, useState, Fragment,useRef } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AccNav from '../components/AccNav'
import { ToastContainer, Toast } from 'react-bootstrap';
import commonFunctionClass from '../constants/commonFunctionClass';

function CustomOrderDetails() {
    const navigate = useNavigate();
    const { token, setToken } = useToken();
 
    const [materials, setMaterials] = useState([]);
    
    const _itemType = useRef('4');
    const _formName = useRef('MOSCOW CUSTOM ORDER');
    const _itemTypeName = useRef('MOSCOW CUSTOM ORDER');
    const _category = useState('HOMEPAGE');

    const [status, setStatus] = useState('All');
   
    const [customorders, setCustomOrders] = useState([]);    

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [heading, setHeading] = useState("");
    const [materialType, setMaterialType] = useState("");
    
    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "MATERIALS")
        {
            setMaterials(data);
        }
    }

    const handleStatusChange = (e) => {
        let status=document.getElementById('status').value;
        setMaterialType(status);
    }
    
    const handleClick = async () =>
    {
        getData();
    }

    const handleViewOrder = (e) =>
    {
        let url = e.target.dataset.path;
        window.open(url, '_blank');
    }

    const handleApproveReject = (e) =>
    {
        let Status = e.target.dataset.status;
        let OrderId = e.target.dataset.orderid;

        let bodyParameters = {
            STATUS: Status,
            REMARK: '',
            ORDERID: OrderId,
            SALESPERSONREMARK: '',
            MODE: 'USERUPDATE'
        };

        const config = {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data"  }
        };

        axios.post(
           `${BaseUrl}/api/v1/items/CustomOrderApprovedRejectPending`,
            bodyParameters,
            config
        ).then((response) => {
            setpopupMsgType("light");
            if (response.data.status == 'Success') {                
                getData();
            }
            else
            {
                setpopupMsgType("warning");
                setCustomOrders([]);
            }
            
            setpopupHeaderMsg(response.data.header);
            setpopupDetailedMsg(response.data.message);
            setShowPopupMsg(true);

        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');
                window.location.href=HomeUrl;    
            }
            
         });
    }

    async function getData()
    {
        let bodyParameters = {
            FROMDATE: fromDate,
            TODATE: toDate,
            HEADING: heading,
            MATERIALID: materialType
        };

        const config = {
            headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data"  }
        };

        let result = await axios.post(
           `${BaseUrl}/api/v1/items/getcustomorder`,
            bodyParameters,
            config
        ).then((response) => {
            setpopupMsgType("light");
            if (response.data.status == 'Success') {
                let data = JSON.parse(response.data.data);
                setCustomOrders(data);
            }
            else
            {
                setpopupMsgType("warning");
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
                setShowPopupMsg(true);
                setCustomOrders([]);
            }
        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');
                window.location.href=HomeUrl;    
            }
            
         });
    }

    const getCurrentDateInput = (Mode) => {

        const dateObj = new Date();
      
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();
      
        const shortDate = `${year}-${month}-${day}`;
      
        return shortDate;
      };

    useEffect(() => {
        CommonParameterFilter('',"MATERIALS");
        let day = new Date().toLocaleString("en-US", { day : '2-digit'});
        let month=new Date().toLocaleString("en-US", { month : '2-digit'});
        let year = new Date().getFullYear();
        var fromDate= year + "-" + month + "-" + "01";
        var toDate= year + "-" + month + "-" + day;
        setFromDate(fromDate);
        setToDate(toDate);        
    }, []);

    return (
        <Fragment>

            <Header />
            <Sidebar />
             
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            
            {/* <!-- Diamond Search Filter --> */}
            <section id="search-stone" className="diamond-search--filter">
                <div className="search-stone">
                    <AccNav />
                </div>
            </section>
            {/* <!-- End Diamond Search Filter --> */}

            <section id="profile-pageSection">
                <div class="max-container container">
                    <div class="order_details row rows-col-lg-5 align-items-end mb-4">
                        <div class="col">
                            <div class="form-group">
                                <label for="">From Date</label>
                                <input type="date" name="fromDate" class="form-control" id="fromDate"  onChange={e => setFromDate(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="">To Date</label>
                                <input type="date" name="toDate" class="form-control" id="toDate" onChange={e => setToDate(e.target.value)} />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="">Order Heading</label>
                                <input type="text" name="OrderHeading" class="form-control" id="OrderHeading" onChange={e => setHeading(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col">
                            <select class="form-select form-control" style={{ width: 250 ,marginRight: 10}} id="status" onChange={handleStatusChange}>
                                <option value='' selected>All</option>                                {
                                materials.map(material=>(                                        
                                        <option value={material.MATERIALID}>{material.MATERIALNAME}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div class="col mb-1">
                            <button class="hero_btn w-100" onClick={handleClick}>Search</button>
                        </div>
                    </div>
                    <table class="table table-bordered ">
                        <thead class="table-dark">
                            <tr>
                                <td>Action</td>
                                <td>Order No</td>
                                <td>Order Date</td>
                                <td>Heading</td>
                                <td>Remark</td>
                                <td>Order Type</td>
                                <td>Order Status</td>
                                <td>Sale Person</td>
                                <td>Sale Person Remark</td>
                                <td>File Heading</td>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            customorders.map(item => (
                                <tr>
                                    <td>
                                        <ul class="list-unstyled mb-0 d-flex gap-2 align-items-center">
                                        {
                                            item.STATUS.toUpperCase()=="PENDING"?
                                            <li>
                                                <a href="#" class="d-block" onClick={handleApproveReject}  >
                                                    <img src="../assets//images/icons/confirm.svg" data-status={'APPROVED'} data-OrderId={item.ORDERID} width="25px" title="Order Confirm" alt="" />
                                                </a>
                                            </li>
                                            :
                                            ''
                                        }
                                        {
                                            item.STATUS.toUpperCase()=="PENDING" || item.STATUS.toUpperCase()=="APPROVED"?
                                            <li>
                                                <a href="#" class="d-block" onClick={handleApproveReject}  >
                                                    <img src="../assets//images/icons/cancel.svg" data-status={'CANCELED'} data-OrderId={item.ORDERID} width="25px" title="Order Cancel" alt="" />
                                                </a>
                                            </li>
                                            :
                                            ''
                                        }
                                            <li>
                                                <a href={"../"+item.FILEPATH} class="d-block" download>
                                                    <img src="../assets//images/icons/view.svg" width="25px" title="View Order" data-Path={item.FILEPATH} alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>{item.ORDERNO}</td>
                                    <td>{item.ORDERDATE}</td>
                                    <td>{item.HEADING}</td>
                                    <td>{item.REMARKS}</td>
                                    <td>{item.MATERIALNAME}</td>
                                    <td>{item.STATUS}</td>
                                    <td>{item.APPUSERAPPROVEDBYNAME}</td>
                                    <td>{item.SALESPERSONREMARK}</td>
                                    <td>{item.FILENAME}</td>
                              </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </section>
            
        </Fragment>
    )
}
export default CustomOrderDetails;
