import React, { useEffect, useState, Fragment,Component,useRef} from 'react'
import ReactDOM from 'react-dom';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import  CommonComponent from '../constants/commonComponent';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useTranslation } from 'react-i18next';
import  commonFunctionClass from '../constants/commonFunctionClass';
import { ToastContainer, Toast } from 'react-bootstrap';


function VideoView(prop) {
    const navigate = useNavigate();
    const { token, setToken } = useToken();
    
    const { t, i18n } = useTranslation();
    const [userRole, setUserRole] = useState('');
    
    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    
    return (
        <div>
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>
            
            <div className="offcanvas offcanvas-end w-50 canfava_f" tabIndex="-1" id="company-Video" // id="diamond-video"
                aria-labelledby="diamond-videoLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <video width="750" height="500" controls >
                        <source src="../../assets/video/Video.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
            
        </div>
    )
}
export default VideoView;