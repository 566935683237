import React, { Fragment } from 'react';
import {   
    Link
  } from "react-router-dom";
import Footer from '../components/Footer'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Languageoption from '../components/language-dropdown';
import { useTranslation } from 'react-i18next';
import VideoView from '../components/VideoView';
import Gallery from '../components/Gallery';

function Landing() {
    const { t, i18n } = useTranslation();
    return (
        <Fragment>
            <header className="bg-dark landing_header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <div className="navbar-brand">
                            <a href="index.php">
                                <img src="../assets/images/logo.png" width="100px" alt="" />
                            </a>
                        </div>
                        <ul className="navbar-nav ms-auto align-items-center"> 
                            <li className="nav-item">
                                <Link to="/login" className="nav-link text-white">{t('signin')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/register" className="nav-link text-white">{t('register')}</Link>
                            </li>
                            <li className="nav-item">
                                {/* <a href="/gallery" className="nav-link text-white">{t('viewgallery')}</a> */}
                                {/* <a href="#" data-bs-toggle="offcanvas" data-bs-target="#company-video" aria-controls="company-video" className="nav-link text-white">{t('viewgallery')}</a> */}
                                <a href="#" data-bs-toggle="offcanvas" data-bs-target="#company-gallery" aria-controls="company-gallery" className="nav-link text-white">{t('viewgallery')}</a>
                            </li>
                            <li className="nav-item">
                              <Languageoption/>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link box">
                                    <img src="../assets/images/menu-icon.svg" width="30px" alt="" />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <section id="landing_heroSection">

             {/* <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                >
                <SwiperSlide>Slide 1</SwiperSlide>
                <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                ...
                </Swiper> */}


            <div className="swiper hero_sliderWrapper">
                <div className="swiper-wrapper">
                    
                    <div className="swiper-slide">
                        <div className="hero_sectionSliderWrapper">
                            {/* <video className="w-100" autolay loop muted>
                                <source src="../assets/video/hp-video.mp4" />
                            </video> */}
                           <img src="../assets/images/Rectangle 13 (1).png" className="img-fluid w-100 img-1" alt="" />
                           {/* <img src="../assets/images/Rectangle 13.png" className="img-fluid img-2 w-100" alt="" /> */}
                            <div className="hero_sectionSliderTextWrapper top50">
                                <div className="container">
                                    <div className="col-lg-6 col-md-7">
                                        <h5>{t('welcometo')}</h5>
                                        <h1 className="mb-5">{t('yakutsk')} <br /> {t('diamonds')}</h1>
                                        <h6>{t('anyshape')}.</h6>
                                        {/* <a href="#">Search Diamod</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="hero_sectionSliderWrapper">
                            <img src="../assets/images/hero-bg2.png" className="img-fluid w-100" alt="" />
                            <div className="hero_sectionSliderTextWrapper top25">
                                <div className="container">
                                    <div className="col-lg-6 col-md-6">
                                        <h5>SAY IT</h5>
                                        <h1>IN COLOUR</h1>
                                        <h6>SPECIAL OFFER ON ALL COLOURED STONES THIS WEEK!</h6>
                                        <a href="index.php">Search Diamod</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="hero_sectionSliderWrapper">
                            <img src="../assets/images/hero-bg1.png" className="img-fluid w-100" alt="" />
                            <div className="hero_sectionSliderTextWrapper top40">
                                <div className="container">
                                    <div className="col-lg-6 col-md-6">
                                        <h5>IMPERIAL DIAMOND .LIVE<br />GET YOUR DIAMONDS</h5>
                                        <h1>FROM THE<br />SOURCE</h1>
                                        <h6>THE GLOBAL WHOLESALER FOR TOP DESIGNER BRANDS</h6>
                                        <a href="index.php">Search Diamod</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-pagination"></div>
            </div>


            </section>

            <section id="convinience_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/convenience.jpg" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white text-center top50">
                            <h2 className="section-title"><span></span>{t('convenience')}</h2>
                            <p className="section-para">
                                {t('conveniencedesc')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="control_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/control.png" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white top75 left10">
                            <h2 className="section-title"><span></span>{t('control')}</h2>
                            <p className="section-para">
                                {t('control')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="present_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/in-person.png" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white top40">
                            <h2 className="section-title"><span></span>{t('inpresent')}</h2>
                            <p className="section-para">
                                {t('inpresentdesc')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="certificate_section">
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white left10">
                            <h2 className="section-title"><span></span>{t('register')}</h2>
                            <p className="section-para mb-4">
                                {t('registerdesc')}
                            </p>
                            <Link to="/register" className="hero_btn">{t('registernow')}</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section id="register_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/register.png" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content left60 text-white top60">
                            <h2 className="section-title"><span></span>{t('imperialdiamond')}</h2>
                            <p className="section-para">
                              {t('imperialdiamonddesc')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="bgs_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/distribution-m.png" className="img-fluid w-100" alt="" />
                    <img src="../assets/images/distribution-m.png" className="img-fluid w-100 cus-img" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white text-center left10 top88">
                            <img src="../assets/images/distribution2.gif" className="img-fluid mb-5" alt="" />
                            <h2 className="section-title">{t('aboutus')}</h2>
                            <p className="section-para">                            
                                {t('aboutusdesc1')}
                             </p>
                            <p className="section-para">   
                                                        
                                1.) {t('moscow')} <br/>
                                2.) {t('kostroma')} <br/>
                                3.) {t('petersburg')} <br/>
                                4.) {t('vladivostok')} <br/>
                                
                                {/* 5.) Armenia */}

                            </p>
                             <p>
                              {t('aboutusdesc2')}
                             </p> 
                        
                        </div>
                    </div>
                </div>
            </section>

            <section id="grading_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/grading-system.png" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white text-end top40 left50">
                            <h2 className="section-title">{t('diamond')}</h2>
                            <p className="section-para mb-4">
                                Admire the incomparable brilliance <br/>
                                {t('diamonddesc')}
                            </p>
                           
                        </div>
                    </div>
                </div>
            </section>

            <section id="certification_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/gemstone.png" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="div-content text-white text-center">
                            <h2 className="section-title">{t('gemstone')}</h2>
                            <p className="section-para">
                                    Glimmers of inspiration <br/>
                                    {t('gemstonedesc1')}

                            </p>
                            {/* <p className="section-para mt-3">
                            {t('gemstonedesc2')}
                            </p> */}
                           
                        </div>
                    </div>
                </div>
            </section>

            

            <section id="ethical_section">
                <div className="container">
                    <div className="row g-3">
                        
                        <div className="col-lg-6 col-md-6">
                            <div className="ethical_sectionInner martop0">
                                <img src="../assets/images/gold1.png" className="img-fluid w-100" alt="" />
                                <div className="div-content text-white">
                                    <h2 className="section-title">{t('gold')}</h2>
                                    <p className="section-para mb-5">
                                           {t('goldesc')}
                                    </p>
                                    {/* <a href="index.php" className="hero_btn">KNOW MORE</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                        <div className="ethical_sectionInner martop-515">
                                <img src="../assets/images/jewellery.png" className="img-fluid w-100" alt="" />
                                <div className="div-content text-white">
                                    <h2 className="section-title"><a href='https://www.dilight.ru'>{t('jewellery')}</a></h2>
                                     <p className="section-para mb-5">
                                       {t('jewellerydesc')}
                                    </p>
                                    {/* <a href="index.php" className="hero_btn">KNOW MORE</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="ethical_sectionInner mrtop270">
                                <img src="../assets/images/ethical-bg.png" className="img-fluid w-100" alt="" />
                                <div className="div-content text-white">
                                <h2 className="section-title">{t('ETHICALHeading1')}<br />{t('ETHICALHeading2')}</h2>
                                    <p className="section-para mb-5">
                                        {t('ethicaldesc')}
                                    </p>
                                    {/* <a href="index.php" className="hero_btn">{t('knowmore')}</a> */}
                                </div>
                            </div>
                        </div>                        
                        <div className="col-lg-6 col-md-6">
                            <div className="margin-img  margin-img1 mrtop270">
                                {/* <img src="../assets/images/VideoThumbnail.png" className="img-fluid w-100 imgheight" alt=""/> */}
                                <img src="../assets/images/ethical-bg1.png" className="img-fluid w-100 " alt=""/>
                                <div className="div-content text-white">
                                    <h2 className="section-title">{t('ManufacturingUnitName')}</h2>
                                    <p className="section-para mb-5">
                                        {t('ManufacturingUnitInfo')}
                                    </p>                                    
                                    <a href="#" data-bs-toggle="offcanvas" data-bs-target="#company-Video" aria-controls="company-Video" className="hero_btn">{t('seevideo')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divcolor'></div>
            </section>


            <section id="testimonial_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/certificate.png" className="img-fluid w-100" alt="" />
                </div>
                <div className="container">
                    
                </div>
            </section>

            <section id="association_section">
                <div className="container-fluid p-0">
                    <img src="../assets/images/certificate.png" className="img-fluid w-100" alt="" />
                </div>
               

                <Footer/>
               
            </section>
           
            <VideoView/>

            <Gallery/>
        </Fragment>

    )
}
export default Landing;