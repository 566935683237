import React, { useEffect, useState, Fragment,Component,useRef} from 'react'
import ReactDOM from 'react-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import  CommonComponent from '../constants/commonComponent';
import { useNavigate } from 'react-router-dom';
import AccNav from '../components/AccNav'
import { ToastContainer, Toast } from 'react-bootstrap';
import Pagination from '../components/Pagination';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import  commonFunctionClass from '../constants/commonFunctionClass';
import StoneInformation from '../components/StoneInformation';

function Cart() {
    const navigate = useNavigate();
    const { token, setToken } = useToken();

    const { t, i18n } = useTranslation();
    const [userRole, setUserRole] = useState('');

    const _itemType = useRef('1');
    const _formName = useRef('MOSCOW CART');
    const _itemTypeName = useRef('MOSCOW STOCK');
    const _category = useRef('HOMEPAGE');
    const [itemFormControlRight, setitemFormControlRight] = useState([]);

    const [_itemTypeState,setitemTypeState] = useState('1');
    const [_formNameState,setformNameState] = useState('MOSCOW CART');
    const [_itemTypeNameState,setitemTypeNameState] = useState('MOSCOW STOCK');
    const [_categoryState,setcategoryState] = useState('HOMEPAGE');
    const [selectedItemState, setSelectedItemState] = useState([]);
    const [alternateItemsState, setAlternateItemsState] = useState([]);
    const [compareItemsState, setCompareItemsState] = useState([]);

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const [defaultItemCount,setDefaultItemCount] =  useState(0);
    const [giaItemCount,setGiaItemCount] =  useState(0);
    const [exclusiveItemCount,setExclusiveItemCount] =  useState(0);

    const [items, setitems] = useState([]);

    const [cartItemTotal, setCartItemTotal] = useState(0);
    const [showCartToast, setShowCartToast] = useState(false);
    const [showOrderToast, setOrderToast] = useState(false);
    const [showWatchToast, setShowWatchToast] = useState(false);

    const [loading, setLoading] = useState(false);
    const [itemImageSource, setItemImageSource] = useState('');
    const [itemVideoSource, setItemVideoSource] = useState('');
    const [itemCertificateSource, setItemCertificateSource] = useState('');

    const [totalitemsRemoved, settotalitemsRemoved] = useState(0);
    const [totalwatchitemsAdded, setTotalWatchItemsAdded] = useState(0);
    const [totalitemsAdded, settotalitemsAdded] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(100);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = items.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(items.length / recordsPerPage);

    const [showCartToastHoldItemAvailable, setCartToastHoldItemAvailable] = useState(false);

    function onChangeChild() {

    }

    const changeItemType = (ItemType) => {
        let nav = document.querySelectorAll(".itemTypeClass");
        let navName = document.getElementById('nav'+ItemType);
        _itemType.current=ItemType;
        _formName.current='MOSCOW CART';
        _itemTypeName.current='MOSCOW STOCK';

        setitemTypeState(_itemType.current);
        setformNameState(_formName.current);
        setitemTypeNameState(_itemTypeName.current);

        nav.forEach((element) => element.classList.remove('active'));
        navName.classList.add('active');

        if(ItemType == "2")
        {
            _formName.current='GIA CART';
            _itemTypeName.current='GIA STOCK';
            setformNameState(_formName.current);
            setitemTypeNameState(_itemTypeName.current);
        }
        else if(ItemType == "3")
        {
            _formName.current='EXCLUSIVE CART';
            _itemTypeName.current='EXCLUSIVE STOCK';
            setformNameState(_formName.current);
            setitemTypeNameState(_itemTypeName.current);
        }

        getCartData();
    }

    const handleExportToExcel=async()=>{
        let checkboxes = document.getElementsByName('chk_item[]');

        const data = await commonFunctionClass().ExportToExcel(checkboxes,items,itemFormControlRight,t);

        setpopupMsgType(data[0].MsgType);
        setpopupHeaderMsg(data[0].HeaderMsg);
        setpopupDetailedMsg(data[0].DetailedMsg);
        setShowPopupMsg(data[0].PopupMsg);
    }

    const handleCompareItem = async () => {
        setCompareItemsState([]);
        let checkboxes = document.getElementsByName('chk_item[]');
        let checkboxvals="";
        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(indx<=1)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Warning: Select item");
            setpopupDetailedMsg("Please select atleast 2 item");
            setShowPopupMsg(true);
            return;
        }

        const data = await commonFunctionClass().getStoneInfo(token,navigate,checkboxvals,_itemType.current);
        setCompareItemsState(data);
    }

    const handleSentMail = async () => {
        // setLoading(true);
        let checkboxes = document.getElementsByName('chk_item[]');
        let checkboxvals="";
        let indx = 0;

        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(indx <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);
            return;
        }

        const data = await commonFunctionClass().sentMail(token,navigate,checkboxvals,_itemType.current,_formName.current,"");
        // setLoading(false);
        setpopupMsgType(data[0].MsgType);
        setpopupHeaderMsg(data[0].HeaderMsg);
        setpopupDetailedMsg(data[0].DetailedMsg);
        setShowPopupMsg(data[0].PopupMsg);
    }

    const getItemCountData = async (Mode) => {
        const dataCart = await commonFunctionClass().getItemCount(token,navigate,Mode);
        const dataCount = dataCart[0];
        setDefaultItemCount(dataCount.ITEMTYPE1);
        setGiaItemCount(dataCount.ITEMTYPE2);
        setExclusiveItemCount(dataCount.ITEMTYPE3);
    }

    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);

        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight(data);
        }
    }

    window.addEventListener('resize', function(event) {
        manageHeight();
    }, true)

    function manageHeight() {
        const script = document.createElement('script');
        script.src = "../assets/js/list-height.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }

    function getCartData() {
        CommonParameterFilter('',"FORMCONTROL");

        let _mode="";
        let url ="";
        let xtraParam=`<FORMNAME>${_formName.current}</FORMNAME>`;

        setLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        url = `${BaseUrl}/api/v1/userinfo/currentuser`;
        axios.get(url,config).then((response) => {
            setLoading(false);
            if(response.status==200){
                setUserRole(response.data.data.role);
            }
        });

        setLoading(true);
        url = `${BaseUrl}/api/v1/items/GetCart?ItemType=${_itemType.current}&xtraParam=${xtraParam}`;
        axios.get(url, config).then((response) => {
            setLoading(false);
            let data1 = JSON.parse(response.data.data);
            setitems(data1);
            setCartItemTotal(data1.length);
            manageHeight();

            getItemCountData("CART");

        }).catch((error) => {
            setLoading(false);
            if(error.response.status==401)
            {
                localStorage.clear();
                navigate('/');
                window.location.href=HomeUrl;
            }
         });
    }

    useEffect(() => {


        getCartData();

    }, []);

    useEffect(() => {
        manageHeight();
    }, []);

    const handleClearCart = () => {
        let checkboxvals = "";
        let checkboxes = document.getElementsByName('chk_item[]');
        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(checkboxvals.length <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);
            return;
        }

        let _token = token;

        setLoading(true);

        let bodyParameters = {
            userid: '54544545',
            cartitemids: checkboxvals,
            itemtype: _itemType.current,
            remark: '',
            mode:'CLEAR'
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.post(
            `${BaseUrl}/api/v1/items/clearcart`,
            bodyParameters,
            config
        ).then((response) => {
            setLoading(false);

            setpopupMsgType("light");

            if (response.data.status == 'Success') {
                settotalitemsAdded(response.data.totalitems);
                settotalitemsRemoved(response.data.totalitems);
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
                setShowPopupMsg(true);

                getCartData();

                unCheckAllCheckboxes();
            }
            else
            {
                setpopupMsgType("warning");
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
                setShowPopupMsg(true);
            }
        }).catch((error) => {
            console.error(error.response.status);
            //alert(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear();
                navigate('/');
                window.location.href=HomeUrl;
            }

         });
    }

    const handleWatch = async () => {
        let checkboxvals = "";
        let checkboxes = document.getElementsByName('chk_item[]');

        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(checkboxvals.length <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);
            return;
        }

        const data = await commonFunctionClass().addToWatch(token,navigate,checkboxvals,_itemType.current,"INSERT","");

        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
        setShowPopupMsg(true);
        unCheckAllCheckboxes();
    }

    const handleOrder = async () => {
        let checkboxvals = "";
        let checkboxes = document.getElementsByName('chk_item[]');

        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(checkboxvals.length <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);
            return;
        }

        setLoading(true);
        const data = await commonFunctionClass().addToOrder(token,navigate,checkboxvals,_itemType.current,"INSERT","");
        setLoading(false);
        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
        setShowPopupMsg(true);
        unCheckAllCheckboxes();
        getCartData();
    }

    const handleItemImage = (e) => {
        let item_image_source = e.target.dataset.itemimagesource;
        setItemImageSource(item_image_source);
    }

    const handleItemVideo = (e) => {
        let item_video_source = e.target.dataset.itemvideosource;
        setItemVideoSource(item_video_source);
    }

    const handleItemCertificate = (e) => {
        let item_certificate_source = e.target.dataset.itemcertificatesource;
        setItemCertificateSource(item_certificate_source);
    }

    function checkFormControlRightOrNo(ColumnName)
    {
        return CommonComponent.prototype.checkFormControlRightOrNo(itemFormControlRight,ColumnName);
    }

    const handleItemCart = (e) => {
        let itemId = e.target.dataset.itemid;
        let itemInfo = items.filter((item) => item.ITEMID == itemId)[0];
        getSelectedItemData(itemId,itemInfo);
    }

    const getSelectedItemData = async (itemId,itemInfo) => {
        setSelectedItemState(itemInfo);
        const data = await commonFunctionClass().getAlterNativeItem(token,navigate,itemId,_itemType.current);
        setAlternateItemsState(data);
    }

    function unCheckAllCheckboxes() {
        var checkboxes = document.getElementsByName('chk_item[]');
        for (var i in checkboxes) {
            if (checkboxes[i].checked == true) {
                checkboxes[i].checked = false;
            }
        }
    }

    const handleCheckStatusChange =(e)=>{
        filterdivvisibility();
     }

    function filterdivvisibility()
    {
        let div_ClearAll =document.getElementById('div_ClearAll');
        let div_Filter =document.getElementById('div_Filter');
        let checkboxes = document.getElementsByName('chk_item[]');

        let isanycheckboxselected=false;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                isanycheckboxselected=true;
            }
        }

        if(isanycheckboxselected){
            div_ClearAll.style.display='block';
            div_Filter.style.display='none';
        }
        else{
            div_ClearAll.style.display='none';
            div_Filter.style.display='block';
        }
    }

    const handleCheckAllCheckboxes = (event) => {
        const allcheckboxChecked = event.target.checked;
        var checkboxes = document.getElementsByName('chk_item[]');
        if (allcheckboxChecked) {
            for (var i in checkboxes) {
                if (checkboxes[i].checked == false) {
                    checkboxes[i].checked = true;
                }
            }
        }
        else {
            for (var i in checkboxes) {
                if (checkboxes[i].checked == true) {
                    checkboxes[i].checked = false;
                }
            }
        }
    }

    const Item = (props) => {

        let itemJsonData = props.itemdata;

        let _parcelstatus='';

        if(itemJsonData.PARCELSTATUS.toUpperCase() == 'STOCK'){
            _parcelstatus='A';
        }
        else if(itemJsonData.PARCELSTATUS.toUpperCase() == 'HOLD'){
            _parcelstatus='H';
        }
        else if(itemJsonData.PARCELSTATUS.toUpperCase() == 'INVOICE'){
            _parcelstatus='C';
        }

        return (
            <li className="d-flex search-result--tableRow col-data--rowWrapper" key={itemJsonData.ITEMID}>
                {
                    itemFormControlRight.map(data => (
                        <div>
                        {
                            data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "MAINGRID"?
                            ''
                        :
                        data.CONTROLLABLE.toUpperCase()=="STATUS"?
                        <div className="data--nameOuter nrml-width" data-header="STATUS">
                            <div className="ps-3 d-flex justify-content-between">
                                {
                                        _parcelstatus == 'H' ?
                                            <span className="data-name red">{_parcelstatus}</span>
                                        :
                                        _parcelstatus == 'C' ?
                                            <span className="data-name green">{_parcelstatus}</span>
                                        :
                                            <span className="data-name green">{_parcelstatus}</span>
                                }

                                <input className="form-check-input rounded-0" type="checkbox"
                                    value={itemJsonData.ITEMID}
                                    name="chk_item[]" key={itemJsonData.ITEMID} id={itemJsonData.ITEMID}  onChange={handleCheckStatusChange} />
                            </div>
                        </div>
                        :
                        data.CONTROLLABLE.toUpperCase()=="DETAILS"?
                        <div className="nrml-width data--nameOuter" data-header="DETAILS">
                            <ul className="list-unstyled mb-0 d-flex justify-content-between">
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-img" aria-controls="item-img">
                                        <img width="18px" onClick={handleItemImage} data-itemid={itemJsonData.ITEMID} data-itemimagesource={itemJsonData.IMAGE} src="../assets/images/icons/image-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-video" aria-controls="diamond-video">
                                        <img width="20px" onClick={handleItemVideo} data-itemid={itemJsonData.ITEMID} data-itemvideosource={itemJsonData.V360VIDEO} src="../assets/images/icons/video-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-certificate" aria-controls="diamond-certificate">
                                        <img width="16px" onClick={handleItemCertificate} data-itemid={itemJsonData.ITEMID} data-itemcertificatesource={itemJsonData.CERTLINK} src="../assets/images/icons/certificate-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-cart" aria-controls="diamond-cart">
                                        <img width="18px" onClick={handleItemCart} data-itemid={itemJsonData.ITEMID} src="../assets/images/icons/bag-icon.svg" alt="" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        :
                        <div className="data--nameOuter nrml-width" data-header={t(data.CONTROLLABLE)}>
                            <span className="data-name">{itemJsonData[data.CONTROLNAME]}</span>
                        </div>
                        }
                        </div>
                    ))

                }
                {/* <button className="view-all--reasult">View All</button> */}
            </li>
        )
    };

    const popovercontent = (
        <div class="statusWrapper">
            <div class="row g-2">
                <div class="col-sm-4 col-6">
                    <div class="statusInner avl">
                        <a> A-Available</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner memo">
                        <a>M-Memo</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner hold">
                        <a >H-Hold</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner upcmg">
                        <a>U-Upcoming</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner confirm">
                        <a>C-Confirm</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner exclusive">parcelstatus
                        <a>E-Exclusive</a>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <Header />
            <Sidebar />

            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* <!-- Diamond Search Filter --> */}
            <section id="search-stone" className="diamond-search--filter">
                <div className="search-stone">
                    <AccNav />
                </div>
            </section>
            {/* <!-- End Diamond Search Filter --> */}

            {/* <!-- Diamond List Name --> */}
            <section id="id-diamond--name" className="diamond-search--filter">
                <div className="search-stone">
                    <div className='container max-container'>
                        <ul className="nav nav-pills cartTab gap-3">
                            <li className="nav-item">
                                <button className="nav-link itemTypeClass active" id="nav1" name='nav1' aria-current="page" onClick={() => changeItemType("1")}>{t('Default')}&nbsp;({defaultItemCount})</button>
                            </li>
                            <li className="nav-item" >
                                <button className="nav-link itemTypeClass" id="nav2" name='nav2' onClick={() => changeItemType("2")} >{t('GIA')}&nbsp;({giaItemCount})</button>
                            </li>
                            <li className="nav-item" >
                                <button className="nav-link itemTypeClass" id="nav3" name='nav3' onClick={() => changeItemType("3")}>{t('Exclusive')}&nbsp;({exclusiveItemCount})</button>
                            </li>
                            <li className="nav-item">
                                <button onClick={handleClearCart} className="btn btn-dark customeButtonRightSide">{t('removecartitems')}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* <!-- End Diamond List Name --> */}
            <div id="divData">
             {
                    loading?
                      <div className='loader'>
                         <img src='../../assets/images/insideLoader.gif'></img>
                      </div>
                    :
                    <section id="serach-result--outerWrapper">
                        <div className="search-result--outerWrapper">
                            <ul className="list-unstyled mb-0 search-result--tableWrapper">

                                <li className="d-flex text-white search-result--tableRow">
                                    {
                                        itemFormControlRight.map(data => (
                                            data.ISCONTROL == true?
                                            ''
                                            :
                                            data.PARENTCONTROLNAME.toUpperCase() != "MAINGRID"?
                                                ''
                                            :
                                            data.CONTROLLABLE.toUpperCase()=="STATUS"?
                                                <div className="heading--nameOuter bg-dark nrml-width">
                                                    <span className="heading-name">{t(data.CONTROLLABLE)}</span>

                                                    {/* <Popover content={popovercontent} trigger="hover">
                                                        <img src="../assets/images/info.png" width="20px" alt="" />
                                                    </Popover> */}

                                                    <input className="form-check-input mt-0 rounded-0"
                                                        onChange={handleCheckAllCheckboxes}
                                                        type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            :
                                            <div className="heading--nameOuter bg-dark nrml-width">
                                                <span className="heading-name">{t(data.CONTROLLABLE)}</span>
                                            </div>
                                        ))
                                    }
                                </li>
                                {
                                    currentRecords.map(item => (
                                        <Item itemdata={item} key={item.itemId} />
                                    ))
                                }
                            </ul>
                        </div>
                    </section>
              }
            </div>

            {/* <!-- Modify Search Button --> */}
            <div className="modify-search--btnWrapper d-block">
                <div className="container max-container">
                    <div className="d-flex justify-content-between">
                        {/* <!-- <div className="col-9"> --> */}
                        <div className="modify-search--btnWrapper-outer-1st w-auto">
                            <div className="modify-search--btnWrapper--left">
                                {checkFormControlRightOrNo("CONFIRMORDER") == true? <button onClick={handleOrder}>{t('confirmorder')}</button> : '' }
                                {checkFormControlRightOrNo("ADDTOWATCH") == true? <button onClick={handleWatch}>{t('watch')}</button>:''}
                                {checkFormControlRightOrNo("OFFER") == true? <button>{t('offer')}</button>:''}
                                {checkFormControlRightOrNo("MORE") == true?
                                    <span className="dropdown">
                                        <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t('more')}
                                        </button>
                                        <ul className="dropdown-menu p-0 rounded-0">
                                            {checkFormControlRightOrNo("COMPARE") == true? <li><a className="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#diamond-compare" aria-controls="diamond-cart" href="#" onClick={handleCompareItem}>{t('compare')}</a></li> :''}
                                            {checkFormControlRightOrNo("EXPORTEXCEL") == true? <li><a className="dropdown-item" href="#" onClick={handleExportToExcel}>{t('exportexcel')}</a></li> :''}
                                            {checkFormControlRightOrNo("SHARE") == true? <li><a className="dropdown-item" href="#" onClick={handleSentMail}>{t('share')}</a></li> :''}
                                        </ul>
                                    </span>
                                    :
                                    ''
                                }
                            </div>
                            {/* <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                <span className="d-block">Search Stone</span>
                            </button> */}
                        </div>
                        {/* <!-- </div> --> */}
                        {/* <!-- <div className="col-3"> --> */}
                        {/* <div className="modify-search--btnWrapper-outer-2nd">
                            <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                <span className="d-block">Confirm Stone</span>
                            </button>
                        </div> */}
                        <div className="modify-search--btnWrapper-outer-3rd w-auto">
                            <div className="modify-search--btnWrapper--right">
                                 <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                                <select name="" id="" className="form-select d-none">
                                    <option value="" disabled selected>Select Saved Search</option>
                                    <option value="">Test Search</option>
                                </select>
                            </div>
                            {/* <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/icons/foot_menu.svg" width="18px" alt="" /></span>
                                <span className="d-block">More</span>
                            </button> */}
                        </div>
                        {/* <!-- </div> --> */}
                    </div>
                </div>
            </div>
            {/* <!-- End Modify Search Button --> */}


            <StoneInformation
                            ItemType={_itemTypeState}
                            FormName={_formNameState}
                            ItemTypeName={_itemTypeNameState}
                            Category={_categoryState}
                            ItemFormControlRight= {itemFormControlRight}
                            SelectedItem = {selectedItemState}
                            AlternateItems = {alternateItemsState}
                            ItemImageSource = {itemImageSource}
                            ItemVideoSource = {itemVideoSource}
                            ItemCertificateSource = {itemCertificateSource}
                            CompareItems = {compareItemsState}
            />
        </Fragment>
    )
}
export default Cart;