import React, { useEffect,useState,Fragment,useRef } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer'
import useToken from '../components/useToken';
import { BaseUrl, HomeUrl } from '../constants/global';
import axios from "axios";
import { useNavigate,useParams } from 'react-router-dom';
import { ToastContainer,Toast } from 'react-bootstrap';
import commonFunctionClass from '../constants/commonFunctionClass';



function CustomOrder() {
    const navigate = useNavigate();

    const { token, setToken } = useToken();
    const [materials, setMaterials] = useState([]);

    const [itemFormControlRight, setitemFormControlRight] = useState([]);
    
    const [heading, setHeading] = useState('');
    const [remarks, setRemarks] = useState('');
    const [materialId, setMaterialId] = useState(1);
    const [materialName, setmaterialName] = useState("Diamond");

    const _itemType = useRef('1');
    const _formName = useRef('MOSCOW STOCK');
    const _itemTypeName = useRef('MOSCOW STOCK');
    const _category = useState('HOMEPAGE');

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);
    
    const [fileSelected, setFileSelected] = useState();
    const [goldSelectionType, setGoldSelectionType] = useState("BAARS");

    const saveFileSelected= (e) => {
        setFileSelected(e.target.files[0]);
      };

    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight(data);
        }
        else if(_mode.toUpperCase() == "MATERIALS")
        {
            setMaterials(data);
        }
    }

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        CommonParameterFilter('',"FORMCONTROL");
        CommonParameterFilter('',"MATERIALS");
        
    },[]);

    const handlMaterialClick=(e)=>{
        let matid=e.target.dataset.matid;
        let matName=e.target.dataset.matname;
        
         setMaterialId(matid);
         setmaterialName(matName.toUpperCase());
    }
    
    const handlGoldClick=(e)=>{
        let Name=e.target.dataset.name;
        setGoldSelectionType(Name);
    }

    const postData = async (e) => {
        if (e) e.preventDefault();
        
        if (materialId > 0 && heading!='') {

            const formData = new FormData();
            formData.append("file", fileSelected);

            let status = e.target.dataset.value.toUpperCase()=="SAVE"?"PENDING":"APPROVED";

            formData.append("material", materialId);
            formData.append("heading", heading);
            formData.append("remarks", remarks);
            formData.append("goldType", goldSelectionType);
            formData.append("status", status);
            
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            };

            try {
                axios.post(
                    `${BaseUrl}/api/v1/customorder/postcustomorder`,
                    formData,
                    config
                ).then((response) => {
                    setpopupMsgType("light");
                    setpopupHeaderMsg("Order Submited");
                    setpopupDetailedMsg("Order submitted successfully.");
                    setShowPopupMsg(true);
                    setHeading("");
                    setRemarks("");
                    setFileSelected("");

                    document.getElementById('heading').value ="";
                    document.getElementById('remark').value ="";
                    document.getElementById('file').value ="";
                })
                .catch((error) => {
                    console.error(error.response.status);
                    
                    if (error.response.status == 401) {
                        localStorage.clear();
                        navigate('/');
                        window.location.href = HomeUrl;
                    }
                });
            }
            catch (ex) {}
        }
        else {
            setpopupMsgType("warning");
            setpopupHeaderMsg("File upload error");
            setpopupDetailedMsg("Enter all mandatory data. (Metal,Heading of Order and file) ");
            setShowPopupMsg(true);
        }
    }

    return (
        <Fragment>
            <Header />
            <Sidebar />

            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            <section id="place_orderSection">
                <div class="container">
                    <div class="place_orderSection">
                        <div class="place_orderWrapper">
                            <div class="heading text-center">
                                <h2 class="mb-4">Place Order</h2>
                            </div>
                            <ul class="select_orderul list-unstyled justify-content-center d-md-flex  gap-3">
                            {
                                materials.map(material=>(
                                    materialId==material.MATERIALID?
                                        <li class="select_order active m-md-0 m-2"  onClick={handlMaterialClick} data-matid={material.MATERIALID} data-matName={material.MATERIALNAME}  >
                                            <img src={material.MATERIALIMAGEURL} width="20px" alt=""   />
                                            {material.MATERIALNAME} 
                                        </li>
                                    :
                                    <li class="select_order m-md-0 m-2"  onClick={handlMaterialClick} data-matid={material.MATERIALID} data-matName={material.MATERIALNAME} >
                                            <img src={material.MATERIALIMAGEURL} width="20px" alt=""   />
                                            {material.MATERIALNAME} 
                                    </li>
                                ))
                            }
                            </ul>                            
                            <div class="place_orderWrapperForm">
                                <div class="form-group">
                                    <label for="heading">Heading Of Order</label>
                                    <input class="form-control" type="text" name="heading" id="heading" onChange={e => setHeading(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label for="Remark">Order Remark</label>
                                    <textarea class="form-control" name="remark" id="remark" cols="30" rows="6" onChange={e => setRemarks(e.target.value)} ></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="File">Choose File</label>
                                    <input class="form-control" type="file" accept=".pdf, .xls, .docx"  onChange={saveFileSelected} name="file" id="file" />
                                </div>
                                <div class="mt-4 justify-content-center d-flex gap-md-3 flex-wrap">
                                    <button class="hero_btn px-sm-3 px-2" onClick={postData} data-value="SAVE">Save</button>
                                    <button class="hero_btn mx-1 px-sm-3 px-3" onClick={postData} data-value="SAVE_AND_CONFIRM">Save& Confirm</button>
                                    <button class="hero_btn px-sm-3 px-2">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}
export default CustomOrder;