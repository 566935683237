import React, { useEffect, useState, Fragment,Component,useRef} from 'react'
import ReactDOM from 'react-dom';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css'


function VideoView(prop) {
    const images = [
        {          
            original: "../assets/images/Gallery/1.jpg",
            thumbnail: "../assets/images/Gallery/1_Thumb.jpg",
        },
        {          
            original: "../assets/images/Gallery/2.jpg",
            thumbnail: "../assets/images/Gallery/2_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/3.jpg",
          thumbnail: "../assets/images/Gallery/3_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/4.jpg",
          thumbnail: "../assets/images/Gallery/4_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/5.jpg",
          thumbnail: "../assets/images/Gallery/5_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/6.jpg",
          thumbnail: "../assets/images/Gallery/6_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/7.jpg",
          thumbnail: "../assets/images/Gallery/7_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/8.jpg",
          thumbnail: "../assets/images/Gallery/8_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/9.jpg",
          thumbnail: "../assets/images/Gallery/9_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/10.jpg",
          thumbnail: "../assets/images/Gallery/10_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/11.jpg",
          thumbnail: "../assets/images/Gallery/11_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/12.jpg",
          thumbnail: "../assets/images/Gallery/12_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/13.jpg",
          thumbnail: "../assets/images/Gallery/13_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/14.jpg",
          thumbnail: "../assets/images/Gallery/14_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/15.jpg",
          thumbnail: "../assets/images/Gallery/15_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/16.jpg",
          thumbnail: "../assets/images/Gallery/16_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/17.jpg",
          thumbnail: "../assets/images/Gallery/17_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/18.jpg",
          thumbnail: "../assets/images/Gallery/18_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/19.jpg",
          thumbnail: "../assets/images/Gallery/19_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/20.jpg",
          thumbnail: "../assets/images/Gallery/20_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/21.jpg",
          thumbnail: "../assets/images/Gallery/21_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/22.jpg",
          thumbnail: "../assets/images/Gallery/22_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/23.jpg",
          thumbnail: "../assets/images/Gallery/23_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/24.jpg",
          thumbnail: "../assets/images/Gallery/24_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/25.jpg",
          thumbnail: "../assets/images/Gallery/25_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/26.jpg",
          thumbnail: "../assets/images/Gallery/26_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/27.jpg",
          thumbnail: "../assets/images/Gallery/27_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/28.jpg",
          thumbnail: "../assets/images/Gallery/28_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/29.jpg",
          thumbnail: "../assets/images/Gallery/29_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/30.jpg",
          thumbnail: "../assets/images/Gallery/30_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/31.jpg",
          thumbnail: "../assets/images/Gallery/31_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/32.jpg",
          thumbnail: "../assets/images/Gallery/32_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/33.jpg",
          thumbnail: "../assets/images/Gallery/33_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/34.jpg",
          thumbnail: "../assets/images/Gallery/34_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/35.jpg",
          thumbnail: "../assets/images/Gallery/35_Thumb.jpg",
        },
        {
          original: "../assets/images/Gallery/36.jpg",
          thumbnail: "../assets/images/Gallery/36_Thumb.jpg",
        },
      ];
    
    return (
        <div>
            
            <div className="offcanvas offcanvas-end w-50 canfava_f" tabIndex="-1" id="company-gallery" // id="diamond-video"
                aria-labelledby="company-gallery">
                    <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <ImageGallery items={images} />;
            </div>
            
        </div>
    )
}
export default VideoView;