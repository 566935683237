import React, { useEffect, Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useToken from '../components/useToken';
import { ToastContainer, Toast } from 'react-bootstrap';
import { BaseUrl } from '../constants/global';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import  commonFunctionClass from '../constants/commonFunctionClass';

function Register() {
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const { t, i18n } = useTranslation();

    const {token, setToken } = useToken();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [address, setAddress] = useState('');

    const [country, setCountry] = useState('RUSSIA');
    const [state, setState] = useState('MOSCOW OBLAST');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('MOSCOW OBLAST');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [fax, setFax] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [businessType, setBusinessType] = useState('');

    const [rapnet, setRapnet] = useState('');
    const [instagram, setInstagram] = useState('');

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);
    
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [BussinessTypeList, setBussinessTypeList] = useState([]);
    const [CompanyTypeList, setCompanyTypeList] = useState([]);

    const handleRegister = () => {
        navigate('/register');
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleReset = async () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setAddress('');
        setPassword('');
        setConfirmPassword('');
        setMobileNo('');
        setUserName('');

        setCountry('');
        setState('');
        setCity('');
        setZip('');
        setPhone1('');
        setPhone2('');
        setFax('');
        setCompanyName('');
        setCompanyType('');
        setWebsiteUrl('');

        setBusinessType('');
        setRapnet('');
        setInstagram('');

        setpopupMsgType("light");
        setpopupHeaderMsg("Reset Successfully");
        setpopupDetailedMsg("Reset successfully...");
        setShowPopupMsg(true);
    }

    const handleSubmit1 = async (e) => {
        if (e) 
        {
            e.preventDefault();
        }

    }

    const handleSubmit = async () => {
        var btnRegister = document.getElementById("btnRegister");
        btnRegister.disabled = true;
        
        // if (e) 
        // {
        //     e.preventDefault();
        // }

        if (confirmPassword != password) {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Passcode Miss-Match");
            setpopupDetailedMsg("Password and Confirm Password not same.");
            setShowPopupMsg(true);
            btnRegister.disabled = false;
            return;
        }
        
        try 
        { 
            var resData=[{"status":""}];

            var url = `${BaseUrl}/api/v1/users/register`;
            
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            
            setpopupMsgType("warning");
            var headerMsg ='';
            var detailMsg ='';
            
            if(userName==='' || userName.length<5)
            {
                headerMsg = headerMsg+"User Name," +" ";
                detailMsg = detailMsg +"Please enter proper UserName (More then 5 character)," +" ";
            }
            if(password==='' || password.length<5)
            {
                headerMsg = headerMsg+"Password," +" ";
                detailMsg = detailMsg +"Please enter Password (More then 5 character)," +" ";
            }
            if(firstName==='' || firstName.length<=3)
            {
                headerMsg = headerMsg+"First Name," +" ";
                detailMsg = detailMsg +"Please enter FirstName (More then 3 character)," +" ";
            }
            if(lastName==='' || lastName.length<=3)
            {
                headerMsg = headerMsg+"Last Name," +" ";
                detailMsg = detailMsg +"Please enter LastName (More then 3 character)," +" ";
            }
            if(email==='' || email.length<8)
            {
                headerMsg = headerMsg+"Email," +" ";
                detailMsg = detailMsg +"Please enter Email (More then 8 character)," +" ";
            }
            if(mobileNo==='' || mobileNo.length<10)
            {
                headerMsg = headerMsg+"Email," +" ";
                detailMsg = detailMsg +"Please enter Mobile. (10 character)," +" ";
            }
            if(address==='')
            {
                headerMsg = headerMsg+"Address," +" ";
                detailMsg = detailMsg +"Please enter Address.," +" ";
            }
            
            if(country==='')
            {
                headerMsg = headerMsg+"Country," +" ";
                detailMsg = detailMsg +"Please enter Country.," +" ";
            }            
            else if(country.toLowerCase().includes('--'))
            {
                headerMsg = headerMsg+"Country," +" ";
                detailMsg = detailMsg +"Please enter Country.," +" ";
            }

            if(state==='')
            {
                headerMsg = headerMsg+"State," +" ";
                detailMsg = detailMsg +"Please enter State.," +" ";
            }
            else if(state.toLowerCase().includes('--'))
            {
                headerMsg = headerMsg+"State," +" ";
                detailMsg = detailMsg +"Please enter State.," +" ";
            }

            if(city==='')
            {
                headerMsg = headerMsg+"City," +" ";
                detailMsg = detailMsg +"Please enter City.," +" ";
            }
            else if(city.toLowerCase().includes('--'))
            {
                headerMsg = headerMsg+"City," +" ";
                detailMsg = detailMsg +"Please enter City.," +" ";
            }

            if(zip==='')
            {
                headerMsg = headerMsg+"Zip," +" ";
                detailMsg = detailMsg +"Please enter Zip.," +" ";
            }

            if(companyName.length<=5)
            {
                headerMsg = headerMsg+"Company Name," +" ";
                detailMsg = detailMsg +"Please enter Proper Company Name.," +" ";
            }

            if(companyType==='')
            {
                headerMsg = headerMsg+"Company Type," +" ";
                detailMsg = detailMsg +"Please enter Company Type.," +" ";
            }            
            else if(companyType.toLowerCase().includes('--'))
            {
                headerMsg = headerMsg+"Company Type," +" ";
                detailMsg = detailMsg +"Please enter Company Type.," +" ";
            }

            if(businessType==='')
            {
                headerMsg = headerMsg+"Bussiness Type," +" ";
                detailMsg = detailMsg +"Please enter Bussiness Type.," +" ";
            }
            else if(businessType.toLowerCase().includes('--'))
            {
                headerMsg = headerMsg+"Bussiness Type," +" ";
                detailMsg = detailMsg +"Please enter Bussiness Type.," +" ";
            }

            if(headerMsg.length>0)
            {                
                setpopupHeaderMsg(headerMsg);
                setpopupDetailedMsg(detailMsg);
                setShowPopupMsg(true);
                btnRegister.disabled = false;
                return;
            }

            let bodyParameters = {
                userName: userName,
                password: password,
                appRoleId: 3,
                firstName: firstName,
                lastName: lastName,
                email: email,
                address: address,
                mobileNo: mobileNo,
                country: country,
                state: state,
                city: city,
                zip: zip,
                phone1: phone1,
                phone2: phone2,
                fax: fax,
                companyName: companyName,
                companyType: companyType,
                websiteUrl: websiteUrl,
                businessType: businessType,
                rapnet: rapnet,
                instagram: instagram
            };

            var resp = await axios.post(
                url,
                bodyParameters,
                config
            ).then((response) => {                
                resData = response.data;
            }).catch((error) => {

            });

            if (resData.status.toUpperCase() === 'CREATED') {
                setFirstName('');
                setLastName('');
                setEmail('');
                setAddress('');
                setPassword('');
                setConfirmPassword('');
                setMobileNo('');
                setUserName('');

                setCountry('');
                setState('');
                setCity('');
                setZip('');
                setPhone1('');
                setPhone2('');
                setFax('');
                setCompanyName('');
                setCompanyType('');
                setWebsiteUrl('');

                setBusinessType('');
                setRapnet('');
                setInstagram('');

                setpopupMsgType("light");
                setpopupHeaderMsg(resData.header);
                setpopupDetailedMsg(resData.message);
                setShowPopupMsg(true);
            }
            else {
                setpopupMsgType("warning");
                setpopupHeaderMsg(resData.header);
                setpopupDetailedMsg(resData.message);
                setShowPopupMsg(true);
            }
        } 
        catch (err) {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Contact to administrator");
            setpopupDetailedMsg("Something is wrong with your user, Please contact with the administrator.");
            setShowPopupMsg(true);
        }
       
        btnRegister.disabled = false;
    };

    const handleCountry = async (e) =>{
        setCountry(e.target.value);
    }

    const handleState = async (e) =>{
        setState(e.target.value);
    }

    const handleCity = async (e) =>{
        setCity(e.target.value);
    }

    const handleCompanyType = async (e) =>{
        setCompanyType(e.target.value);
    }

    const handleBussinessType = async (e) =>{
        setBusinessType(e.target.value);
    }

    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilterWithoutAuthentication(token,0,'N','REGISTER',_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "COUNTRY")
        {
            setCountryList(data);
        }
        else if(_mode.toUpperCase() == "STATE")
        {
            setStateList(data);
        }
        else if(_mode.toUpperCase() == "CITY")
        {
            setCityList(data);
        }
        else if(_mode.toUpperCase() == "BUSSINESSTYPE")
        {
            setBussinessTypeList(data);
        }
        else if(_mode.toUpperCase() == "COMPANYTYPE")
        {
            setCompanyTypeList(data);
        }
    }

    useEffect(() => {
        CommonParameterFilter('',"COUNTRY");
        CommonParameterFilter('',"BUSSINESSTYPE");
        CommonParameterFilter('',"COMPANYTYPE");
    },[]);

    useEffect(() => {     
        setState("");
        let xtraParam = "<COUNTRYID>"+country+"</COUNTRYID>";
        CommonParameterFilter(xtraParam,"STATE");
    },[country]);

    useEffect(() => {
        setCity("");
        let xtraParam = "<STATEID>"+state+"</STATEID>";
        CommonParameterFilter(xtraParam,"CITY");
    },[state]);


    return (
        <Fragment>
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body>{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            <form onClick={handleSubmit1}>
                {/* <!-- Section Login --> */}
                <section id="verify-userWrapper">
                    <div className="row cus-row g-0">

                        <div className="col-lg-6 col-md-6">
                            <div className="verify_userWrapperLeft">
                                <div className="verify_userLeftOuter">
                                    <img src="../assets/images/logo.png" width="100px" alt="" />
                                    <h3>User Register</h3>
                                    <ul className="list-unstyled mb-0 d-flex gap-4 justify-content-center">
                                        <li><a onClick={handleLogin}>Login</a></li>
                                        <li className="active"><a onClick={handleRegister}>Register</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="verify_userWrapperRight register">
                                <img src="../assets/images/logo.png" width="100px" alt="" />
                                <div className="form-group mt-4">
                                    <h4 className="mb-3 fw-bold">{t('logininformation')}</h4>
                                    <div className="row g-3">
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userFirstName">{t('firstname')} <span className="star">*</span></label>
                                            <input type="text" id="userFirstName" value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userLastName">{t('lastname')} <span className="star">*</span></label>
                                            <input type="text" id="userLastName" value={lastName} onChange={e => setLastName(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userEmail">{t('email')} <span className="star">*</span></label>
                                            <input type="text" id="userEmail" value={email} onChange={e => setEmail(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userName">{t('username')} <span className="star">*</span></label>
                                            <input type="text" id="userName" value={userName} onChange={e => setUserName(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userPassword">{t('password')} <span className="star">*</span></label>
                                            <input type="password" id="userPassword" value={password} onChange={e => setPassword(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userConPass">{t('confirmpassword')} <span className="star">*</span></label>
                                            <input type="password" id="userConPass" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mt-4">
                                    <h4 className="mb-3 fw-bold">{t('contactinformation')}</h4>
                                    <div className="row g-3">
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userAddress">{t('address')} <span className="star">*</span></label>
                                            <input type="text" id="userAddress" value={address} onChange={e => setAddress(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userMobile">{t('mobileno')} <span className="star">*</span></label>
                                            <input type="text" id="userMobile" value={mobileNo} onChange={e => setMobileNo(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userCountry">{t('selectcountry')} <span className="star">*</span></label>
                                            {/* <input type="text" id="userCountry" value={country} onChange={e => setCountry(e.target.value)} className="form-control" /> */}                                            
                                            <select class="form-select form-control" value={country} id="userCountry" onChange={handleCountry}>
                                            {
                                                CountryList.map(item=>(
                                                    item.VALUE.toUpperCase() ==  country?
                                                    <option value={item.VALUE} selected>{item.VALUE}</option>
                                                    :
                                                    <option value={item.VALUE}>{item.VALUE}</option>
                                                ))
                                            }
                                            </select>
                                        </div>
                                        
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userState">{t('selectstate')} <span className="star">*</span></label>
                                            {/* <input type="text" id="userState" value={state} onChange={e => setState(e.target.value)} className="form-control" /> */}                                            
                                            <select class="form-select form-control" value={state} id="userState" onChange={handleState}>
                                            {
                                                StateList.map(item=>(
                                                    item.VALUE.toUpperCase() ==  state?
                                                    <option value={item.VALUE} selected>{item.VALUE}</option>
                                                    :
                                                    <option value={item.VALUE}>{item.VALUE}</option>
                                                ))
                                            }
                                            </select>
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userCity">{t('selectcity')} <span className="star">*</span></label>
                                            {/* <input type="text" id="userCity" value={city} onChange={e => setCity(e.target.value)} className="form-control" /> */}
                                            <select class="form-select form-control" value={city} id="userCity" onChange={handleCity}>
                                            {
                                                CityList.map(item=>(
                                                    item.VALUE.toUpperCase() ==  city?
                                                    <option value={item.VALUE} selected>{item.VALUE}</option>
                                                    :
                                                    <option value={item.VALUE}>{item.VALUE}</option>
                                                ))
                                            }
                                            </select>
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userZip">{t('zipcode')}<span className="star">*</span></label>
                                            <input type="text" id="userZip" value={zip} onChange={e => setZip(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userName">{t('phoneno')} 1</label>
                                            <input type="text" id="userPhone1" value={phone1} onChange={e => setPhone1(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userPass">{t('phoneno')} 2</label>
                                            <input type="text" id="userPhone2" value={phone2} onChange={e => setPhone2(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userFax">{t('faxno')}</label>
                                            <input type="text" id="userFax" value={fax} onChange={e => setFax(e.target.value)} className="form-control" />
                                        </div>

                                    </div>
                                </div>

                                <div className="form-group mt-4">
                                    <h4 className="mb-3 fw-bold">{t('businessinformation')}</h4>
                                    <div className="row g-3">
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userName">{t('companyname')} <span className="star">*</span></label>
                                            <input type="text" id="userName" value={companyName} onChange={e => setCompanyName(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userCompanyType">{t('companytype')} <span className="star">*</span></label>
                                            {/* <input type="text" id="userCompanyType" value={companyType} onChange={e => setCompanyType(e.target.value)} className="form-control" /> */}
                                            <select class="form-select form-control" value={companyType} id="userCompanyType" onChange={handleCompanyType}>
                                            {
                                                CompanyTypeList.map(item=>(
                                                    item.VALUE.toUpperCase() ==  companyType ?
                                                    <option value={item.VALUE} selected>{item.VALUE}</option>
                                                    :
                                                    <option value={item.VALUE}>{item.VALUE}</option>
                                                ))
                                            }
                                            </select>
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userWebSiteURL">{t('websiteurl')} <span className="star"></span></label>
                                            <input type="text" id="userWebSiteURL" value={websiteUrl} onChange={e => setWebsiteUrl(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userPass">{t('selectbusinesstype')}<span className="star">*</span></label>
                                            {/* <input type="text" id="userPass" value={businessType} onChange={e => setBusinessType(e.target.value)} className="form-control" /> */}
                                            <select class="form-select form-control" value={businessType} id="userBusinessType" onChange={handleBussinessType}>
                                            {
                                                BussinessTypeList.map(item=>(
                                                    item.VALUE.toUpperCase() ==  businessType?
                                                    <option value={item.VALUE} selected>{item.VALUE}</option>
                                                    :
                                                    <option value={item.VALUE}>{item.VALUE}</option>
                                                ))
                                            }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mt-4">
                                    <h4 className="mb-3 fw-bold">{t('socialnetworking')}</h4>
                                    <div className="row g-3">
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userRapnet">{t('rapnet')}</label>
                                            <input type="text" id="userRapnet" value={rapnet} onChange={e => setRapnet(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-row col-lg-6 col-md-6">
                                            <label for="userInstagram">{t('instagram')}</label>
                                            <input type="text" id="userInstagram" value={instagram} onChange={e => setInstagram(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-check mb-3 d-flex justify-content-start gap-1 ps-5">
                                            <div>
                                                <input className="form-check-input rounded-0" type="checkbox" value="" id="rememberCheck" />
                                                <label className="form-check-label" for="rememberCheck">{t('readandaccept')}</label>
                                            </div>
                                            <a href=""><small><b> {t('termcondition')}.</b></small></a>
                                        </div>
                                        <div className="form-row mt-3 mb-3 d-flex gap-3 col-lg-8 col-md-8">
                                            <button className="hero_btn w-50" id='btnRegister' data-value='register' onClick={handleSubmit}>{t('register')}</button>
                                            <button className="hero_btn btn_invers w-50" id="btnReset" data-value='reset' onClick={handleReset}>{t('reset')}</button>
                                        </div>
                                        <p>{t('alreadyhaveanaccount')}? <Link to="/login"><b>{t('signin')}</b></Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </section>
                {/* <!-- End Section Login --> */}
            </form>
        </Fragment>
    )
}

export default Register;