import React, { useEffect, useState, Fragment, useRef  } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import { useNavigate } from 'react-router-dom';
import AccNav from '../components/AccNav'
import { ToastContainer, Toast } from 'react-bootstrap';
import Select from 'react-select';
import Pagination from '../components/Pagination';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import  commonFunctionClass from '../constants/commonFunctionClass';
import StoneInformation from '../components/StoneInformation';

function Order() {
    const navigate = useNavigate();
    const { token, setToken } = useToken();
    const { t, i18n } = useTranslation();
    const [userRole, setUserRole] = useState('');

    const _itemType = useRef('1');
    const _formName = useRef('MOSCOW ORDER');
    const _itemTypeName = useRef('MOSCOW STOCK');
    const _category = useRef('HOMEPAGE');
    const [itemFormControlRight, setitemFormControlRight] = useState([]);
    
    const [_itemTypeState,setitemTypeState] = useState('1');
    const [_formNameState,setformNameState] = useState('MOSCOW ORDER');
    const [_itemTypeNameState,setitemTypeNameState] = useState('MOSCOW STOCK');
    const [_categoryState,setcategoryState] = useState('HOMEPAGE');
    const [selectedItemState, setSelectedItemState] = useState([]);
    const [alternateItemsState, setAlternateItemsState] = useState([]);
    const [compareItemsState, setCompareItemsState] = useState([]);

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const [defaultItemCount,setDefaultItemCount] =  useState(0);
    const [giaItemCount,setGiaItemCount] =  useState(0);
    const [exclusiveItemCount,setExclusiveItemCount] =  useState(0);
    
    const itemStatus = useRef('Pending');
    const [itemShapes, setitemShapes] = useState([]);
    const [itemCarats, setitemCarats] = useState([]);
    const [itemColors, setitemColors] = useState([]);
    const [itemClarities, setitemClarities] = useState([]);
    
    const [orders, setOrders] = useState([]);   
    const [items, setitems] = useState([]);
    
    const [orderItemTotal, setOrderItemTotal] = useState(0);
    const [userinfo, setUserInfo] = useState({ selecteditems: [], response: [] });
    
    const [loading, setLoading] = useState(false);
    const [itemImageSource, setItemImageSource] = useState('');
    const [itemVideoSource, setItemVideoSource] = useState('');
    const [itemCertificateSource, setItemCertificateSource] = useState('');
       
    const statusItems = [
        { label: "Pending", value: "Pending"},
        { label: "Rejected", value: "Rejected"},
        { label: "Approved", value: "Approved"},
      ];


      const [currentPage, setCurrentPage] = useState(1);
      const [recordsPerPage] = useState(100);
      const indexOfLastRecord = currentPage * recordsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      const currentRecords = orders.slice(indexOfFirstRecord, indexOfLastRecord);
      const nPages = Math.ceil(orders.length / recordsPerPage);

    
      window.addEventListener('resize', function(event) {
        //this.alert('resize');
        manageHeight();
    }, true)

    function manageHeight() {
        //../assets/images/diamond/d3.png
        const script = document.createElement('script');
        script.src = "../assets/js/list-height.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }       
    }

    const changeItemType = (ItemType) => {    
        let nav = document.querySelectorAll(".itemTypeClass");
        let navName = document.getElementById('nav'+ItemType);
        _itemType.current=ItemType;
        _formName.current='MOSCOW ORDER';
        _itemTypeName.current='MOSCOW STOCK';
    
        setitemTypeState(_itemType.current);
        setformNameState(_formName.current);
        setitemTypeNameState(_itemTypeName.current);

        nav.forEach((element) => element.classList.remove('active'));
        navName.classList.add('active');

        if(ItemType == "2")
        {
            _formName.current='GIA ORDER';
            _itemTypeName.current='GIA STOCK';
            setformNameState(_formName.current);
            setitemTypeNameState(_itemTypeName.current);
        }
        else if(ItemType == "3")
        {
            _formName.current='EXCLUSIVE ORDER';
            _itemTypeName.current='EXCLUSIVE STOCK';
            setformNameState(_formName.current);
            setitemTypeNameState(_itemTypeName.current);
        }

        getData(itemStatus.current);
    }

    const handleExportToExcel=async()=>{
        let checkboxes = document.getElementsByName('chk_item[]');

        const data = await commonFunctionClass().ExportToExcel(checkboxes,items,itemFormControlRight,t);

        setpopupMsgType(data[0].MsgType);
        setpopupHeaderMsg(data[0].HeaderMsg);
        setpopupDetailedMsg(data[0].DetailedMsg);
        setShowPopupMsg(data[0].PopupMsg);
    }
    
    const handleCompareItem = async () => {
        setCompareItemsState([]);
        let checkboxes = document.getElementsByName('chk_item[]');
        let checkboxvals="";
        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }
        
        if(indx<=1)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Warning: Select item");
            setpopupDetailedMsg("Please select atleast 2 item");
            setShowPopupMsg(true);    
            return;
        }

        const data = await commonFunctionClass().getStoneInfo(token,navigate,checkboxvals,_itemType.current);
        setCompareItemsState(data);
    }

    const handleSentMail = async () => {
        // setLoading(true);
        let checkboxes = document.getElementsByName('chk_item[]');
        let checkboxvals="";
        let indx = 0;

        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(indx <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);
            return;
        }

        const data = await commonFunctionClass().sentMail(token,navigate,checkboxvals,_itemType.current,_formName.current,"");
        // setLoading(false);
        setpopupMsgType(data[0].MsgType);
        setpopupHeaderMsg(data[0].HeaderMsg);
        setpopupDetailedMsg(data[0].DetailedMsg);
        setShowPopupMsg(data[0].PopupMsg);
    }

    const getItemCountData = async (Mode) => {
        const dataCart = await commonFunctionClass().getItemCount(token,navigate,Mode);        
        const dataCount = dataCart[0];              
        setDefaultItemCount(dataCount.ITEMTYPE1);
        setGiaItemCount(dataCount.ITEMTYPE2);
        setExclusiveItemCount(dataCount.ITEMTYPE3);
    }

    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight(data);
        }        
    }

    async  function getData(status)
    {
        CommonParameterFilter('',"FORMCONTROL");
        
        let _mode="";
        let url ="";
        let xtraParam=`<FORMNAME>${_formName.current}</FORMNAME>`;

        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.get(`${BaseUrl}/api/v1/userinfo/currentuser`,config).then((response) => {  
            if(response.status==200){
                setUserRole(response.data.data.role);
            }   

        });
        
        url = `${BaseUrl}/api/v1/items/GetOrderItems/?ItemType=${_itemType.current}&xtraParam=${xtraParam}&status=${status}`        
        await axios.get(url,config).then((response) => {            
            setLoading(false);
            let data1 = JSON.parse(response.data.data);
            setOrders(data1);
            setOrderItemTotal(data1.length);
            getItemCountData("ORDER");
        }).catch((error) => {
            console.error(error.response.status);
            setLoading(false);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');
                window.location.href=HomeUrl;    
            }
            
         });
         manageHeight();
    }

    useEffect(() => {
        getData(itemStatus.current);

    }, []);

   

    useEffect(() => {        
        manageHeight();
    }, []);

    const handleStatusChange = (e) => {
        let status=document.getElementById('status').value;
        itemStatus.current = status;
        
        getData(itemStatus.current);
    }

    const handleItemImage = (e) => {
        let itemId = e.target.dataset.itemid;
        let item_image_source = e.target.dataset.itemimagesource;
        setItemImageSource(item_image_source);
    }


    const handleItemVideo = (e) => {
        let itemId = e.target.dataset.itemid;
        let item_video_source = e.target.dataset.itemvideosource;
        setItemVideoSource(item_video_source);
    }

    const handleItemCertificate = (e) => {
        let itemId = e.target.dataset.itemid;
        let item_certificate_source = e.target.dataset.itemcertificatesource;
        setItemCertificateSource(item_certificate_source);
    }

    function checkFormControlRightOrNo(ColumnName)
    {
        return itemFormControlRight.some(data => (
            data.CONTROLNAME.toUpperCase()==ColumnName.toUpperCase()
        ))
    }

    const handleItemCart = (e) => {
        let itemId = e.target.dataset.itemid;        
        let itemInfo = items.filter((item) => item.ITEMID == itemId)[0];        
        getSelectedItemData(itemId,itemInfo);
    }
    
    const getSelectedItemData = async (itemId,itemInfo) => {
        setSelectedItemState(itemInfo);
        const data = await commonFunctionClass().getAlterNativeItem(token,navigate,itemId,_itemType.current);        
        setAlternateItemsState(data);
    }

    const Item = (props) => {        
        let itemJsonData = props.itemdata;

        let _parcelstatus = '';
        if (itemStatus.current.toUpperCase() == 'REJECTED') {
            _parcelstatus = 'R';
        }
        else if (itemJsonData.PARCELSTATUS == 'STOCK') {
            _parcelstatus = 'A';
        }
        else if (itemJsonData.PARCELSTATUS == 'HOLD') {
            _parcelstatus = 'H';
        }
        else if (itemJsonData.PARCELSTATUS == 'INVOICE') {
            _parcelstatus = 'C';
        }

        return (
            <li className="d-flex search-result--tableRow col-data--rowWrapper" key={itemJsonData.ITEMID}>
                {
                    itemFormControlRight.map(data => (
                        <div>
                        {
                            data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "MAINGRID"?
                            ''
                        :
                        data.CONTROLLABLE.toUpperCase()=="STATUS"?
                        <div className="data--nameOuter nrml-width" data-header="STATUS">
                            <div className="ps-3 d-flex justify-content-between">
                                {
                                        _parcelstatus == 'H' || _parcelstatus == 'R'  ? <span className="data-name red">{_parcelstatus}</span>
                                        :
                                        <span className="data-name green">{_parcelstatus}</span>
                                }
                            </div>
                        </div>
                        :
                        data.CONTROLLABLE.toUpperCase()=="DETAILS"?
                        <div className="nrml-width data--nameOuter" data-header="DETAILS">
                            <ul className="list-unstyled mb-0 d-flex justify-content-between">
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-img" aria-controls="item-img">
                                        <img width="18px" onClick={handleItemImage} data-itemid={itemJsonData.ITEMID} data-itemimagesource={itemJsonData.IMAGE} src="../assets/images/icons/image-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-video" aria-controls="diamond-video">
                                        <img width="20px" onClick={handleItemVideo} data-itemid={itemJsonData.ITEMID} data-itemvideosource={itemJsonData.V360VIDEO} src="../assets/images/icons/video-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-certificate" aria-controls="diamond-certificate">
                                        <img width="16px" onClick={handleItemCertificate} data-itemid={itemJsonData.ITEMID} data-itemcertificatesource={itemJsonData.CERTLINK} src="../assets/images/icons/certificate-icon.svg" alt="" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        :                        
                        <div className="data--nameOuter nrml-width" data-header={t(data.CONTROLLABLE)}>                           
                            <span className="data-name">{itemJsonData[data.CONTROLNAME]}</span>
                        </div>
                        }
                        </div>
                    ))
                }
                
                <button className="view-all--reasult">View All</button>
            </li>
        )
    }
    
    const AlterNativeItems = (props) => {
        let itemJsonData = props.itemdata;
        
        return (
            <div>
                <div className="prod_heading prod-max-width">
                    <img src={itemJsonData.IMAGE} className="img-fluid prod_img" alt=""/>
                </div>

                {
                    itemFormControlRight.map(data => (
                        data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "ALTERNATEGRID"?
                            ''
                        :
                            <div className="prod_heading prod-max-width">
                                <span className="prod_text">{itemJsonData[data.CONTROLNAME]}</span>
                            </div>
                    
                    ))
                }
                <div className="prod_heading prod-max-width">
                    <span className="prod_text"><a>VIEW DETAIL</a></span>
                </div>
            </div>
        )
    };
        
    const OrderItem = (props) => {
        let itemData = props.itemdata.JSONDATA;
        return itemData.map(item =>
            <Item itemdata={item} key={item.itemId} />
        )
    };
    
    const MainOrderList = (props) => {
        let itemJsonData = props.itemdata;
        return (
            <div>
                <span>&nbsp;
                        {itemJsonData.ORDERDATEFORMATTED}&nbsp;|&nbsp;
                        {itemJsonData.ORDERNO}&nbsp;|&nbsp;
                        Pcs:&nbsp;{itemJsonData.ORDERITEMSUMQUANTITY}&nbsp;|&nbsp;
                        Toatl Cts :&nbsp;{itemJsonData.ORDERITEMSUMCARATFORMATTED}&nbsp;|&nbsp;
                        Total Amount :&nbsp;{itemJsonData.ORDERITEMSUMAMOUNTFORMATTED}
                </span>
            </div>
        )
    };

    const popovercontent = (
        <div class="statusWrapper">
            <div class="row g-2">
                <div class="col-sm-4 col-6">
                    <div class="statusInner avl">
                        <a> A-Available</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner memo">
                        <a>M-Memo</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner hold">
                        <a >H-Hold</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner upcmg">
                        <a>U-Upcoming</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner confirm">
                        <a>C-Confirm</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner exclusive">parcelstatus
                        <a>E-Exclusive</a>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <Header />
            <Sidebar />
           
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* <!-- Diamond Search Filter --> */}

            <section id="search-stone" className="diamond-search--filter">
                <div className="search-stone">                   
                  
                <AccNav/>
                   
                </div>
            </section>

            {/* <!-- End Diamond Search Filter --> */}

            {/* <!-- Diamond List Name --> */}
            <section id="id-diamond--name" className="diamond-search--filter">
                <div className="search-stone">
                    <div className='container max-container'>                        
                        <ul className="nav nav-pills cartTab gap-3">
                            <li className="nav-item">
                                <button className="nav-link itemTypeClass active" id="nav1" name='nav1' aria-current="page" onClick={() => changeItemType("1")}>{t('Default')}&nbsp;({defaultItemCount})</button>
                            </li>
                            <li className="nav-item" >          
                                <button className="nav-link itemTypeClass" id="nav2" name='nav2' onClick={() => changeItemType("2")} >{t('GIA')}&nbsp;({giaItemCount})</button>
                            </li>
                            <li className="nav-item" >          
                                <button className="nav-link itemTypeClass" id="nav3" name='nav3' onClick={() => changeItemType("3")}>{t('Exclusive')}&nbsp;({exclusiveItemCount})</button>
                            </li>
                            <li className="nav-item">
                                <select class="form-select form-control customeButtonRightSide" style={{ width: 250 ,marginRight: 10}} id="status" onChange={handleStatusChange}>
                                    <option value='Pending'>Pending</option>
                                    <option value='Rejected'>Rejected</option>
                                    <option value='Approved'>Approved</option>                                
                                </select>
                            </li>
                        </ul>                        
                    </div>
                </div>
            </section>
            {/* <!-- End Diamond List Name --> */}

            <div id="divData">
             {

                loading?
                <div className='loader'>                      
                    <img src='../../assets/images/insideLoader.gif'></img>                        
                </div>
                 :
                <section id="serach-result--outerWrapper">
                    <div className="search-result--outerWrapper">                   

                        <ul className="list-unstyled mb-0 search-result--tableWrapper">

                            
                            <li className="d-flex text-white search-result--tableRow">
                                {
                                    itemFormControlRight.map(data => (                                                
                                        data.ISCONTROL == true?
                                        ''
                                        :
                                        data.PARENTCONTROLNAME.toUpperCase() != "MAINGRID"?
                                            ''
                                        :
                                        data.CONTROLLABLE.toUpperCase()=="STATUS"?                                                
                                            <div className="heading--nameOuter bg-dark nrml-width">
                                                <span className="heading-name">{t('status')}</span>
                                            </div>
                                        :
                                        <div className="heading--nameOuter bg-dark nrml-width">
                                            <span className="heading-name">{t(data.CONTROLLABLE)}</span>
                                        </div>
                                    ))
                                }
                            </li>

                            
                            {currentRecords.map(item  => (
                                <div class="position-relative dt_wrapper">
                                    <div class="bg-dark text-white">
                                        <MainOrderList itemdata={item} key={item.itemid} />
                                    </div>
                                    <OrderItem itemdata={item} key={item.itemid} />
                                </div>
                            ))
                            }

                        </ul>
                    </div>
                </section>

             }
           </div>

            {/* <!-- Modify Search Button --> */}
            <div className="modify-search--btnWrapper d-block">
                <div className="container max-container">
                    <div className="d-flex justify-content-between">
                        {/* <!-- <div className="col-9"> --> */}
                        <div className="modify-search--btnWrapper-outer-1st">
                            <div className="modify-search--btnWrapper--left">
                                {/* {checkFormControlRightOrNo("OFFER") == true? <button>{t('offer')}</button>:''}
                                {checkFormControlRightOrNo("MORE") == true? 
                                    <span className="dropdown">
                                        <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t('more')}
                                        </button>
                                        <ul className="dropdown-menu p-0 rounded-0">
                                            {checkFormControlRightOrNo("COMPARE") == true? <li><a className="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#diamond-compare" aria-controls="diamond-cart" href="#" onClick={handleCompareItem}>{t('compare')}</a></li> :''}                                            
                                            {checkFormControlRightOrNo("EXPORTEXCEL") == true? <li><a className="dropdown-item" href="#" onClick={handleExportToExcel}>{t('exportexcel')}</a></li> :''}
                                            {checkFormControlRightOrNo("SHARE") == true? <li><a className="dropdown-item" href="#" onClick={handleSentMail}>{t('share')}</a></li> :''}
                                        </ul>
                                    </span>
                                    :
                                    ''
                                } */}
                            </div>
                            {/* <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                <span className="d-block">Search Stone</span>
                            </button> */}
                        </div>
                        {/* <!-- </div> --> */}
                        {/* <!-- <div className="col-3"> --> */}
                        {/* <div className="modify-search--btnWrapper-outer-2nd">
                            <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                <span className="d-block">Confirm Stone</span>
                            </button>
                        </div> */}
                        <div className="modify-search--btnWrapper-outer-3rd">
                            <div className="modify-search--btnWrapper--right">                               
                               <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />

                                <select name="" id="" className="form-select d-none">
                                    <option value="" disabled selected>Select Saved Search</option>
                                    <option value="">Test Search</option>
                                </select>
                            </div>
                            {/* <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/icons/foot_menu.svg" width="18px" alt="" /></span>
                                <span className="d-block">More</span>
                            </button> */}
                        </div>
                        {/* <!-- </div> --> */}
                    </div>
                </div>
            </div>
            {/* <!-- End Modify Search Button --> */}

            <StoneInformation 
                            ItemType={_itemTypeState} 
                            FormName={_formNameState} 
                            ItemTypeName={_itemTypeNameState} 
                            Category={_categoryState} 
                            ItemFormControlRight= {itemFormControlRight}
                            SelectedItem = {selectedItemState}
                            AlternateItems = {alternateItemsState}
                            ItemImageSource = {itemImageSource}
                            ItemVideoSource = {itemVideoSource}
                            ItemCertificateSource = {itemCertificateSource}
                            CompareItems = {compareItemsState}
            />
        </Fragment>
    )
}
export default Order;