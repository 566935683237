import React, { Fragment,useState } from 'react'
import { 
  Link,useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
function AccNav() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      <div className='container max-container'>
        <ul className="nav nav-pills cartTab gap-3">
          <li className="nav-item" >
            <Link className={location.pathname=="/cart"?"nav-link active":"nav-link"} id="nav_cart"  aria-current="page" to="/cart">{t('mycart')}</Link>
          </li>
          <li className="nav-item" >          
            <Link className={location.pathname=="/order"?"nav-link active":"nav-link"}  id="nav_order"   to="/order"  >{t('myorder')}</Link>
          </li>
          <li className="nav-item" >          
            <Link className={location.pathname=="/watch"?"nav-link active":"nav-link"}  id="nav_watch"   to="/watch"  >{t('mywatch')}</Link>
          </li>                    
          <li className="nav-item" >          
            <Link className={location.pathname=="/mysavedsearch"?"nav-link active":"nav-link"}  id="nav_savedsearch"   to="/mysavedsearch"  >{t('mysavedsearch')}</Link>
          </li>                    
          <li className="nav-item" >          
            <Link className={location.pathname=="/customorderdetails"?"nav-link active":"nav-link"}  id="nav_customorderdetails"   to="/customorderdetails"  >{t('quoteorder')}</Link>
          </li>
          {/* <li className="nav-item" >          
            <Link className={location.pathname=="/giaorder"?"nav-link active":"nav-link"}  id="nav_giaorder"   to="/giaorder"  >{t('mygiaorder')}</Link>
          </li>
          <li className="nav-item" >          
            <Link className={location.pathname=="/exclusiveorder"?"nav-link active":"nav-link"}  id="nav_exclusiveorder"   to="/exclusiveorder"  >{t('myexclusiveorder')}</Link>
          </li> */}
        
        </ul>
      </div>
    </Fragment>
  )
}
export default AccNav;