import React, { useEffect, useState, Fragment, useRef } from 'react'
import useToken from '../components/useToken';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { BaseUrl } from '../constants/global'
import Languageoption from './language-dropdown'
import CommonComponent from '../constants/commonComponent';
import commonFunctionClass from '../constants/commonFunctionClass';

import {
    Link
} from "react-router-dom";

import { useTranslation } from 'react-i18next';

function Header() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const dataRef1 = useRef([]);
    const [_formName, setFormName] = useState('HOMEPAGE');
    const { token, setToken } = useToken();
    const [userInfo, setUserInfo] = useState([]);

    const hcartItemCount = useRef(0);

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(`${BaseUrl}/api/v1/userinfo/currentuser`, config).then((response) => {
            if (response.status == 200) {
                setUserInfo(response.data.data);
            }
        })
            .catch((error) => {
                console.error(error.response.status);
                //alert(error.response.status);
                if (error.response.status == 401) {
                    localStorage.clear();
                    window.location.reload();
                }

            })

    }, []);

    useEffect(() => {
        const getItemCountData = async () => {
            const dataCart = await commonFunctionClass().getItemCount(token, navigate, "CART");
        }
        getItemCountData();

    }, []);

    function logOut() {
        localStorage.clear()
        navigate('/');
    }

    function test() {
    }

    return (
        <Fragment>
            <header className="bg-dark">
                {/* <button name="btnCountry" className="toggle_country"></button> */}
                <div className="container max-container">
                    <div id="select-country">
                        <div className="select-country">
                            <div className="dropdown ms-auto">
                                <Languageoption />
                            </div>
                        </div>
                    </div>

                    <nav className="navbar navbar-expand-lg justify-content-between">
                        <div className="navbar-brand d-flex align-items-center gap-md-3">
                            <div className="navbar-toggle-outer" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                title="Menu">
                                <a className="side-menu--toggler--anch" data-bs-toggle="offcanvas" href="#mobileMenu" aria-controls="mobileMenuLabel">
                                    <img src="../../assets/images/menu-icon.svg" width="28px" alt="" />
                                </a>
                            </div>
                            <Link to="/">
                                <img src="../../assets/images/logo.png" width="100px" alt="Imperial Diamond Logo" />
                            </Link>
                        </div>
                        <ul className="navbar-nav navmenu gap-4">
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/">{t('home')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white batch" to="/giaitemsearch/-1">
                                    {t('gia')}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white batch" to="/exclusiveitemsearch/-1">
                                    {t('exclusive')}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/myaccount/summary">
                                    {t('myaccount')}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/customorder">
                                    {t('quoteorder')}
                                </Link>
                            </li>
                        </ul>

                        <ul className="navbar-nav align-items-center side-menu--toggler">
                            <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="Search"><a
                                className="side-menu--toggler--anch" href="" data-bs-toggle="offcanvas"
                                data-bs-target="#sideMenuToggle" aria-controls="sideMenuToggle"><img
                                    src="../../assets/images/search-icon.svg" width="32px" alt="" /></a>
                            </li>

                            <li id="licart" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cart"
                                className="show-batch" data-batch={hcartItemCount.current}>
                                <Link className="side-menu--toggler--anch" to="/cart">
                                    <img src="../../assets/images/cart-icon.svg" width="32px" alt="" />
                                </Link>
                            </li>

                            <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notification" className="show-batch"
                                data-batch="2"><a className="side-menu--toggler--anch" href="" data-bs-toggle="offcanvas"
                                    data-bs-target="#sideMenuToggle" aria-controls="sideMenuToggle"><img
                                        src="../../assets/images/notification-icon.svg" width="28px" alt="" /></a>
                            </li>

                            <li className="d-flex gap-2 align-items-center" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                title="Profile">
                                <a className="side-menu--toggler--anch" href="" data-bs-toggle="offcanvas"
                                    data-bs-target="#sideMenuToggle" aria-controls="sideMenuToggle"><img
                                        src="../../assets/images/profile-icon.svg" width="28px" alt="" />
                                </a>
                                <a href="" className="user_name" data-bs-toggle="offcanvas" data-bs-target="#sideMenuToggle"
                                    aria-controls="sideMenuToggle" >{userInfo.givenName}</a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="bottom" title="Log Out">
                                <a className="side-menu--toggler--anch" href="" onClick={() => logOut()}>
                                    <img src="../../assets/images/logout.png" width="28px" alt="" />
                                </a>
                            </li>

                        </ul>
                    </nav>

                    <div class="offcanvas offcanvas-start" tabindex="-1" id="mobileMenu" aria-labelledby="offcanvasExampleLabel">
                        <nav className="navbar navbar-expand-lg d-block p-3">
                            <div class="offcanvas-header p-0 align-items-start">
                            <div className='widthimg object-fit-cover'>
                                <img src="../../assets/images/logo.png" width="100px" alt="Imperial Diamond Logo" />
                            </div>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <ul className="navbar-nav d-block gap-4 pt-4">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">{t('home')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link batch" to="/giaitemsearch/-1">
                                        {t('gia')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link batch" to="/exclusiveitemsearch/-1">
                                        {t('exclusive')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/myaccount/summary">
                                        {t('myaccount')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/customorder">
                                        {t('quoteorder')}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>



        </Fragment>
    )
}
export default Header;