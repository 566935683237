import React, { useEffect, useState, Fragment, useRef  } from 'react'
import ParentChildComponent from './ParentChildComponent';
import ParentChildFunction from './ParentChildFunction';

function ParentFunction() {   
    const color = useRef("red");
    const [color2, setcolor2] = useState("blue");
    const handleClick = () => {
        color.current="Green";
        setcolor2("yellow");
    }

    return (
        <div>
            <h1>This is a parent component</h1>
            <ul>
                <li>
                    <ParentChildFunction color={color2}/>
                    <button onClick={handleClick}>My button</button>
                </li>
            </ul>
        </div>        
    )
}
export default ParentFunction;