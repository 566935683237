
import React, { useEffect, useState, Fragment } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl } from '../constants/global'
import MyAccNav from '../components/MyAccNav'

function MyAccountDetail() {
    const { token, setToken } = useToken();

    const [firstName, setFirstName] = useState("");  
    const [lastName, setLastName] = useState("");  
    const [mobileNo, setMobileNo] = useState("");  
    const [email, setEmail] = useState("");  

    const [companyName, setCompanyNamel] = useState("");  
    const [companyType, setCompanyType] = useState("");  
    const [websiteUrl, setWebsiteUrl] = useState("");  
    const [address, setAddress] = useState(""); 
        
    async  function getPersonalInfoData()
    {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        await axios.get(`${BaseUrl}/api/v1/myaccount/getpersonalinfo`,config).then((response) => {
          
            var data= JSON.parse(response.data.data);
            
            setFirstName(data[0].FIRSTNAME);
            setLastName(data[0].LASTNAME);
            setMobileNo(data[0].MOBILENO);
            setEmail(data[0].EMAIL);

            setCompanyNamel(data[0].COMPANYNAME);
            setCompanyType(data[0].COMPANYTYPE);
            setWebsiteUrl(data[0].WEBSITEURL);
            setAddress(data[0].ADDRESS);
        });
    }

    useEffect(() => {
        getPersonalInfoData();
    }, []);

    return (
        <Fragment>
            <Header />
            <Sidebar />
            <section id="profile-pageSection">
                <MyAccNav />
                <div className="profile-pageSection">
                    <div className="max-container container">
                        { /* <!-- details --> */}
                        <div className="row">
                            <div className="user-profile">
                                <div className="user-profile-wrapper">
                                    <div className="profile-banner">
                                        <img src="../assets/images/prop-banner.png" className="img-fluid" alt="" />
                                        <div className="edit-profile-banner" data-bs-toggle="modal" data-bs-target="#editbanner">
                                            <img src="../assets/images/icons/edit-icon-white.png" alt="" />
                                        </div>
                                        <div className="profile-image">
                                            <img src="../assets/images/profile.jpg" className="img-fluid" alt="" />
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-profile-wrapper">
                                    <div className="user-details">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div
                                                    className="user-details-heading d-flex justify-content-between align-items-center">
                                                    <h4>Personal Information</h4>
                                                    <button data-bs-toggle="offcanvas" data-bs-target="#pi-slide" aria-controls="pi-slide"></button>
                                                </div>                                                
                                                <div className="row g-1 g-lg-3">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{firstName} {lastName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{mobileNo}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="user-details-heading d-flex justify-content-between align-items-center">
                                                    <h4>Company Information</h4>
                                                    <button data-bs-toggle="offcanvas" data-bs-target="#pi-slide" aria-controls="pi-slide"></button>
                                                </div>
                                                <div className="row g-1 g-lg-3">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{companyName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{companyType}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{websiteUrl}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white px-2 py-3">
                                                            <img src="../assets/images/icons/certificate-icon.svg" alt="" />
                                                            <span>{address}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="profile-details bg-white p-2">
                                                            <img src="../assets/images/still.jpg" className="rounded-2" alt="" />
                                                            <span>Personal ID</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default MyAccountDetail;