import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, Toast } from 'react-bootstrap';
import { BaseUrl } from '../constants/global';
import { useTranslation } from 'react-i18next';
import axios from "axios";

function GuestLogin() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');

    const [companyName, setCompanyName] = useState('');

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const handleSubmit = async (e) => {
        var btnRegister = document.getElementById("btnRegister");
        btnRegister.disabled = true;
        
        if (e)
        {
            e.preventDefault();
        }
  
        if (confirmPassword.toString() != password.toString()) {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Passcode Miss-Match");
            setpopupDetailedMsg("Password and Confirm password not same.");
            setShowPopupMsg(true);
            btnRegister.disabled = false;
            return;
        }

        try 
        {
            var resData=[{"status":""}];

            var url = `${BaseUrl}/api/v1/users/Register`;
            
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            
            setpopupMsgType("warning");
            var headerMsg ='';
            var detailMsg ='';

            if(userName==='' || userName.length<5)
            {
                headerMsg = headerMsg+"User Name," +" ";
                detailMsg = detailMsg +"Please enter proper UserName (More then 5 character)," +" ";
            }
            if(password==='' || password.length<5)
            {
                headerMsg = headerMsg+"Password," +" ";
                detailMsg = detailMsg +"Please enter Password (More then 5 character)," +" ";
            }
            if(firstName==='' || firstName.length<=3)
            {
                headerMsg = headerMsg+"First Name," +" ";
                detailMsg = detailMsg +"Please enter FirstName (More then 3 character)," +" ";
            }
            if(lastName==='' || lastName.length<=3)
            {
                headerMsg = headerMsg+"Last Name," +" ";
                detailMsg = detailMsg +"Please enter LastName (More then 3 character)," +" ";
            }
            if(email==='' || email.length<8)
            {
                headerMsg = headerMsg+"Email," +" ";
                detailMsg = detailMsg +"Please enter Email (More then 8 character)," +" ";
            }
            if(mobileNo==='' || mobileNo.length<10)
            {
                headerMsg = headerMsg+"Email," +" ";
                detailMsg = detailMsg +"Please enter Mobile. (10 character)," +" ";
            }
            
            if(headerMsg.length>0)
            {                
                setpopupHeaderMsg(headerMsg);
                setpopupDetailedMsg(detailMsg);
                setShowPopupMsg(true);
                btnRegister.disabled = false;
                return;
            }

            let bodyParameters = {
                userName: userName,
                password: password,
                appRoleId: 4,
                firstName: firstName,
                lastName: lastName,
                email: email,
                address: '',
                mobileNo: mobileNo,
                country: '',
                state: '',
                city: '',
                zip: '',
                phone1: '',
                phone2: '',
                fax: '',
                companyName: '',
                companyType: '',
                websiteUrl: '',
                businessType: '',
                rapnet:'',
                instagram: '',
            };
            
            var resp = await axios.post(
                url,
                bodyParameters,
                config
            ).then((response) => {                
                resData = response.data;
            }).catch((error) => {

            });

            if (resData.status.toUpperCase() === 'CREATED') {
                setFirstName('');
                setLastName('');
                setEmail('');
                setUserName('');
                setPassword('');
                setConfirmPassword('');
                setMobileNo('');    
                setCompanyName('');                
                setpopupMsgType("light");
                setpopupHeaderMsg(resData.header);
                setpopupDetailedMsg(resData.message);
                setShowPopupMsg(true);
            }
            else {
                setpopupMsgType("warning");
                setpopupHeaderMsg(resData.header);
                setpopupDetailedMsg(resData.message);
                setShowPopupMsg(true);
            }
        }
        catch (err) 
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Contact to administrator");
            setpopupDetailedMsg("Something is wrong with your user, Please contact with the administrator.");
            setShowPopupMsg(true);
        }
       
        btnRegister.disabled = false;
    };

    const handleRegister = () => {
        navigate('/register');
    };

    const handleGuestLogin = () => {
        navigate('/guestlogin');
    };

    const handleLogin = () => {
        navigate('/login');
    };


    return (
        <Fragment>
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body>{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* <!-- Section Login --> */}
            <section id="verify-userWrapper">
                <div class="row cus-row g-0">
                    <div class="col-lg-6 col-md-6">
                        <div class="verify_userWrapperLeft">
                            <div class="verify_userLeftOuter">
                                <img src="../assets//images/logo.png" width="70px" alt="" />
                                <h3>Guest Login</h3>
                                <ul class="list-unstyled mb-0 d-flex gap-2 justify-content-center flex-wrap">
                                    <li class="active"><a onClick={handleGuestLogin}>Guest Login</a></li>
                                    <li><a onClick={handleLogin} >Login</a></li>
                                    <li><a onClick={handleRegister} >Register</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="verify_userWrapperRight register">
                            <img src="../assets//images/logo.png" width="70px" alt="" />
                            <div class="form-group mt-4">
                                <div class="row g-3">

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userFirstName">{t('firstname')}e <span className="star">*</span></label>
                                        <input type="text" id="userFirstName" value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userLastName">{t('lastname')} <span className="star">*</span></label>
                                        <input type="text" id="userLastName" value={lastName} onChange={e => setLastName(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userName">{t('username')} <span className="star">*</span></label>
                                        <input type="text" id="userName" value={userName} onChange={e => setUserName(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userPassword">{t('password')} <span className="star">*</span></label>
                                        <input type="password" id="userPassword" value={password} onChange={e => setPassword(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userConPass">{t('confirmpassword')} <span className="star">*</span></label>
                                        <input type="password" id="userConPass" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userEmail">{t('email')} <span className="star">*</span></label>
                                        <input type="text" id="userEmail" value={email} onChange={e => setEmail(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userMobile">{t('mobileno')} <span className="star">*</span></label>
                                        <input type="text" id="userMobile" value={mobileNo} onChange={e => setMobileNo(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="form-row col-lg-6 col-md-6">
                                        <label for="userName">{t('companyname')} <span className="star">*</span></label>
                                        <input type="text" id="userName" value={companyName} onChange={e => setCompanyName(e.target.value)} className="form-control" />
                                    </div>

                                    <div class="form-check mb-3 d-flex justify-content-start gap-1 ps-5">
                                        <div>
                                            <input class="form-check-input rounded-0" type="checkbox" value="" id="rememberCheck" />
                                            <label class="form-check-label" for="rememberCheck">{t('readandaccept')} </label>
                                        </div>
                                        <a href=""><small><b> {t('termcondition')}.</b></small></a>
                                    </div>

                                    <div class="form-row mt-3 mb-3 d-flex gap-3 col-lg-8 col-md-8">
                                        <button onClick={handleSubmit} id='btnRegister' class="hero_btn w-50">{t('register')}</button>
                                    </div>

                                    <p>{t('alreadyhaveanaccount')}? <Link to="/login"><b>{t('signin')}</b></Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Section Login --> */}

        </Fragment>
    )
}
export default GuestLogin;