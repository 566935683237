
import React, { useEffect, useState, Fragment } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl } from '../constants/global'
import MyAccNav from '../components/MyAccNav'
import { ToastContainer,Toast } from 'react-bootstrap';

function ChangePassword() {
    const { token, setToken } = useToken();

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    const handleSubmit = async e => {

        if(e) e.preventDefault();
       
        if(oldPassword!='' && password!='')
        {
            if(confirmpassword!=password){
            }

            let bodyParameters = {              
                oldPassword: oldPassword,
                password: password,
            };
    
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            axios.post(
                `${BaseUrl}/api/v1/myaccount/changepassword`,
                bodyParameters,
                config
            ).then((response) => {
                setpopupMsgType("light");
                if (response.data.status.toUpperCase() == 'ERROR') {
                    setpopupMsgType("warning");                    
                }
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
                setShowPopupMsg(true);
            })
            .catch((error) => {
                console.error(error)
            });
        }        
        else{
            
            setpopupMsgType("warning");
            setpopupHeaderMsg("Change Password");
            setpopupDetailedMsg("Enter username & password.");
            setShowPopupMsg(true);
          //alert('Enter userid & password');
        }
    }

    return (
        <Fragment>
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Header />
            <Sidebar />
            <section id="profile-pageSection">
                <MyAccNav />

                <div className="profile-pageSection mt-2 mb-4">
                    <div className="max-container container">
                        {/* <!-- Summary --> */}
                        <div className="row">
                            <div className="change-passwordWrapper mt-2 mb-4">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-row mb-2">
                                        <label for="currentPass">Current Password</label>
                                        <input type="password" name="curPass" id="currentPass" className="form-control"
                                            placeholder="Current Password"   onChange={e => setOldPassword(e.target.value)}/>
                                    </div>
                                    <div className="form-row mb-2">
                                        <label for="npass">New Password</label>
                                        <input type="password" name="curPass" id="npass" className="form-control"
                                            placeholder="New Password" onChange={e => setPassword(e.target.value)}  />
                                    </div>
                                    <div className="form-row">
                                        <label for="conPass">Confirm Password</label>
                                        <input type="password" name="curPass" id="conPass" className="form-control"
                                            placeholder="Confirm Password" />
                                    </div>
                                    <button className="hero_btn mt-3" type='submit' >Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default ChangePassword;