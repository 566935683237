import React, { useEffect, useState, Fragment,Component,useRef } from 'react';
import  ParentChildComponent from '../pages/ParentChildComponent';


export default class ParentComponent extends Component {
   constructor(){
    super()
    this.state={
        color: "red"
    }
    this.handleClick = this.handleClick.bind(this);
   } 
   handleClick(){
    this.setState((previousState)=> ({
        color: "green"
    })
    )
   }
  render() {
    return (
        <div>
            <h1>This is a parent component</h1>
            <ul>
                <li>
                    <ParentChildComponent color={this.state.color}/>
                    <button onClick={this.handleClick}>My button</button>
                </li>
            </ul>
        </div>
    );
}
}