import React, { useEffect, useState, Fragment,Component,useRef} from 'react'
import ReactDOM from 'react-dom';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import  CommonComponent from '../constants/commonComponent';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useTranslation } from 'react-i18next';
import  commonFunctionClass from '../constants/commonFunctionClass';
import { ToastContainer, Toast } from 'react-bootstrap';


function StoneInformation(prop) {
    const navigate = useNavigate();
    const { token, setToken } = useToken();
    
    const { t, i18n } = useTranslation();
    const [userRole, setUserRole] = useState('');
    
    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);

    let currentid = 0;

    function checkFormControlRightOrNo(ColumnName)
    {   
        return CommonComponent.prototype.checkFormControlRightOrNo(prop.ItemFormControlRight,ColumnName);
    }
    
    const handleCart = async () => {
        let checkboxvals = prop.SelectedItem.ITEMID;
        
        const data = await commonFunctionClass().addToCart(token,navigate,checkboxvals,prop.ItemType,"INSERT","");        
        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
        setShowPopupMsg(true);
    }
    
    const handleWatch = async () => {
        let checkboxvals = prop.SelectedItem.ITEMID;
        
        const data = await commonFunctionClass().addToWatch(token,navigate,checkboxvals,prop.ItemType,"INSERT","");
        
        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);        
        setShowPopupMsg(true);
    }

    const handleOrder = async () => {
        let checkboxvals = prop.SelectedItem.ITEMID;
        
        const data = await commonFunctionClass().addToOrder(token,navigate,checkboxvals,prop.ItemType,"INSERT","");
        
        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
        setShowPopupMsg(true);
    }

    const AlterNativeItems = (props) => {
        let itemJsonData = props.itemdata;
        
        return (
            <div>
                <div className="prod_heading prod-max-width">
                    <img src={itemJsonData.IMAGE} className="img-fluid prod_img" alt=""/>
                </div>

                {
                    prop.ItemFormControlRight.map(data => (
                        data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "ALTERNATEGRID"?
                            ''
                        :
                            <div className="prod_heading prod-max-width">
                                <span className="prod_text">{itemJsonData[data.CONTROLNAME]}</span>
                            </div>
                    
                    ))
                }
                <div className="prod_heading prod-max-width">
                    <span className="prod_text"><a>VIEW DETAIL</a></span>
                </div>
            </div>
        )
    };
    
    const CompareItems = (props) => {
        let itemJsonData = props.itemdata;
        
        return (
            <div>
                <div className="prod_heading prod-max-width">
                    <img src={itemJsonData.IMAGE} className="img-fluid prod_img" alt=""/>
                </div>

                {
                    prop.ItemFormControlRight.map(data => (
                        data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "ALTERNATEGRID"?
                            ''
                        :
                            <div className="prod_heading prod-max-width">
                                {
                                    data.CONTROLNAME.toUpperCase() == 'LAB_NAME'?
                                        <div>
                                            <a href={itemJsonData["CERTLINK"]} target='_blank' style={{color: 'blue'}}><u>{itemJsonData[data.CONTROLNAME]}</u></a>
                                        </div>
                                    :
                                        <span className="prod_text">{itemJsonData[data.CONTROLNAME]}</span>
                                }
                            </div>
                    
                    ))
                }
                <div className="prod_heading prod-max-width">
                    <span className="prod_text"><a>VIEW DETAIL</a></span>
                </div>
            </div>
        )
    };

    return (
        <div>
            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="offcanvas offcanvas-end w-50 canfava_f" tabIndex="-1" id="diamond-img" aria-labelledby="diamond-imgLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <img src={prop.ItemImageSource} className="img-fluid" alt="" />
                </div>
            </div>

            <div className="offcanvas offcanvas-end w-50 canfava_f" tabIndex="-1" id="diamond-video"
                aria-labelledby="diamond-videoLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <iframe src={prop.ItemVideoSource} width="100%" height="100%">
                    </iframe>
                </div>
            </div>

            <div className="offcanvas offcanvas-end w-50 canfava_f" tabIndex="-1" id="diamond-certificate"
                aria-labelledby="diamond-certificateLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <iframe src={prop.ItemCertificateSource} width="100%" height="100%">
                    </iframe>
                </div>
            </div>

            <div className="offcanvas offcanvas-end w-75" tabIndex="-1" id="diamond-cart" aria-labelledby="diamond-cartLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="diamond-list--cartWrapper">
                        <div className="row">
                            {/* <!-- Prod Slider --> */}
                            <div className="col-lg-5 col-md-6">
                                <div className="diamond-list--cart--leftImg">

                                    <Swiper style={{"--swiper-navigation-color": "#000"}} navigation={true} modules={[Navigation]} className="mySwiper">
                                        <SwiperSlide>
                                            <img src={prop.SelectedItem.IMAGE} className='img-fluid' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <iframe src={prop.SelectedItem.V360VIDEO} width="100%" height="550px">
                                            </iframe>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            {/* <!-- Prod Slider --> */}

                            {/* <!-- Prod Details --> */}
                            <div className="col-lg-7 col-md-6">
                                <div className="diamondDetailRight ps-5">
                                    <div className="diamondDetailBox">
                                        <div className="detailTopBasic">
                                            <div className="detailBasic">
                                                <ul className="basicDetailFull list-unstyled d-flex gap-3 fs-4 fw-bold">
                                                    {checkFormControlRightOrNo("SHAPENAME") == true? <li>{prop.SelectedItem.SHAPENAME}</li>:''}                                                    
                                                    {checkFormControlRightOrNo("CARAT_FORMATTED") == true? <li>{prop.SelectedItem.CARAT_FORMATTED}</li>:''}                                                    
                                                    {checkFormControlRightOrNo("COLORNAME") == true? <li>{prop.SelectedItem.COLORNAME}</li>:''}                                                    
                                                    {checkFormControlRightOrNo("CLARITYNAME") == true? <li>{prop.SelectedItem.CLARITYNAME}</li>:''}
                                                </ul>
                                            </div>
                                            <div className="detailSmallBasic">
                                                <ul  className="basicDetailFull list-unstyled align-items-center d-flex gap-2 fs-6 fw-normal text-secondary">
                                                    {checkFormControlRightOrNo("CUT") == true? <li>{prop.SelectedItem.CUT}</li>:''}
                                                    {checkFormControlRightOrNo("POLISH") == true? <li>{prop.SelectedItem.POLISH}</li>:''}
                                                    {checkFormControlRightOrNo("SYMMETRY") == true? <li>{prop.SelectedItem.SYMMETRY}</li>:''}
                                                    {checkFormControlRightOrNo("FLUORESCENCE") == true? <li>{prop.SelectedItem.FLUORESCENCE}</li>:''}
                                                </ul>
                                            </div>
                                            <div className="detailPrice mb-3">                                                
                                                {checkFormControlRightOrNo("AMOUNT_FORMATTED") == true? <h3 className="fs-3">{prop.SelectedItem.AMOUNT_FORMATTED}</h3>:''}
                                            </div>
                                            <div className="detailShareWrapper mb-5 d-flex gap-2 align-items-start flex-wrap">
                                                {checkFormControlRightOrNo("ADDTOCART") == true? <button className="btn2" onClick={handleCart}>ADD TO CART</button>:''}
                                                {checkFormControlRightOrNo("ADDTOWATCH") == true? <button className="btn1" onClick={handleWatch}>ADD TO WATCHLIST</button>:''}
                                                {checkFormControlRightOrNo("ADDTOORDER") == true? <button className="btn1" onClick={handleOrder}>ADD TO ORDER</button>:''}
                                                {checkFormControlRightOrNo("SHARE") == true? <button className="btn3">SHARE</button>:''}
                                                {checkFormControlRightOrNo("DOWNLOAD") == true? <button className="btn4">DOWNLOAD</button>:''}
                                                {checkFormControlRightOrNo("CERTIFICATE") == true? <button className="btn5">CERTIFICATE</button>:''}
                                            </div>

                                            <div className="prodDesrpWrapper col-lg-6">
                                                <ul className="mb-0 list-unstyled">
                                                    {checkFormControlRightOrNo("LENGTH_MEAS2") == true?
                                                        <div>
                                                            <li className="d-flex justify-content-between">
                                                                <span className="prodDesrpLabel">{t('length')}</span>
                                                                <span className="propDetailsVal">{prop.SelectedItem.LENGTH_MEAS2}</span>
                                                            </li>
                                                            <hr />
                                                        </div>
                                                    :
                                                       ''
                                                    }

                                                    {checkFormControlRightOrNo("WIDTH_MEAS1") == true?
                                                        <div>
                                                            <li className="d-flex justify-content-between">
                                                                <span className="prodDesrpLabel">{t('width')}</span>
                                                                <span className="propDetailsVal">{prop.SelectedItem.WIDTH_MEAS1}</span>
                                                            </li>
                                                            <hr />
                                                        </div>
                                                    :
                                                       ''
                                                    }
                                                    
                                                    {checkFormControlRightOrNo("DEPTH_MEAS3") == true?
                                                        <div>
                                                            <li className="d-flex justify-content-between">
                                                                <span className="prodDesrpLabel">{t('depth')}</span>
                                                                <span className="propDetailsVal">{prop.SelectedItem.DEPTH_MEAS3}</span>
                                                            </li>
                                                            <hr />
                                                        </div>
                                                    :
                                                       ''
                                                    }

                                                    {checkFormControlRightOrNo("TABLE_PERC") == true?
                                                        <div>
                                                            <li className="d-flex justify-content-between">
                                                                <span className="prodDesrpLabel">{t('table')}</span>
                                                                <span className="propDetailsVal">{prop.SelectedItem.TABLE_PERC}</span>
                                                            </li>
                                                            <hr />
                                                        </div>
                                                    :
                                                       ''
                                                    }
                                                    

                                                    {checkFormControlRightOrNo("DEPTH_PER") == true?
                                                        <div>
                                                            <li className="d-flex justify-content-between">
                                                                <span className="prodDesrpLabel">{t('depth %')}</span>
                                                                <span className="propDetailsVal">{prop.SelectedItem.DEPTH_PER}</span>
                                                            </li>
                                                            <hr />
                                                        </div>
                                                    :
                                                       ''
                                                    }
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Prod Details --> */}

                            {/* <!-- Prod Details Table --> */}
                            <h3 className="mt-5 heading-details">{t('detailinfo')}</h3>
                            <div className="prodDesrpWrapper col-lg-4">
                                <h5 className='mb-5'>{t('basicdetails')}</h5>
                                <ul className="mb-0 list-unstyled">
                                    {checkFormControlRightOrNo("LOTID") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('stockno')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.LOTID}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("CERTIFICATENO") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('reportno')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.CERTIFICATENO}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("LAB_NAME") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('lab')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.LAB_NAME}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("AMOUNT_FORMATTED") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('amount')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.AMOUNT_FORMATTED}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("SHAPENAME") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('shape')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.SHAPENAME}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("CARAT_FORMATTED") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('carat')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.CARAT_FORMATTED}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("COLORNAME") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('color')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.COLORNAME}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("CLARITYNAME") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('clarity')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.CLARITYNAME}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("CUT") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('cut')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.CUT}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("FLUORESCENCE") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('fluorescenc')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.FLUORESCENCE}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("LOCATION_NAME") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('location')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.LOCATION_NAME}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                </ul>
                            </div>

                            <div className="prodDesrpWrapper col-lg-4">
                                <ul className="mb-0 list-unstyled">
                                    <h5 className='mb-5'>{t('measurements')}</h5>
                                
                                    {checkFormControlRightOrNo("TABLE_PERC") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('table')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.TABLE_PERC}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("DEPTH_PER") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('depth %')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.DEPTH_PER}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("LENGTH_MEAS2") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('length')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.LENGTH_MEAS2}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("WIDTH_MEAS1") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('width')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.WIDTH_MEAS1}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                    {checkFormControlRightOrNo("DEPTH_MEAS3") == true?
                                        <div>
                                            <li className="d-flex justify-content-between">
                                                <span className="prodDesrpLabel">{t('depth')}</span>
                                                <span className="propDetailsVal">{prop.SelectedItem.DEPTH_MEAS3}</span>
                                            </li>
                                            <hr />
                                        </div>
                                    :
                                        ''
                                    }
                                </ul>
                            </div>
                           
                            {/* <!-- Compare Product --> */}
                            <h3 className="mt-5 heading-details">{t('compairealtdiamonds')}</h3>
                            <div className="col-lg-12">
                                <div className="details-compare--wrapper">
                                    <div className="row flex-nowrap g-0 overflow-auto">
                                        <div className="col-3">
                                            <ul className="details-compare--container list-unstyled mb-0 bg-light">
                                                <li className="details-compare--li">
                                                    <div className="prod_heading prod-max-width">
                                                        <span className="prod_text">{t('image')}</span>
                                                    </div>
                                                    {
                                                        prop.ItemFormControlRight.map(data => (
                                                            data.ISCONTROL == true?
                                                                ''
                                                            :
                                                            data.PARENTCONTROLNAME.toUpperCase() != "ALTERNATEGRID"?
                                                                ''
                                                            :
                                                            <div className="prod_heading prod-max-width">
                                                                <span className="prod_text">{t(data.CONTROLLABLE)}</span>
                                                            </div>                                         
                                                        ))
                                                    }
                                                    <div className="prod_heading prod-max-width">
                                                        <span className="prod_text"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            prop.AlternateItems!=='undefined'?
                                                prop.AlternateItems.map(altitem=>(
                                                    <div className="col-3">
                                                        <ul className="details-compare--container list-unstyled mb-0">
                                                            <li className="details-compare--li">
                                                                {
                                                                    <AlterNativeItems itemdata={altitem} key={altitem.ITEMID} />
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                ))
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Compare Product --> */}

                            {/* <!-- Recently Viewed Product --> */}
                            {/* <h3 className="mt-5 heading-details">{t('recentlyviewed')}</h3>
                            <div className="col-lg-12">
                                <div id="grid-view" className="d-block p-0">

                                </div>
                            </div> */}
                            {/* <!-- Recently Viewed Product --> */}

                            {/* <!-- Suggested Product --> */}
                            {/* <h3 className="heading-details">{t('suggestedstock')}</h3>
                            <div className="col-lg-12">
                                <div id="grid-view" className="d-block p-0">
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="offcanvas offcanvas-end w-75" tabIndex="-1" id="diamond-compare" aria-labelledby="diamond-cartLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="diamond-list--cartWrapper">
                        <div className="row">
                            {/* <!-- Compare Product --> */}
                            <h3 className="mt-5 heading-details">{t('compairealtdiamonds')}</h3>
                            <div className="col-lg-12">
                                <div className="details-compare--wrapper">
                                    <div className="row flex-nowrap g-0 overflow-auto">
                                        <div className="col-3">
                                            <ul className="details-compare--container list-unstyled mb-0 bg-light">
                                                <li className="details-compare--li">
                                                    <div className="prod_heading prod-max-width">
                                                        <span className="prod_text">{t('image')}</span>
                                                    </div>
                                                    {
                                                        prop.ItemFormControlRight.map(data => (
                                                            data.ISCONTROL == true?
                                                                ''
                                                            :
                                                            data.PARENTCONTROLNAME.toUpperCase() != "ALTERNATEGRID"?
                                                                ''
                                                            :
                                                            <div className="prod_heading prod-max-width">
                                                                <span className="prod_text">{t(data.CONTROLLABLE)}</span>
                                                            </div>                                         
                                                        ))
                                                    }
                                                    <div className="prod_heading prod-max-width">
                                                        <span className="prod_text"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            prop.CompareItems!=='undefined'?
                                                prop.CompareItems.map(item=>(
                                                    <div className="col-3">
                                                        <ul className="details-compare--container list-unstyled mb-0">
                                                            <li className="details-compare--li">
                                                            {
                                                                <CompareItems itemdata={item} key={item.ITEMID} />
                                                            }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                ))
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Compare Product --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StoneInformation;