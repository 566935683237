import React, { useEffect, useState, Fragment, useRef  } from 'react'

function ParentChildFunction(props) {   
    
    return (
        <div>
            <h1>This is a child component</h1>
            <h1 style={{ color: props.color }}>
                GeeksForGeeks
            </h1>
        </div> 
    )
}
export default ParentChildFunction;