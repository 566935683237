
import React, { useEffect,useState,Fragment, useRef } from 'react'
import { BaseUrl,HomeUrl } from '../constants/global'
import axios from "axios";
import { json } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

function commonFunctionClass() {
    async function getItemCount(token,navigate,Mode) {
        let data = [];
        
        let url='';
        let xtraParam=`<FORMNAME></FORMNAME>`;

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        url = `${BaseUrl}/api/v1/items/getitemtotal?xtraParam=${xtraParam}&Mode=${Mode}`;

        const result = await axios.get(url,config).then((response) => {
            data = JSON.parse(response.data.data);
            if(Mode =="CART")
            {
                const liElement = document.getElementById("licart");
                liElement.setAttribute('data-batch', data[0].TOTALCOUNT);
            }
        })
        .catch((error) => {
            console.error(error);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                window.location.reload();    
            }            
         });
         
        return data;
    }

    async function CommonParameterFilter(token,_itemType,_category,_formName,_xtraParam, _mode) {
        let data = [];
        let url='';
        let xtraParam=`<FORMNAME>${_formName}</FORMNAME>`+_xtraParam;

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        url = `${BaseUrl}/api/v1/CommonParameter/GetParameter?ItemType=${_itemType}&Category=${_category}&Mode=${_mode}&xtraParam=${xtraParam}`;
        const result = await axios.get(url,config).then((response) => {
            if(response.data.data!=null)
                data = JSON.parse(response.data.data);
            
        });
         
        return data;
    }

    async function CommonParameterFilterWithoutAuthentication(token,_itemType,_category,_formName,_xtraParam, _mode) {
        let data = [];
        let url='';
        let xtraParam=`<FORMNAME>${_formName}</FORMNAME>`+_xtraParam;

        const formData = new FormData();
        formData.append("ItemType", _itemType);
        formData.append("Category", _category);
        formData.append("Mode", _mode);
        formData.append("xtraParam", xtraParam);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
        };

        
        try {
            let tmpwait = await axios.post(
                `${BaseUrl}/api/v1/order/GetParameter`,
                formData,
                config
            ).then((response) => {
                if(response.data.status.toUpperCase() == "ERROR")
                {
                    console.error(response.data.message);
                }
                else
                {
                    data = JSON.parse(response.data.data);
                }
            })
            .catch((error) => {
                console.error(error.response.status);
            });
        }
        catch (ex) {}
         
        return data;
    }
    async function getAlterNativeItem(token,navigate,itemId,ItemType) {
        let data = [];
        
        let url= `${BaseUrl}/api/v1/items/getAlterNativeItem?Id=${itemId}&Type=${ItemType}`;

        let _token = token;
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const result = await axios.get(
            url,
            saerchconfig
        ).then((response) => {
            data = JSON.parse(response.data.data);

        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');           
                window.location.href=HomeUrl;
            }            
         });
        
        return data;
    }

    async function getStoneInfo(token,navigate,itemId,ItemType) {
        let data = [];
        
        let url= `${BaseUrl}/api/v1/items/getStoneInfo?Id=${itemId}&Type=${ItemType}`;

        let _token = token;
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const result = await axios.get(
            url,
            saerchconfig
        ).then((response) => {
            data = JSON.parse(response.data.data);

        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');           
                window.location.href=HomeUrl;
            }            
         });
        
        return data;
    }

    async function addToWatch(token,navigate,_checkboxvals,_itemType,_Mode,_Remark) {
        let data = [
            {
                POPUPMSGTYPE:"",
                POPUPHEADERMSG:"",
                POPUPDETAILEDMSG:"",
                DATA: []
            }
        ];
        
        let bodyParameters = {
            userid: '54544545',
            watchitemids: _checkboxvals.toString(),
            itemtype: _itemType,
            remark: _Remark,
            mode: _Mode
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const result = await axios.post(
            `${BaseUrl}/api/v1/items/postwatch`,
            bodyParameters,
            config
        ).then((response) => {            
            data[0].POPUPMSGTYPE = "light";
            data[0].POPUPHEADERMSG = response.data.header;
            data[0].POPUPDETAILEDMSG = response.data.message;
            data[0].DATA=response.data.data;

            if (response.data.status !== 'Success') {            
                data[0].POPUPMSGTYPE = "warning";
            }
        }).catch((error) => {
            
            data[0].POPUPMSGTYPE = "Warning";
            data[0].POPUPHEADERMSG = error.response.title;
            data[0].POPUPDETAILEDMSG = error.response.error;
            data[0].DATA=[];

            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear();
                window.location.href=HomeUrl;    
            }            
         });

         return data;
    }

    async function addToOrder(token,navigate,_checkboxvals,_itemType,_Mode,_Remark){
        
        let _token = token;

        let data = [
            {
                POPUPMSGTYPE:"",
                POPUPHEADERMSG:"",
                POPUPDETAILEDMSG:"",
                DATA: []
            }
        ];
        
        let bodyParameters = {
            userid: '54544545',
            orderitemids: _checkboxvals.toString(),
            itemtype: _itemType,
            remark: _Remark,
            mode: _Mode
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const result = await axios.post(
            `${BaseUrl}/api/v1/items/postorder`,
            bodyParameters,
            config
        ).then((response) => {            
            data[0].POPUPMSGTYPE = "light";
            data[0].POPUPHEADERMSG = response.data.header;
            data[0].POPUPDETAILEDMSG = response.data.message;
            data[0].DATA=response.data.data;

            if (response.data.status !== 'Success') {
                data[0].POPUPMSGTYPE = "warning";
            }
        }).catch((error) => {
            
            data[0].POPUPMSGTYPE = "Warning";
            data[0].POPUPHEADERMSG = error.response.title;
            data[0].POPUPDETAILEDMSG = error.response.error;
            data[0].DATA=[];
            
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');
                window.location.href=HomeUrl;    
            }
         });

         return data;
    }

    async function addToCart(token,navigate,_checkboxvals,_itemType,_Mode,_Remark){
        let data = [
            {
                POPUPMSGTYPE:"",
                POPUPHEADERMSG:"",
                POPUPDETAILEDMSG:"",
                DATA: []
            }
        ];

        let _token = token;

        let bodyParameters = {
            userid: '54544545',
            cartitemids: _checkboxvals.toString(),
            itemtype: _itemType,
            remark: _Remark,
            mode: _Mode
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const result = await axios.post(
            `${BaseUrl}/api/v1/items/postcart`,
            bodyParameters,
            config
        ).then((response) => {
            
            data[0].POPUPMSGTYPE = "light";
            data[0].POPUPHEADERMSG = response.data.header;
            data[0].POPUPDETAILEDMSG = response.data.message;
            data[0].DATA=response.data.data;

            if (response.data.status !== 'Success') {
                data[0].POPUPMSGTYPE = "warning";
            }

        }).catch((error) => {
            
            data[0].POPUPMSGTYPE = "Warning";
            data[0].POPUPHEADERMSG = error.response.title;
            data[0].POPUPDETAILEDMSG = error.response.error;
            data[0].DATA=[];
            
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear();
                window.location.href=HomeUrl;    
            }
            
         });

         return data;
    }

    async function saveSearch(token,navigate,_itemType,searchid,titleinputval){
        let data = [
            {
                POPUPMSGTYPE:"",
                POPUPHEADERMSG:"",
                POPUPDETAILEDMSG:"",
                DATA: []
            }
        ];

        let savebodyParameters = {
            userid: 0,
            searchid: searchid,
            searchname:titleinputval ,
        
        };
    
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
    
        const result = await  axios.post(
            `${BaseUrl}/api/v1/items/savesearch`,
            savebodyParameters,
            saerchconfig
        ).then((response) => {
            data[0].POPUPMSGTYPE = "light";
            data[0].POPUPHEADERMSG = "Saved";
            data[0].POPUPDETAILEDMSG = "Search saved successfully...";
            data[0].DATA=[];
        })
        .catch((error) => {
            console.error(error)
            data[0].POPUPMSGTYPE = "warning";
            data[0].POPUPHEADERMSG = "Error";
            data[0].POPUPDETAILEDMSG = error.response.error;
            data[0].DATA=[];

            if(error.response.status==401)
            {
                localStorage.clear();
                window.location.href=HomeUrl;    
            }
        });

         return data;
    }
    
    async function getSearchParam(token,_itemType,SEARCHID) {
        // SEARCHID = '1bf47172-40de-4ce5-b965-d1353b14c5a0';
        let data = {
            shape_Params:'',
            carat_Params:'',
            color_Params:'',
            clarity_Params:'',
            cut_Params:'',
            polish_Params:'',
            symmentry_Params:'',
            fluorescence_Params:'',
            lab_Params:'',
            discount_From:'',
            discount_To:'',
            price_From:'',
            price_To:'',
            countryOfOrigin_Params:'',
            table_Percentage_From:'',
            table_Percentage_To:'',
            depth_Percentage_From:'',
            depth_Percentage_To:'',
            ratio_From:'',
            ratio_To:'',
            length_From:'',
            length_To:'',
            width_From:'',
            width_To:'',
            depth_From:'',
            depth_To:'',
            crown_Angle_From:'',
            crown_Angle_To:'',
            crown_Height_From:'',
            crown_Height_To:'',
            girdle_Percentage_From:'',
            girdle_Percentage_To:'',
            pavilion_Angle_From:'',
            pavilion_Angle_To:'',
            pavilion_Depth_From:'',
            pavilion_Depth_To:'',
            lower_Half_From:'',
            lower_Half_To:'',
            star_Length_From:'',
            star_Length_To:'',
            itemType: _itemType,
            colorType_Params: 'WHITE'
        };

        let _mode = "SEARCHID";
        let url='';
        let xtraParam=`<USERSEARCHID>${SEARCHID}</USERSEARCHID>`;

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        url = `${BaseUrl}/api/v1/CommonParameter/GetParameter?ItemType=${_itemType}&Category=0&Mode=${_mode}&xtraParam=${xtraParam}`;
        const result = await axios.get(url,config).then((response) => {
            if(response.data.data!=null)
            {
                let data1 = JSON.parse(response.data.data);
                

                if(data1.length>0)
                {
                    data['shape_Params']=data1[0]['SHAPE_PARAMS'];
                    data['carat_Params']=data1[0]['CARAT_PARAMS'];
                    data['color_Params']=data1[0]['COLOR_PARAMS'];
                    data['clarity_Params']=data1[0]['CLARITY_PARAMS'];
                    data['cut_Params']=data1[0]['CUT_PARAMS'];
                    data['polish_Params']=data1[0]['POLISH_PARAMS'];
                    data['symmentry_Params']=data1[0]['SYMMENTRY_PARAMS'];
                    data['fluorescence_Params']=data1[0]['FLUORESCENCE_PARAMS'];
                    data['lab_Params']=data1[0]['LAB_PARAMS'];
                    data['discount_From']=data1[0]['DISCOUNT_FROM'];
                    data['discount_To']=data1[0]['DISCOUNT_TO'];
                    data['price_From']=data1[0]['PRICE_FROM'];
                    data['price_To']=data1[0]['PRICE_TO'];
                    data['countryOfOrigin_Params']=data1[0]['COUNTRYOFORIGIN_PARAMS'];
                    data['table_Percentage_From']=data1[0]['TABLE_PERCENTAGE_FROM'];
                    data['table_Percentage_To']=data1[0]['TABLE_PERCENTAGE_TO'];
                    data['depth_Percentage_From']=data1[0]['DEPTH_PERCENTAGE_FROM'];
                    data['depth_Percentage_To']=data1[0]['DEPTH_PERCENTAGE_TO'];
                    data['ratio_From']=data1[0]['RATIO_FROM'];
                    data['ratio_To']=data1[0]['RATIO_TO'];
                    data['length_From']=data1[0]['LENGTH_FROM'];
                    data['length_To']=data1[0]['LENGTH_TO'];
                    data['width_From']=data1[0]['WIDTH_FROM'];
                    data['width_To']=data1[0]['WIDTH_TO'];
                    data['depth_From']=data1[0]['DEPTH_FROM'];
                    data['depth_To']=data1[0]['DEPTH_TO'];
                    data['crown_Angle_From']=data1[0]['CROWN_ANGLE_FROM'];
                    data['crown_Angle_To']=data1[0]['CROWN_ANGLE_TO'];
                    data['crown_Height_From']=data1[0]['CROWN_HEIGHT_FROM'];
                    data['crown_Height_To']=data1[0]['CROWN_HEIGHT_TO'];
                    data['girdle_Percentage_From']=data1[0]['GIRDLE_PERCENTAGE_FROM'];
                    data['girdle_Percentage_To']=data1[0]['GIRDLE_PERCENTAGE_TO'];
                    data['pavilion_Angle_From']=data1[0]['PAVILION_ANGLE_FROM'];
                    data['pavilion_Angle_To']=data1[0]['PAVILION_ANGLE_TO'];
                    data['pavilion_Depth_From']=data1[0]['PAVILION_DEPTH_FROM'];
                    data['pavilion_Depth_To']=data1[0]['PAVILION_DEPTH_TO'];
                    data['lower_Half_From']=data1[0]['LOWER_HALF_FROM'];
                    data['lower_Half_To']=data1[0]['LOWER_HALF_TO'];
                    data['star_Length_From']=data1[0]['STAR_LENGTH_FROM'];
                    data['star_Length_To']=data1[0]['STAR_LENGTH_TO'];
                    data['colorType_Params']=data1[0]['COLORTYPE_PARAMS'];
                }

            }
            
        });
         
        return data;
    }

    
    async function ExportToExcel(checkboxes,items,itemFormControlRight, t)
    {
        var msgData = [{
            MsgType:"success",
            HeaderMsg:"Export Success",
            DetailedMsg:"File saved successfully...",
            PopupMsg : false
        }];

        // let checkboxes = document.getElementsByName('chk_item[]');

        let itemInfo = [];

        const jsonDataForExport = [];
        const jsonDataForExportColumn = [];
        
        itemFormControlRight.map(data => {
            if(data.ISCONTROL != true &&  data.PARENTCONTROLNAME.toUpperCase() == "MAINGRID")
            {
                var localColumnVariable=[];
                localColumnVariable["COLUMNLABLE"] =  t(data.CONTROLLABLE);
                localColumnVariable["COLUMNFIELD"] =  data.CONTROLNAME;

                if(data.CONTROLNAME == "IMAGE")
                {
                    localColumnVariable["COLUMNLABLE"] =  t("image");
                    localColumnVariable["COLUMNFIELD"] =  data.CONTROLNAME;
                    jsonDataForExportColumn.push(localColumnVariable);

                    localColumnVariable=[];
                    localColumnVariable["COLUMNLABLE"] =  t("video");
                    localColumnVariable["COLUMNFIELD"] =  "V360VIDEO";
                    jsonDataForExportColumn.push(localColumnVariable);

                    localColumnVariable=[];
                    localColumnVariable["COLUMNLABLE"] =  t("certificate");
                    localColumnVariable["COLUMNFIELD"] =  "CERTLINK";
                    jsonDataForExportColumn.push(localColumnVariable);
                }
                else
                {
                    jsonDataForExportColumn.push(localColumnVariable);
                }
            }
        });

        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                indx = indx + 1;                
                let itemInfo1 = items.filter((item) => item.ITEMID == checkboxes[i].value)[0];
                let info=[];

                jsonDataForExportColumn.map(data => {
                    if(data.COLUMNFIELD == 'IMAGE' || data.COLUMNFIELD == 'V360VIDEO' || data.COLUMNFIELD == 'CERTLINK')
                    {
                        info[data.COLUMNLABLE] = '=HYPERLINK("'+itemInfo1[data.COLUMNFIELD]+'", "'+t('clicktoopen')+'")';
                    }
                    else
                    {
                        info[data.COLUMNLABLE] = itemInfo1[data.COLUMNFIELD];
                    }
                });

                itemInfo.push(info);
            }
        }
              
        if(indx <=0)
        {
            msgData[0]["MsgType"]="warning";
            msgData[0]["HeaderMsg"]="No Item selected";
            msgData[0]["DetailedMsg"]="Please select atleast 1 item";
            msgData[0]["PopupMsg"]=true;
            return msgData;
        }

        const worksheet = XLSX.utils.json_to_sheet(itemInfo);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "StoneData.csv");

        return msgData;
    }
        
    async function sentMail(token,navigate,itemId,ItemType,_formName,_xtraParam) {
        
        var msgData = [{
            MsgType:"light",
            HeaderMsg:"Sent Mail Success",
            DetailedMsg:"Mail sent successfully...",
            PopupMsg : true
        }];

        let xtraParam=`<FORMNAME>${_formName}</FORMNAME>`+_xtraParam;

        let url= `${BaseUrl}/api/v1/items/sentMail?Id=${itemId}&Type=${ItemType}&xtraParam=${xtraParam}`;

        let _token = token;
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const result = await axios.get(
            url,
            saerchconfig
        ).then((response) => {
            let data = JSON.parse(response.data.data);

            if(response.data.status.toUpperCase()=="ERROR")
            {
                msgData[0]["MsgType"]="warning";
                msgData[0]["HeaderMsg"]="Error: Mail not sent";
                msgData[0]["DetailedMsg"]=response.data.message;
                msgData[0]["PopupMsg"]=true;
            }

        }).catch((error) => {
            
            msgData[0]["MsgType"]="warning";
            msgData[0]["HeaderMsg"]="Error: Mail not sent";
            msgData[0]["DetailedMsg"]=error.response;
            msgData[0]["PopupMsg"]=true;

            console.log(error);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');           
                window.location.href=HomeUrl;
            }            
         });
        
        return msgData;
    }

    return {
        getItemCount:getItemCount,
        CommonParameterFilter: CommonParameterFilter,
        getAlterNativeItem: getAlterNativeItem,
        addToWatch: addToWatch,
        addToOrder: addToOrder,
        addToCart: addToCart,
        saveSearch: saveSearch,
        getSearchParam: getSearchParam,
        ExportToExcel: ExportToExcel,
        getStoneInfo: getStoneInfo,
        sentMail: sentMail,
        CommonParameterFilterWithoutAuthentication: CommonParameterFilterWithoutAuthentication
    }
};
export default commonFunctionClass;