import { Fragment } from "react";
import { 
  Link
} from "react-router-dom";

function NotFound() {
  return <Fragment>
    <div class="thankYouWrapper">
      <div class="thankYouInnerBox">
        <div class="thankYouMainBox">
          <div class="thankYouImgBlock">
            <img src="/static/media/1.0391887d.svg" alt=""/>
          </div><div class="thankYouPageContent">
            <h2>Oops, Page not found!</h2>
            <Link to="/">
              <button class="commonButton">Back To Home</button>
           </Link>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
}
export default NotFound;