import React, { useEffect, useState, useMemo, Fragment,useRef } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';

import { useNavigate, useParams } from 'react-router-dom';

import { ToastContainer, Toast } from 'react-bootstrap';
import Pagination from '../components/Pagination';
import { Popover } from 'antd';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

import { FreeMode, Navigation, Thumbs } from "swiper";
import CommonComponent from '../constants/commonComponent';
import commonFunctionClass from '../constants/commonFunctionClass';
import StoneInformation from '../components/StoneInformation';

let PageSize = 10;

function ItemList() {
    const navigate = useNavigate();
    let { searchid } = useParams();
    const { t, i18n } = useTranslation();
    const { token, setToken } = useToken();

    const [userInfo, setUserInfo] = useState([]);
    const [userRole, setUserRole] = useState('');

    const [itemShapes, setitemShapes] = useState([]);
    const [itemCarats, setitemCarats] = useState([]);
    const [itemColors, setitemColors] = useState([]);
    const [itemClarities, setitemClarities] = useState([]);

    const _itemType = useRef('1');
    const _formName = useRef('MOSCOW STOCK');
    const _itemTypeName = useRef('MOSCOW STOCK');
    const _category = useRef('SEARCHPAGE');
    const [itemFormControlRight, setitemFormControlRight] = useState([]);
    const _searchItemParameterList = useRef({});

    const [_itemTypeState,setitemTypeState] = useState('1');
    const [_formNameState,setformNameState] = useState('MOSCOW STOCK');
    const [_itemTypeNameState,setitemTypeNameState] = useState('MOSCOW STOCK');
    const [_categoryState,setcategoryState] = useState('SEARCHPAGE');
    const [selectedItemState, setSelectedItemState] = useState([]);
    const [alternateItemsState, setAlternateItemsState] = useState([]);
    const [compareItemsState, setCompareItemsState] = useState([]);

    const [items, setitems] = useState([]);

    const [responseitems, setResponseItems] = useState([]);

    const [totalitems, setTotalitems] = useState(0);
    const [totalcartitemsAdded, setTotalCartItemsAdded] = useState(0);
    
    const [caratAddFrom, setCaratAddFrom] = useState('');
    const [caratAddTo, setCaratAddTo] = useState('');

    const [totalwatchitemsAdded, setTotalWatchItemsAdded] = useState(0);

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [searchCarats, setSearchCarats] = useState({ selectedcarats: [] });
    const [searchColors, setSearchColors] = useState({ selectedcolors: [] });

    const [searchShapes, setSearchShapes] = useState({ selectedshapes: [] });
    const [searchClarities, setSearchClarities] = useState({ selectedclarities: [] });
    
    const [showCartToast, setShowCartToast] = useState(false);
    const [showWatchToast, setShowWatchToast] = useState(false);

    const [showItemExceededToast, setShowItemExceededToast] = useState(false);
    const [ShowPopupMsg, setShowPopupMsg] = useState(false);
    const [popupHeaderMsg, setpopupHeaderMsg] = useState(false);
    const [popupDetailedMsg, setpopupDetailedMsg] = useState(false);
    const [popupMsgType, setpopupMsgType] = useState(false);
    
    const [showCartToastHoldItemAvailable, setCartToastHoldItemAvailable] = useState(false);

    const [itemImageSource, setItemImageSource] = useState('');
    const [itemVideoSource, setItemVideoSource] = useState('');
    const [itemCertificateSource, setItemCertificateSource] = useState('');

    const [loading, setLoading] = useState(false);

    const [showTabularView,setShowTabularView]=useState(true); 
    const [showGridView,setShowGridView]=useState(false); 
    
    const [isItemSelected,setIsItemSelected]=useState(false); 

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(100);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = items.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(items.length / recordsPerPage);
    const colorType = useRef("WHITE");

    window.addEventListener('resize', function(event) {
        manageHeight();
    }, true)

    /*Below function used for user have an right of column(It's dynamic and get from database)  */
    function checkFormControlRightOrNo(ColumnName)
    {
        return CommonComponent.prototype.checkFormControlRightOrNo(itemFormControlRight,ColumnName);
    }
    
    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight(data);
        }
        else if(_mode.toUpperCase() == "SHAPE")
        {
            setitemShapes(data);
        }
        else if(_mode.toUpperCase() == "CARAT")
        {
            setitemCarats(data);
        }
        else if(_mode.toUpperCase() == "COLOR")
        {
            setitemColors(data);
        }
        else if(_mode.toUpperCase() == "CLARITY")
        {
            setitemClarities(data);
        }
    }
    
    const bindSearchData = async () =>
    {   
        const { selectedcarats } = searchCarats;
        let _caratMapValues = selectedcarats.join(',');

        const { selectedcolors } = searchColors;
        let _colorMapValues = selectedcolors.join(',');

        const { selectedshapes } = searchShapes;
        let _shapeMapValues = selectedshapes.join(',');

        const { selectedclarities } = searchClarities;
        let _clarityMapValues = selectedclarities.join(',');
                
        let data = await getSearchParam(searchid);

        _searchItemParameterList.current["shape_Params"] = _shapeMapValues;
        _searchItemParameterList.current["carat_Params"] = _caratMapValues;
        _searchItemParameterList.current["color_Params"] = _colorMapValues;
        _searchItemParameterList.current["clarity_Params"] = _clarityMapValues;
        _searchItemParameterList.current["itemType"] = _itemType.current;
        _searchItemParameterList.current["colorType_Params"] = colorType.current;
    }
    
    const getSearchParam = async () =>
    {
        const data = await commonFunctionClass().getSearchParam(token,_itemType.current,searchid);        
        _searchItemParameterList.current=data;
    }

    const handleExportToExcel=async()=>{
        let checkboxes = document.getElementsByName('chk_item[]');

        const data = await commonFunctionClass().ExportToExcel(checkboxes,items,itemFormControlRight,t);

        setpopupMsgType(data[0].MsgType);
        setpopupHeaderMsg(data[0].HeaderMsg);
        setpopupDetailedMsg(data[0].DetailedMsg);
        setShowPopupMsg(data[0].PopupMsg);
    }

    const reloadSearchData = async () =>
    {
        let data1 = await getSearchParam();
        const shapesArray = _searchItemParameterList.current.shape_Params.split(",");
        setSearchShapes({ selectedshapes: shapesArray })

        const clarityArray = _searchItemParameterList.current.clarity_Params.split(",");
        setSearchClarities({ selectedclarities: clarityArray });

        const colorArray = _searchItemParameterList.current.color_Params.split(",");
        setSearchColors({ selectedcolors: colorArray });

        if(_searchItemParameterList.current.carat_Params!=''){
            const caratArray = _searchItemParameterList.current.carat_Params.split(",");    
            setSearchCarats({selectedcarats:caratArray});
        }
       
        if(_searchItemParameterList.current.colorType_Params!=''){
            colorType.current = _searchItemParameterList.current.colorType_Params;
        }
    }

    function manageHeight() {
        const script = document.createElement('script');
        script.src = "../assets/js/list-height.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }       
    }

    useEffect(() => {
        manageHeight();
    }, []);

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        CommonParameterFilter('',"FORMCONTROL");
        CommonParameterFilter('',"SHAPE");
        CommonParameterFilter('',"CARAT");
        
        let xtraParams= `<COLORTYPE>${colorType.current}</COLORTYPE>`;
        CommonParameterFilter(xtraParams,"COLOR");
        
        CommonParameterFilter('',"CLARITY");
     
        axios.get(`${BaseUrl}/api/v1/userinfo/currentuser`,config).then((response) => {  

            if(response.status==200){
                setUserInfo(response.data.data);
                setUserRole(response.data.data.role);
            }   

        });
    }, []);


    const getData = async () => {
        setLoading(true);
        
        let data = await reloadSearchData();

        let _token = token;
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let d1 = await axios.get(
            `${BaseUrl}/api/v1/items/SearchItems/${searchid}`,
            saerchconfig
        ).then((response) => {
            setLoading(false);
            setTotalitems(0);
            if (response.data.status == 'DataLimitExceeded') {
                setpopupMsgType("warning");
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
                setShowPopupMsg(true);
                setitems([]);
                setResponseItems([]);
                setTotalitems(0);
            }
            else if (response.data.status == 'Error') 
            {
                setpopupMsgType("error");
                setpopupHeaderMsg(response.data.header);
                setpopupDetailedMsg(response.data.message);
                setShowPopupMsg(true);
                setitems([]);
                setResponseItems([]);
                setTotalitems(0);
            }
            else {
                setpopupMsgType("light");
                let _resdata = JSON.parse(response.data.data);
                setitems(_resdata);
                setResponseItems(_resdata);
                setTotalitems(_resdata.length);
            }

            manageHeight();

        })
        .catch((error) => {            
            setLoading(false);
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear(); 
                navigate('/');   
                window.location.href=HomeUrl;    
            }            
         });
         
         setLoading(false);
    }

    const handleAddCaratClick=(e)=>{
        const { selectedcarats } = searchCarats;
     
        if(caratAddFrom!='' && caratAddTo!='')
        {
            if(!isNaN(caratAddFrom) && !isNaN(caratAddTo) )
            {
                let carat_mapval=caratAddFrom +'-'+caratAddTo;     
                let check=selectedcarats.includes(carat_mapval)
                if(!check){
                    setSearchCarats({
                        selectedcarats:[...selectedcarats,carat_mapval]
                    })
    
                    setCaratAddFrom('');
                    setCaratAddTo('');           
                }
             }
        }
          
    }

    const handleRemoveCaratClick=(e)=>{
        const { selectedcarats } = searchCarats; 
        let carat_mapval=e.target.dataset.caratmapvalue;        
        let check=selectedcarats.includes(carat_mapval);
    
        if(check){
            setSearchCarats({
             selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
             });
             
         }           
         
    }

    useEffect(() => {
        getData();

    }, []);

    const handleSearchAdvance = () => {
        navigate('/itemsearch/' + searchid);
    }

    const handleClearAllSearchParams= () => {
        navigate('/');
    }

    const handleAnotherSearchAdvance = () => {
        const liElement = document.getElementById("licart");
        liElement.setAttribute('data-batch', '7');
    }

    const handleDataDisplay=(e)=>{
        if(showTabularView==true){
           
            setShowTabularView(false);
            setShowGridView(true);
            e.target.className='grid-view list-view';
        }
        else{
            setShowTabularView(true);
            setShowGridView(false);
            e.target.className='grid-view';
        }

        manageHeight();
    }

    const handleCaratClick = (e) => {

        const { selectedcarats } = searchCarats;
        let carat_mapval = e.target.dataset.caratmapvalue;

        let check = selectedcarats.includes(carat_mapval)
        if (check) {
            setSearchCarats({
                selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
            })
            e.target.className = '';
        }
        else {
            setSearchCarats({
                selectedcarats: [...selectedcarats, carat_mapval]
            })
            e.target.className = 'active';
        }
    }

    const handleColorClick = (e) => {
        const { selectedcolors } = searchColors;

        let color_mapval = e.target.dataset.colormapvalue;

        let check = selectedcolors.includes(color_mapval)
        if (check) {
            setSearchColors({
                selectedcolors: selectedcolors.filter((e) => e !== color_mapval)
            })
            e.target.className = '';
        }
        else {
            setSearchColors({
                selectedcolors: [...selectedcolors, color_mapval]
            })
            e.target.className = 'active';
        }
    }

    const handleShapeClick = (e) => {
        const { selectedshapes } = searchShapes;

        let shape_mapval = e.target.dataset.shapemapvalue;
        const el = document.getElementById(shape_mapval);

        let check = selectedshapes.includes(shape_mapval)
        if (check) {
            setSearchShapes({
                selectedshapes: selectedshapes.filter((e) => e !== shape_mapval)
            })
            el.className = '';
        }
        else {
            setSearchShapes({
                selectedshapes: [...selectedshapes, shape_mapval]
            })
            el.className = 'active';
        }
    }

    const handleClarityClick = (e) => {
        const { selectedclarities } = searchClarities;

        let clarity_mapval = e.target.dataset.claritymapvalue;

        let check = selectedclarities.includes(clarity_mapval)
        if (check) {
            setSearchClarities({
                selectedclarities: selectedclarities.filter((e) => e !== clarity_mapval)
            })
            e.target.className = '';
        }
        else {
            setSearchClarities({
                selectedclarities: [...selectedclarities, clarity_mapval]
            })
            e.target.className = 'active';
        }
    }
    
    const SearchData = async () =>
    {   
        let _token = token;
       
        let data =  await bindSearchData();
        
        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.post(
            `${BaseUrl}/api/v1/items/putsearch/${searchid}`,
            _searchItemParameterList.current,
            saerchconfig
        ).then((response) => {
            getData();
        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear();
                window.location.href=HomeUrl;    
            }
            
         });
    }


    const handleSearchNow = () => {
        SearchData();
    }

    const handleChageRecordsPerPage = () => {
        let recordsPerPage = document.getElementById('selectRecordsPerPage').value;
        setRecordsPerPage(recordsPerPage);
        setCurrentPage(1);
    }

    const handleCart = async () => {
        let checkboxvals = "";
        let checkboxes = document.getElementsByName('chk_item[]');

        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(checkboxvals.length <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);    
            return;
        }

        setLoading(true);
        const data = await commonFunctionClass().addToCart(token,navigate,checkboxvals,_itemType.current,"INSERT","");
        setLoading(false);
        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
        setShowPopupMsg(true);
        getData();
        unCheckAllCheckboxes();
    }

    const handleWatch = async () => {
        let checkboxvals = "";
        let checkboxes = document.getElementsByName('chk_item[]');

        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(checkboxvals.length <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);    
            return;
        }

        
        const data = await commonFunctionClass().addToWatch(token,navigate,checkboxvals,_itemType.current,"INSERT","");
        
        setpopupMsgType(data[0].POPUPMSGTYPE);
        setpopupHeaderMsg(data[0].POPUPHEADERMSG);
        setpopupDetailedMsg(data[0].POPUPDETAILEDMSG);
        setShowPopupMsg(true);
        unCheckAllCheckboxes();
    }

    function filterdivvisibility()
    {
        let div_ClearAll =document.getElementById('div_ClearAll');
        let div_Filter =document.getElementById('div_Filter');
        let checkboxes = document.getElementsByName('chk_item[]');

        let isanycheckboxselected=false;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                isanycheckboxselected=true;
            }
        }

        if(isanycheckboxselected){
            div_ClearAll.style.display='block';
            div_Filter.style.display='none';
        }
        else{
            div_ClearAll.style.display='none';
            div_Filter.style.display='block';
        }
    }

    const handleCheckAllCheckboxes = (event) => {      
        const allcheckboxChecked = event.target.checked;
        var checkboxes = document.getElementsByName('chk_item[]');
        if (allcheckboxChecked) {                   
            for (var i in checkboxes) {
                if (checkboxes[i].checked == false) {
                    checkboxes[i].checked = true;                   
                }
            }                      
        }
        else {                      

            for (var i in checkboxes) {
                if (checkboxes[i].checked == true) {
                    checkboxes[i].checked = false;
                   
                }
            }
        }

        filterdivvisibility();
    }  


    function unCheckAllCheckboxes() {
        var checkboxes = document.getElementsByName('chk_item[]');

        for (var i in checkboxes) {
            if (checkboxes[i].checked == true) {
                checkboxes[i].checked = false;
            }
        }
    }

    const handleCheckStatusChange =(e)=>{
        filterdivvisibility();
     }

     const handleAllClear=()=>{
        unCheckAllCheckboxes();
        filterdivvisibility();

        let checkbox=document.getElementById('flexCheckDefault');
        if(checkbox)
        {
            checkbox.checked=false;
        }
     }

    const handleStatusChange = (status) => {
        let _items = responseitems;
        let _filter_items = _items.filter((e) => e.PARCELSTATUS === status);
        setitems(_filter_items);
    }

  

    const handleItemImage = (e) => {
        let itemId = e.target.dataset.itemid;
        let item_image_source = e.target.dataset.itemimagesource;
        setItemImageSource(item_image_source);
    }


    const handleItemVideo = (e) => {
        let itemId = e.target.dataset.itemid;
        let item_video_source = e.target.dataset.itemvideosource;
        setItemVideoSource(item_video_source);
    }

    const handleItemCertificate = (e) => {
        e.preventDefault();
        let itemId = e.target.dataset.itemid;
        let item_certificate_source = e.target.dataset.itemcertificatesource;
        setItemCertificateSource(item_certificate_source);
    }
    
    const handleItemCart = (e) => {
        let itemId = e.target.dataset.itemid;        
        let itemInfo = items.filter((item) => item.ITEMID == itemId)[0];        
        getSelectedItemData(itemId,itemInfo);
    }

    const getSelectedItemData = async (itemId,itemInfo) => {
        setSelectedItemState(itemInfo);
        const data = await commonFunctionClass().getAlterNativeItem(token,navigate,itemId,_itemType.current);        
        setAlternateItemsState(data);
    }

    const handleCompareItem = async () => {
        setCompareItemsState([]);
        let checkboxes = document.getElementsByName('chk_item[]');
        let checkboxvals="";
        let indx = 0;
        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }
        
        if(indx<=1)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("Warning: Select item");
            setpopupDetailedMsg("Please select atleast 2 item");
            setShowPopupMsg(true);    
            return;
        }

        const data = await commonFunctionClass().getStoneInfo(token,navigate,checkboxvals,_itemType.current);
        setCompareItemsState(data);
    }

    const handleSentMail = async () => {
        // setLoading(true);
        let checkboxes = document.getElementsByName('chk_item[]');
        let checkboxvals="";
        let indx = 0;

        for (let i in checkboxes) {
            if (checkboxes[i].checked == true) {
                if (indx == 0) {
                    checkboxvals = checkboxvals + checkboxes[i].value;
                }
                else {
                    checkboxvals = checkboxvals + "," + checkboxes[i].value;
                }
                indx = indx + 1;
            }
        }

        if(indx <=0)
        {
            setpopupMsgType("warning");
            setpopupHeaderMsg("No Item selected");
            setpopupDetailedMsg("Please select atleast 1 item");
            setShowPopupMsg(true);
            return;
        }

        const data = await commonFunctionClass().sentMail(token,navigate,checkboxvals,_itemType.current,_formName.current,"");
        // setLoading(false);
        setpopupMsgType(data[0].MsgType);
        setpopupHeaderMsg(data[0].HeaderMsg);
        setpopupDetailedMsg(data[0].DetailedMsg);
        setShowPopupMsg(data[0].PopupMsg);
    }

    const popovercontent = (
        <div class="statusWrapper">
            <div class="row g-2">
            <div class="col-sm-6 col-6">
                    <div class="statusInner avl">
                        <a onClick={() => handleStatusChange('STOCK')}>A-Available</a>
                    </div>
                </div>
                {/* <div class="col-sm-6 col-6">
                    <div class="statusInner memo">
                        <a>M-Memo</a>
                    </div>
                </div> */}
                <div class="col-sm-6 col-6">
                    <div class="statusInner hold">
                        <a onClick={() => handleStatusChange('HOLD')}>H-Hold</a>
                    </div>
                </div>
                {/* <div class="col-sm-4 col-6">
                    <div class="statusInner upcmg">
                        <a>U-Upcoming</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner confirm">
                        <a>C-Confirm</a>
                    </div>
                </div>
                <div class="col-sm-4 col-6">
                    <div class="statusInner exclusive">parcelstatus
                        <a>E-Exclusive</a>
                    </div>
                </div> */}
            </div>
        </div>
    );

    const Item = (props) => {

        let itemJsonData = props.itemdata;

        let _parcelstatus='';
        
        if(itemJsonData.PARCELSTATUS.toUpperCase() == 'STOCK'){
            _parcelstatus='A';
        }
        else if(itemJsonData.PARCELSTATUS.toUpperCase() == 'HOLD'){
            _parcelstatus='H';
        }
        else if(itemJsonData.PARCELSTATUS.toUpperCase() == 'INVOICE'){
            _parcelstatus='C';
        }

        return (
            
            <li className="d-flex search-result--tableRow col-data--rowWrapper" key={itemJsonData.ITEMID}>
                {
                    itemFormControlRight.map(data => (                        
                        data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "MAINGRID"?
                            ''
                        :
                        data.CONTROLLABLE.toUpperCase()=="STATUS"?
                        <div className="data--nameOuter nrml-width" data-header="STATUS">
                            <div className="ps-3 d-flex justify-content-between">
                                {
                                        _parcelstatus == 'H' ? 
                                            <span className="data-name red">{_parcelstatus}</span>
                                        :
                                        _parcelstatus == 'C' ? 
                                            <span className="data-name green">{_parcelstatus}</span>
                                        :
                                            <span className="data-name green">{_parcelstatus}</span>
                                }                      

                                <input className="form-check-input rounded-0" type="checkbox"
                                    value={itemJsonData.ITEMID}
                                    name="chk_item[]" key={itemJsonData.ITEMID} id={itemJsonData.ITEMID}  onChange={handleCheckStatusChange} />
                            </div>
                        </div>
                        :
                        data.CONTROLLABLE.toUpperCase()=="DETAILS"?
                        <div className="nrml-width data--nameOuter" data-header="DETAILS">
                            <ul className="list-unstyled mb-0 d-flex justify-content-between">
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-img" aria-controls="item-img">
                                        <img width="18px" onClick={handleItemImage} data-itemid={itemJsonData.ITEMID} data-itemimagesource={itemJsonData.IMAGE} src="../assets/images/icons/image-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-video" aria-controls="diamond-video">
                                        <img width="20px" onClick={handleItemVideo} data-itemid={itemJsonData.ITEMID} data-itemvideosource={itemJsonData.V360VIDEO} src="../assets/images/icons/video-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-certificate" aria-controls="diamond-certificate">
                                        <img width="16px" onClick={handleItemCertificate} data-itemid={itemJsonData.ITEMID} data-itemcertificatesource={itemJsonData.CERTLINK} src="../assets/images/icons/certificate-icon.svg" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className='cursor_pointer' data-bs-toggle="offcanvas" data-bs-target="#diamond-cart" aria-controls="diamond-cart">
                                        <img width="18px" onClick={handleItemCart} data-itemid={itemJsonData.ITEMID} src="../assets/images/icons/bag-icon.svg" alt="" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        :                        
                        <div className="data--nameOuter nrml-width" data-header={t(data.CONTROLLABLE)}>
                            <span className="data-name">{itemJsonData[data.CONTROLNAME]}</span>
                        </div>
                    ))
                }                
                <button className="view-all--reasult">View All</button>
            </li>
        )
    };

    const AlterNativeItems = (props) => {
        let itemJsonData = props.itemdata;
        
        return (
            <div>
                <div className="prod_heading prod-max-width">
                    <img src={itemJsonData.IMAGE} className="img-fluid prod_img" alt=""/>
                </div>
                {
                    itemFormControlRight.map(data => (
                        data.ISCONTROL == true?
                            ''
                        :
                        data.PARENTCONTROLNAME.toUpperCase() != "ALTERNATEGRID"?
                            ''
                        :
                            <div className="prod_heading prod-max-width">
                                <span className="prod_text">{itemJsonData[data.CONTROLNAME]}</span>
                            </div>
                    
                    ))
                }
                <div className="prod_heading prod-max-width">
                    <span className="prod_text"><a>VIEW DETAIL</a></span>
                </div>
            </div>
        )
    };

    const GridItem=(props)=>{        
        let itemJsonData = props.itemdata;

        let _parcelstatus='';
        if(itemJsonData.PARCELSTATUS.toUpperCase()=='STOCK'){
            _parcelstatus='A';
        }
        else if(itemJsonData.PARCELSTATUS.toUpperCase()=='HOLD'){
            _parcelstatus='H';
        }
        else if(itemJsonData.PARCELSTATUS.toUpperCase()=='INVOICE'){
            _parcelstatus='C';
        }

        return(
                <div className="col" >
                    <div className="border border-1 grid-view--wrapper">
                    <input className="form-check-input m-3 position-absolute rounded-0 start-0 top-0" type="checkbox"
                            value={itemJsonData.ITEMID}
                            name="chk_item[]" key={itemJsonData.ITEMID} id={itemJsonData.ITEMID}  onChange={handleCheckStatusChange} />
                        <div className="align-items-center d-flex grid-view--imgWrapper justify-content-center">
                            {
                                itemJsonData.IMAGE!=""?
                                <img src={itemJsonData.IMAGE} className="img-fluid" alt="" />
                                :<span className='d-block mb-5'>No Image Found</span>
                            }                           
                        </div>
                        <div className="grid-view--textWrapper d-flex">
                            <ul className="list-unstyled mb-0">
                                <li>{checkFormControlRightOrNo("SHAPENAME") == true? itemJsonData.SHAPENAME:''}</li>
                                <li className="batch">{checkFormControlRightOrNo("LOTID") == true? itemJsonData.LOTID:''}</li>
                                <li>
                                    {checkFormControlRightOrNo("CARAT_FORMATTED") == true? itemJsonData.CARAT_FORMATTED:''}&nbsp;
                                    {checkFormControlRightOrNo("COLORNAME") == true? itemJsonData.COLORNAME:''}&nbsp;
                                    {checkFormControlRightOrNo("CLARITYNAME") == true? itemJsonData.CLARITYNAME:''}&nbsp;
                                    {checkFormControlRightOrNo("CUT") == true? itemJsonData.CUT:''}&nbsp;
                                    {checkFormControlRightOrNo("POLISH") == true? itemJsonData.POLISH:''}&nbsp;
                                    {checkFormControlRightOrNo("SYMMETRY") == true? itemJsonData.SYMMETRY:''}&nbsp;
                                    {checkFormControlRightOrNo("FLUORESCENCE") == true? itemJsonData.FLUORESCENCE:''}
                                </li>
                            </ul>
                            <ul className="list-unstyled mb-0">
                                 <li>{checkFormControlRightOrNo("LAB_NAME") == true? itemJsonData.LAB_NAME:''}</li>
                                <li>{checkFormControlRightOrNo("AMOUNT_FORMATTED") == true? itemJsonData.AMOUNT_FORMATTED:''}</li>
                                {
                                    _parcelstatus=='H'?
                                        <li className="status hold">{checkFormControlRightOrNo("PARCELSTATUS") == true? _parcelstatus:''}</li>
                                    :
                                    _parcelstatus=='C'?
                                        <li className="status available">{checkFormControlRightOrNo("PARCELSTATUS") == true? _parcelstatus:''}</li>
                                    :
                                        <li className="status available">{checkFormControlRightOrNo("PARCELSTATUS") == true? _parcelstatus:''}</li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
         )
    }

    return (
        <Fragment>
            <Header />
            <Sidebar />

            <ToastContainer className="p-3" position="top-end">
                <Toast onClose={() => setShowPopupMsg(false)} bg={popupMsgType} className="d-inline-block m-1" show={ShowPopupMsg} delay={18000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{popupHeaderMsg}</strong>
                        <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body >{popupDetailedMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* <!-- Diamond Search Filter --> */}
            <section id="search-stone" className="diamond-search--filter p-1">
                <div className="search-stone">
                    <div className="row g-3" id="rglr-search">
                        <div className="col-lg-3 col-md-6">
                            <div className="select-stone--wrapper">
                                <div className="select-stone--label">{t('shape')}:</div>
                                <div className="select-stone--shape">
                                    <ul className="list-unstyled d-flex mb-0">
                                        {
                                            itemShapes.map(shape => (
                                                searchShapes.selectedshapes.includes(shape.MAPVALUE) ?
                                                    <li key={shape.MAPVALUE} id={shape.MAPVALUE} data-bs-toggle="tooltip" className='active' data-bs-placement="bottom" title={shape.SHAPENAME} aria-label={shape.SHAPENAME}>
                                                        <img src={shape.IMAGEURL} className="img-fluid px-2" alt="" onClick={handleShapeClick} data-shapemapvalue={shape.MAPVALUE} />
                                                    </li>
                                                    :
                                                    <li key={shape.MAPVALUE} id={shape.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" title={shape.SHAPENAME} aria-label={shape.SHAPENAME}>
                                                        <img src={shape.IMAGEURL} className="img-fluid px-2" alt="" onClick={handleShapeClick} data-shapemapvalue={shape.MAPVALUE} />
                                                    </li>
                                            ))                                            
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="select-stone--wrapper caret">
                                <div className="select-stone--label">{t('carat')}:</div>
                                <div className="select-stone--shape">
                                    <div className="row align-items-center">                            
                                        <div className="col-lg-12">
                                            <div className="select-stone--shape">
                                                <div className="form-group d-flex gap-2 flex-wrap align-items-end mb-2">
                                                    <div className="form-row col-3">
                                                        <input type="text" value={caratAddFrom} onChange={e => setCaratAddFrom(e.target.value)} placeholder={t('from')} className="form-control bg-white mb-0 customTextbox" />
                                                    </div>
                                                    <div className="form-row col-3">                                                        
                                                        <input type="text" value={caratAddTo} onChange={e => setCaratAddTo(e.target.value)} placeholder={t('to')} className="form-control bg-white mb-0 customTextbox" />
                                                    </div>
                                                    <div className="form-row col-3">
                                                        <button className="hero_btn custom_btn" onClick={handleAddCaratClick}>Add</button>
                                                    </div>
                                                </div>
                                                <div className="list-unstyled mb-0 d-flex gap-2 flex-wrap box_range">
                                                    {
                                                        searchCarats.selectedcarats.length>0
                                                        ?
                                                        searchCarats.selectedcarats.map(scarat=>(
                                                            <p className='custom_CaratAd' key={scarat}>
                                                                <span>{scarat}</span>
                                                                <button className='btn-close closeButton' data-caratmapvalue={scarat} onClick={ handleRemoveCaratClick } ></button>
                                                        </p>
                                                        ))
                                                        :''
                                                    }
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="select-stone--wrapper caret">
                                <div className="select-stone--label">{t('color')}:</div>
                                <div className="select-stone--shape">
                                    <ul className="list-unstyled d-flex mb-0">
                                        {
                                            itemColors.map(color => (
                                                searchColors.selectedcolors.includes(color.MAPVALUE) ?
                                                    <li key={color.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleColorClick} data-colormapvalue={color.MAPVALUE} title={color.COLORNAME}>{color.COLORNAME}</li>
                                                    :
                                                    <li key={color.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleColorClick} data-colormapvalue={color.MAPVALUE} title={color.COLORNAME}>{color.COLORNAME}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="select-stone--wrapper caret">
                                <div className="select-stone--label">{t('clarity')}:</div>
                                <div className="select-stone--shape">
                                    <ul className="list-unstyled d-flex mb-0">
                                        {
                                            itemClarities.map(clarity => (
                                                searchClarities.selectedclarities.includes(clarity.MAPVALUE) ?
                                                    <li key={clarity.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" className='active' onClick={handleClarityClick} title={clarity.CLARITYNAME} data-claritymapvalue={clarity.MAPVALUE} >{clarity.CLARITYNAME}</li>
                                                    :
                                                    <li key={clarity.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleClarityClick} title={clarity.CLARITYNAME} data-claritymapvalue={clarity.MAPVALUE} >{clarity.CLARITYNAME}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Diamond Search Filter --> */}

            {/* <!-- Diamond List Name --> */}
            <section id="id-diamond--name" className="px-3">
                <div className="id-diamond--name d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">{t('diamondlist')} ( <span>{totalitems}</span> )</h4>
                   
                      <div id="div_ClearAll" style={{'display':'none'}}>
                         <button className="btn btn-dark rounded-0 px-4 py-2 small" onClick={handleAllClear}>Clear All</button>
                      </div>
                    
                    <div id="div_Filter" style={{'display':'block'}}>
                        <div className="filter-count--wrapper" >
                            <button className="clear-fliter" onClick={handleClearAllSearchParams} data-bs-toggle="tooltip" data-bs-placement="bottom" aria-label="Remove">
                                <span>Filter 1 <img src="../assets/images/icons/eye-icon.svg" width="15px" alt="" /></span>
                            </button>
                            <button className="grid-view" onClick={handleDataDisplay}></button>
                        </div>
                    </div>    

                </div>
            </section>
            {/* <!-- End Diamond List Name --> */}        
          
            <div id="divData">
                {
                    loading?
                      <div className='loader'>                      
                         <img src='../../assets/images/insideLoader.gif'></img>                        
                      </div>
                    :
                    <div>                        
                       {
                        showTabularView ?
                        <section id="serach-result--outerWrapper" >
                            <div className="search-result--outerWrapper">
                                <ul className="list-unstyled mb-0 search-result--tableWrapper">
                                    <li className="d-flex text-white search-result--tableRow">
                                        {                                            
                                            itemFormControlRight.map(data => (                                                
                                                data.ISCONTROL == true?
                                                ''
                                                :
                                                data.PARENTCONTROLNAME.toUpperCase() != "MAINGRID"?
                                                    ''
                                                :
                                                data.CONTROLLABLE.toUpperCase()=="STATUS"?                                                
                                                <div className="heading--nameOuter bg-dark nrml-width">
                                                    <span className="heading-name">{t(data.CONTROLLABLE)}</span>
        
                                                    <Popover content={popovercontent} trigger="hover">
                                                        <img src="../assets/images/info.png" width="20px" alt="" />
                                                    </Popover>
        
                                                    <input className="form-check-input mt-0 rounded-0"
                                                        onChange={handleCheckAllCheckboxes}
                                                        type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                                :
                                                <div className="heading--nameOuter bg-dark nrml-width">                                                    
                                                    <span className="heading-name">{t(data.CONTROLLABLE)}</span>
                                                </div>  
                                               
                                            ))
                                        }
                                    </li>
                                    {
                                        currentRecords.map(item => (
                                            <Item itemdata={item} key={item.itemid}/>
                                        ))
                                    }
                                </ul>
                            </div>
                        </section>
                        :
                        <section id="grid-view" className='overflow-auto'>
                            <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                                {
                                    currentRecords.map(item => (
                                        
                                        <GridItem itemdata={item} key={item.itemid} />
                                    ))
                                }
                            </div>
                        </section>
                       }
                    </div>
                }
            </div>


            {/* <!-- Modify Search Button --> */}
            <div className="modify-search--btnWrapper d-block">
                <div className="container max-container">
                    <div className="d-flex justify-content-between">
                        {/* <!-- <div className="col-9"> --> */}
                        <div className="modify-search--btnWrapper-outer-1st">
                            <div className="modify-search--btnWrapper--left">
                                {checkFormControlRightOrNo("SEARCHBUTTON") == true? <button onClick={handleSearchNow}>{t('search')}</button> :''}
                                {checkFormControlRightOrNo("MODIFYSEARCH") == true? <button className="d-lg-inline-block d-none"  onClick={handleSearchAdvance}>{t('modifysarch')}</button> :''}
                                {checkFormControlRightOrNo("ADDANOTHERSEARCH") == true? <button className="d-lg-inline-block d-none"  onClick={handleAnotherSearchAdvance}>{t('addanothersearch')}</button> :''}
                                {checkFormControlRightOrNo("ADDTOCART") == true? <button onClick={handleCart}> {t('cart')}</button>  :''}
                                {checkFormControlRightOrNo("ADDTOWATCH") == true? <button className="d-lg-inline-block d-none"  onClick={handleWatch}>{t('watch')}</button>:''}
                                {checkFormControlRightOrNo("MORE") == true? 
                                    <span className="dropdown">
                                        <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t('more')}
                                        </button>
                                        <ul className="dropdown-menu p-0 rounded-0">
                                        {checkFormControlRightOrNo("ADDANOTHERSEARCH") == true? <a className="d-lg-none dropdown-item" onClick={handleAnotherSearchAdvance}>{t('addanothersearch')}</a> :''}
                                        {checkFormControlRightOrNo("ADDTOWATCH") == true? <a className="d-lg-none dropdown-item" onClick={handleWatch}>{t('watch')}</a>:''}
                                        {checkFormControlRightOrNo("MODIFYSEARCH") == true? <li><a className="d-lg-none dropdown-item" onClick={handleSearchAdvance}>{t('modifysarch')}</a></li>  :''}
                                            {checkFormControlRightOrNo("COMPARE") == true? <li><a className="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#diamond-compare" aria-controls="diamond-cart" href="#" onClick={handleCompareItem}>{t('compare')}</a></li> :''}                                            
                                            {checkFormControlRightOrNo("EXPORTEXCEL") == true? <li><a className="dropdown-item" href="#" onClick={handleExportToExcel}>{t('exportexcel')}</a></li> :''}
                                            {checkFormControlRightOrNo("SHARE") == true? <li><a className="dropdown-item" href="#" onClick={handleSentMail}>{t('share')}</a></li> :''}
                                        </ul>
                                    </span>
                                    :
                                    ''
                                }
                            </div>
                            {/* <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                <span className="d-block">Search Stone</span>
                            </button> */}
                        </div>
                        {/* <!-- </div> --> */}
                        {/* <!-- <div className="col-3"> --> */}
                        {/* <div className="modify-search--btnWrapper-outer-2nd">
                            <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/search-icon.svg" width="18px" alt="" /></span>
                                <span className="d-block">Confirm Stone</span>
                            </button>
                        </div> */}
                        <div className="modify-search--btnWrapper-outer-3rd">
                            <div className="modify-search--btnWrapper--right">
                                <nav className="d-flex gap-2" aria-label="Page navigation example">
                                    <select name="selectRecordsPerPage" id="selectRecordsPerPage" onChange={handleChageRecordsPerPage} className="form-select me-3 pageJump">
                                        <option value="100" selected>100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                    </select>
                                    <Pagination
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </nav>

                                <select name="" id="" className="form-select d-none">
                                    <option value="" disabled selected>Select Saved Search</option>
                                    <option value="">Test Search</option>
                                </select>

                            </div>
                            {/* <button className="mob-menu-btn">
                                <span className="d-block"><img src="../assets/images/icons/foot_menu.svg" width="18px" alt="" /></span>
                                <span className="d-block">More</span>
                            </button> */}
                        </div>
                        {/* <!-- </div> --> */}
                    </div>
                </div>
            </div>

            
            <StoneInformation 
                            ItemType={_itemTypeState} 
                            FormName={_formNameState} 
                            ItemTypeName={_itemTypeNameState} 
                            Category={_categoryState} 
                            ItemFormControlRight= {itemFormControlRight}
                            SelectedItem = {selectedItemState}
                            AlternateItems = {alternateItemsState}
                            ItemImageSource = {itemImageSource}
                            ItemVideoSource = {itemVideoSource}
                            ItemCertificateSource = {itemCertificateSource}
                            CompareItems = {compareItemsState}
            />

        </Fragment>
    )
}
export default ItemList;

