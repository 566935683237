
import React, { useEffect, useState, Fragment } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl } from '../constants/global'
import MyAccNav from '../components/MyAccNav'
import { useNavigate } from 'react-router-dom';
import  commonFunctionClass from '../constants/commonFunctionClass';

function MyAccountSummary() {
    const navigate = useNavigate();
    const { token, setToken } = useToken();
    
    const [cartItemCount, setCartItemCount] = useState(0);  
    const [cartItemCaratSum, setCartItemCaratSum] = useState("0.00");  
    const [cartItemAmountSum, setCartItemAmountSum] = useState("0.00");  

    const [watchItemCount, setWatchItemCount] = useState(0);  
    const [watchItemCaratSum, setWatchItemCaratSum] = useState("0.00");  
    const [watchItemAmountSum, setWatchItemAmountSum] = useState("0.00");  

    const [orderItemCount, setOrderItemCount] = useState(0);  
    const [orderItemCaratSum, setOrderItemCaratSum] = useState("0.00");  
    const [orderItemAmountSum, setOrderItemAmountSum] = useState("0.00");  

    const [saveSerches, setSaveSerches] = useState([]);   
    const [saveSercheParams, setSaveSercheParams] = useState([]);    

    const [recentSerches, setRecentSerches] = useState([]);   
    const [recentSercheParams, setRecentSercheParams] = useState([]);    
    
    const CommonParameterFilter = async (_mode) =>
    {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        var data=[];
        await axios.get(`${BaseUrl}/api/v1/myaccount/getSummaryData?Mode=${_mode}`,config).then((response) => {
            data = JSON.parse(response.data.data);
        });

        if(_mode.toUpperCase() == "CART")
        {
            setCartItemCount(data[0].QTY);
            setCartItemCaratSum(data[0].CARAT)
            setCartItemAmountSum(data[0].AMOUNT)
        }
        else if(_mode.toUpperCase() == "WATCH")
        {
            setWatchItemCount(data[0].QTY);
            setWatchItemCaratSum(data[0].CARAT)
            setWatchItemAmountSum(data[0].AMOUNT)
        }
        else if(_mode.toUpperCase() == "ORDER")
        {
            setOrderItemCount(data[0].QTY);
            setOrderItemCaratSum(data[0].CARAT)
            setOrderItemAmountSum(data[0].AMOUNT)
        }
        else if(_mode.toUpperCase() == "SAVED SEARCH")
        {
            setSaveSerches(data);
        }
        else if(_mode.toUpperCase() == "RECENT SEARCH")
        {
            setRecentSerches(data);
        }
    }

    function logOut() { 
        localStorage.clear() 
        navigate('/');
      }
    

    useEffect(() => {
        CommonParameterFilter("CART");
        CommonParameterFilter("WATCH");
        CommonParameterFilter("ORDER");
        CommonParameterFilter("SAVED SEARCH");
        CommonParameterFilter("RECENT SEARCH");
    }, []);

    const SavedSearchParamsItem = (props) => {
        let itemJsonData = props.itemdata;

        return (
            <div className="search-inputs d-flex flex-wrap">
                <div className="details">
                    <span>Stock: </span>
                    <span>{itemJsonData.ITEMTYPENAME}</span>
                </div>
                <div className="details">
                    <span>Shape: </span>
                    <span>{itemJsonData.SHAPE}</span>
                </div>
                <div className="details">
                    <span>Color: </span>
                    <span>{itemJsonData.COLOR}</span>
                </div>
                <div className="details">
                    <span>Clarity: </span>
                    <span>{itemJsonData.CLARITY}</span>
                </div>
                <div className="details">
                    <span>Carat: </span>
                    <span>{itemJsonData.CARAT}</span>
                </div>
            </div>
        )
    };


    const RecentSearchParamsItem = (props) => {
        let itemJsonData = props.itemdata;

        return (
            <div className="search-inputs d-flex flex-wrap">
                <div className="details">
                    <span>Stock: </span>
                    <span>{itemJsonData.ITEMTYPENAME}</span>
                </div>
                <div className="details">
                    <span>Shape: </span>
                    <span>{itemJsonData.SHAPE}</span>
                </div>
                <div className="details">
                    <span>Color: </span>
                    <span>{itemJsonData.COLOR}</span>
                </div>
                <div className="details">
                    <span>Clarity: </span>
                    <span>{itemJsonData.CLARITY}</span>
                </div>
                <div className="details">
                    <span>Carat: </span>
                    <span>{itemJsonData.CARAT}</span>
                </div>
            </div>
        )
    }



    return (
        <Fragment>

            <Header />
            <Sidebar />
            <section id="profile-pageSection">
              <MyAccNav/>              
              <div className="profile-pageSection mt-2 mb-4">
                 <div className="max-container container">
                     {/* <!-- Summary --> */}
                     <div className="row">
                        {/* <!-- Profile Baneer --> */}
                        <div className="col-lg-7 col-ms-7">
                            <div className="profile-pageSectionImg">
                                <img src="../assets/images/profile-img.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* <!-- End Profile Banner --> */}
                        {/* <!-- Profile Details --> */}
                        <div className="col-lg-5 col-ms-5">
                            <div className="profile-pageSection-right">
                                <div className="profile-heading-wrapper d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0 fs-4">Accounts Details</h6>
                                    <a href='' className='profile-button-logout' onClick={() => logOut()}>Sign Out</a>
                                </div>
                                <div className="profile-side--wrapper">
                                    <ul className="list-unstyled mb-0 profile_outerWrapper_ul d-flex flex-wrap mt-3">
                                        <li className="profile_outerWrapper_li">
                                            <div className="profile-cartWrapper">
                                                <h6 className="profile_innerHeading" data-cart-count="2">My Cart</h6>
                                                <ul className="list-unstyled mb-0 d-flex flex-wrap gap-2 cart-number-count">
                                                    <li><small>PIECES</small><small>{cartItemCount}</small></li>
                                                    <li><small>CARAT</small><small>{cartItemCaratSum}</small></li>
                                                    <li><small>VALUE</small><small>{cartItemAmountSum}</small></li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="profile_outerWrapper_li">
                                            <div className="profile-cartWrapper">
                                                <h6 className="profile_innerHeading" data-cart-count="2">My Watchlist</h6>
                                                <ul className="list-unstyled mb-0 d-flex flex-wrap gap-2 cart-number-count">
                                                    <li><small>PIECES</small><small>{watchItemCount}</small></li>
                                                    <li><small>CARAT</small><small>{watchItemCaratSum}</small></li>
                                                    <li><small>VALUE</small><small>{watchItemAmountSum}</small></li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="profile_outerWrapper_li">
                                            <div className="profile-cartWrapper">
                                                <h6 className="profile_innerHeading" data-cart-count="2">My Order</h6>
                                                <ul className="list-unstyled mb-0 d-flex flex-wrap gap-2 cart-number-count">
                                                    <li><small>PIECES</small><small>{orderItemCount}</small></li>
                                                    <li><small>CARAT</small><small>{orderItemCaratSum}</small></li>
                                                    <li><small>VALUE</small><small>{orderItemAmountSum}</small></li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="profile_outerWrapper_li">
                                            <div className="profile-cartWrapper">
                                                <h6 className="profile_innerHeading achr" data-cart-count="2">
                                                    <a href="" onClick={() => logOut()}>Sign Out</a>
                                                </h6>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <!-- End Profile Details --> */}
                        {/* <!-- Saved Searches --> */}
                        <div className="col-lg-4">
                            <div className="saved-search--wrapper">
                                <h5>Saved Search</h5>
                                <ul className="list-unstyled">
                                {
                                saveSerches.map(savesearch => (
                                    <li>
                                        <div className="search-name d-flex justify-content-between">
                                            <h6>{savesearch.SEARCHNAME}</h6>
                                            <span><small>{savesearch.CREATEDDATE}</small><small>{savesearch.CREATEDTIME}</small></span>
                                        </div>
                                        <SavedSearchParamsItem userSearchId={savesearch.USERSEARCHID} key={savesearch.USERSEARCHID} itemdata={savesearch}/>
                                    </li>
                                ))
                                }
                                </ul>
                            </div>
                        </div>
                        {/* <!-- End Saved Search --> */}
                        {/* <!-- Recent Search --> */}
                        <div className="col-lg-4">
                            <div className="saved-search--wrapper">
                                <h5>Recent Search</h5>
                                <ul className="list-unstyled">
                                    {
                                        recentSerches.map(recentsearch => (
                                        <li>
                                            <div className="search-name d-flex justify-content-between">
                                                {/* <h6>{savesearch.SEARCHNAME}</h6> */}
                                                <span><small>{recentsearch.CREATEDDATE}</small><small>{recentsearch.CREATEDTIME}</small></span>
                                            </div>
                                            <RecentSearchParamsItem userSearchId={recentsearch.USERSEARCHID} key={recentsearch.USERSEARCHID} itemdata={recentsearch}/>
                                        </li>
                                        ))
                                    }                                    
                                </ul>
                            </div>
                        </div>
                        {/* <!-- End Recent Search --> */}

                        {/* <!-- Recent Activity --> */}
                        {/* <div className="col-lg-4">
                            <div className="saved-search--wrapper">
                                <h5>Recent Activity</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="search-name d-flex justify-content-between mb-0">
                                            <h6>diamond-search (create)</h6>
                                            <span><small>10/09/2022</small><small>10:30 PM</small></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <!-- End Recent Activity --> */}
                    </div>
                    {/* <!-- End Summary --> */}
                 </div>
              </div>

            </section>
        


        </Fragment>
    )
}
export default MyAccountSummary;