import React, {useEffect,useState, Fragment, useRef } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer'

import { useNavigate,useParams } from 'react-router-dom';
import useToken from '../components/useToken';
import axios from "axios";
import { BaseUrl, HomeUrl } from '../constants/global';
import { useTranslation } from 'react-i18next';
import  commonFunctionClass from '../constants/commonFunctionClass';

function Index() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    
    let { searchid } = useParams();

    const [jsonData, setjsonData] = useState([]);
    
    const _itemType = useRef('1');
    const _formName = useRef('MOSCOW STOCK');
    const _itemTypeName = useRef('MOSCOW STOCK');
    const _category = useState('HOMEPAGE');
    const [itemFormControlRight, setitemFormControlRight] = useState([]);
    const _searchItemParameterList = useRef({});

    const {token, setToken } = useToken();
    const [itemShapes, setitemShapes] = useState([]);
    const [itemCarats, setitemCarats] = useState([]);
    const [itemColors, setitemColors] = useState([]);
    const [itemClarities, setitemClarities] = useState([]);

    const [searchCarats, setSearchCarats] = useState({selectedcarats:[]});
    const [searchColors, setSearchColors] = useState({selectedcolors:[]});

    const [searchShapes, setSearchShapes] = useState({selectedshapes:[]});
    const [searchClarities, setSearchClarities] = useState({selectedclarities:[]});
 
    const [caratAddFrom, setCaratAddFrom] = useState('');
    const [caratAddTo, setCaratAddTo] = useState('');

    const colorType = useRef("WHITE");
    
    const bindSearchData = async () =>
    {   
        const { selectedcarats } = searchCarats;
        let _caratMapValues = selectedcarats.join(',');

        const { selectedcolors } = searchColors;
        let _colorMapValues = selectedcolors.join(',');

        const { selectedshapes } = searchShapes;
        let _shapeMapValues = selectedshapes.join(',');

        const { selectedclarities } = searchClarities;
        let _clarityMapValues = selectedclarities.join(',');
                
        let data = await getSearchParam(searchid);

        _searchItemParameterList.current["shape_Params"] = _shapeMapValues;
        _searchItemParameterList.current["carat_Params"] = _caratMapValues;
        _searchItemParameterList.current["color_Params"] = _colorMapValues;
        _searchItemParameterList.current["clarity_Params"] = _clarityMapValues;
        _searchItemParameterList.current["itemType"] = _itemType.current;
        _searchItemParameterList.current["colorType_Params"] = colorType.current;
    }
    
    const getSearchParam = async () =>
    {
        const data = await commonFunctionClass().getSearchParam(token,_itemType.current,searchid);        
        _searchItemParameterList.current=data;
    }

    const reloadSearchData = async () =>
    {
        let data1 = await getSearchParam();
        const shapesArray = _searchItemParameterList.current.shape_Params.split(",");
        setSearchShapes({ selectedshapes: shapesArray })

        const clarityArray = _searchItemParameterList.current.clarity_Params.split(",");
        setSearchClarities({ selectedclarities: clarityArray });

        const colorArray = _searchItemParameterList.current.color_Params.split(",");
        setSearchColors({ selectedcolors: colorArray });

        if(_searchItemParameterList.current.carat_Params!=''){
            const caratArray = _searchItemParameterList.current.carat_Params.split(",");    
            setSearchCarats({selectedcarats:caratArray});
        }
                
        if(_searchItemParameterList.current.colorType_Params!=''){
            colorType.current = _searchItemParameterList.current.colorType_Params;
        }
       
    }

    const CommonParameterFilter = async (_xtraParam, _mode) =>
    {
        const data = await commonFunctionClass().CommonParameterFilter(token,_itemType.current,_category.current,_formName.current,_xtraParam,_mode);
        
        if(_mode.toUpperCase() == "FORMCONTROL")
        {
            setitemFormControlRight(data);
        }
        else if(_mode.toUpperCase() == "SHAPE")
        {
            setitemShapes(data);
        }
        else if(_mode.toUpperCase() == "CARAT")
        {
            setitemCarats(data);
        }
        else if(_mode.toUpperCase() == "COLOR")
        {
            setitemColors(data);
        }
        else if(_mode.toUpperCase() == "CLARITY")
        {
            setitemClarities(data);
        }
    }

    useEffect(() => {
        CommonParameterFilter('',"FORMCONTROL");
        CommonParameterFilter('',"SHAPE");
        CommonParameterFilter('',"CARAT");
        CommonParameterFilter('',"CARAT");
        
        let xtraParams= `<COLORTYPE>${colorType.current}</COLORTYPE>`;
        CommonParameterFilter(xtraParams,"COLOR");
        CommonParameterFilter('',"CLARITY");
        
        searchid=-1;

    },[]);

 
    const SearchData = async (searchtype) =>
    {   
        let _token = token;
       
        if(searchtype != 'imagesearch')
        {
            let data =  await bindSearchData();
        }

        const saerchconfig = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.post(
            `${BaseUrl}/api/v1/items/putsearch/${searchid}`,
            _searchItemParameterList.current,
            saerchconfig
        ).then((response) => {    
                    
            if(response.status==200)
            {
                if(searchtype=='Search' || searchtype == 'imagesearch'){
                    navigate('/itemlist/'+response.data.searchid+'');
                }     
                else if(searchtype=='AdvanceSearch'){
                    //alert('AdvanceSearch');
                    navigate('/itemsearch/'+response.data.searchid+'');
                }     
            }
            else if(response.status==401)
            {           
                localStorage.clear();
                window.location.href=HomeUrl;        
                
            }
        }).catch((error) => {
            console.error(error.response.status);
            if(error.response.status==401)
            {
                localStorage.clear();
                window.location.href=HomeUrl;    
            }
            
         });
    }

    const handleSearchNow = () => {
        SearchData('Search');
    }

    const handleAdvanceSearch = () => {
        SearchData('AdvanceSearch');
      }

      
    const handleCaratClick=(e)=>{
       const { selectedcarats } = searchCarats;

       let carat_mapval=e.target.dataset.caratmapvalue;      
     
       let check=selectedcarats.includes(carat_mapval)
       if(check){
           setSearchCarats({
            selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
            })
            e.target.className='';
        }
        else{
            setSearchCarats({
                selectedcarats:[...selectedcarats,carat_mapval]
              })
              e.target.className='active';
        }     
    }


    const handleColorClick=(e)=>{
        const { selectedcolors } = searchColors;
 
        let color_mapval=e.target.dataset.colormapvalue;      
      
        let check=selectedcolors.includes(color_mapval)
        if(check){
            setSearchColors({
                selectedcolors: selectedcolors.filter((e) => e !== color_mapval)
             })
             e.target.className=''; 
         }
         else{
             setSearchColors({
                selectedcolors:[...selectedcolors,color_mapval]
               })
               e.target.className='active';
         }     
     }

     const handleShapeClick=(e)=>{
        const { selectedshapes} = searchShapes;
 
        let shape_mapval=e.target.dataset.shapemapvalue;      
        
        const el = document.getElementById(shape_mapval);            

        let check=selectedshapes.includes(shape_mapval)
        if(check){         
            setSearchShapes({
                selectedshapes: selectedshapes.filter((e) => e !== shape_mapval)
             })
             el.className='';
         }
         else{
             setSearchShapes({
                selectedshapes:[...selectedshapes,shape_mapval]
               })
               el.className='active';
         }     
     }

     const handleClarityClick=(e)=>{
        const { selectedclarities} = searchClarities;
 
        let clarity_mapval=e.target.dataset.claritymapvalue;      
      
        let check=selectedclarities.includes(clarity_mapval)
        if(check){
            setSearchClarities({
                selectedclarities: selectedclarities.filter((e) => e !== clarity_mapval)
             })
             e.target.className=''; 
         }
         else{
             setSearchClarities({
                selectedclarities:[...selectedclarities,clarity_mapval]
               })
               e.target.className='active';
         }     
     }


     const handleRemoveCaratClick=(e)=>{
        const { selectedcarats } = searchCarats; 
        let carat_mapval=e.target.dataset.caratmapvalue;        
        let check=selectedcarats.includes(carat_mapval);
    
        if(check){
            setSearchCarats({
             selectedcarats: selectedcarats.filter((e) => e !== carat_mapval)
             });
             
         }           
         
     }

    const handleAddCaratClick=(e)=>{
        const { selectedcarats } = searchCarats;
     
        if(caratAddFrom!='' && caratAddTo!='')
        {
            if(!isNaN(caratAddFrom) && !isNaN(caratAddTo) )
            {
                let carat_mapval=caratAddFrom +'-'+caratAddTo;     
                let check=selectedcarats.includes(carat_mapval)
                if(!check){
                    setSearchCarats({
                        selectedcarats:[...selectedcarats,carat_mapval]
                    })
    
                    setCaratAddFrom('');
                    setCaratAddTo('');           
                }
             }
        }
          
     }
    
     const handleImageClick = async (e) => {
        // alert(e.target.dataset.value + " - " + e.target.dataset.shape) ;


        let _caratMapValues = e.target.dataset.value;
        let _shapeMapValues = e.target.dataset.shape;

        let data = await getSearchParam("-1");

        _searchItemParameterList.current["shape_Params"] = _shapeMapValues;
        _searchItemParameterList.current["carat_Params"] = _caratMapValues;
        
        SearchData('imagesearch');
     }


    return (
        <Fragment>

            <Header />
            <Sidebar />

            <div id="hero_section">
                <div class="hero_updated">
                    <div class="row g-0">
                        <div class="col-sm-7">
                            <div class="div_60vh">
                                <img src="../assets/images/Yellow-Diamonds-Buying-Guide-51c8b3f4-56e8-44d9-9114-943c808b6281.jpg" class="img-fluid
                                w-100" alt=""/>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="row g-0">
                                <div class="col-6">
                                    <div class="div_30vh">
                                       <a href='#' onClick={handleImageClick}><img data-value="2.00-9.99" data-shape='3' src="../assets/images/2UP1.jpg" alt="" class="img-fluid w-100"/></a> 
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="div_30vh">
                                    <a href='#' onClick={handleImageClick}><img data-value="0.30-1.99" data-shape='3' src="../assets/images/2DOWN1.jpg" alt="" class="img-fluid w-100"/></a>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="div_30vh">
                                    <a href='#' onClick={handleImageClick}><img data-value="2.00-9.99" data-shape='OTHER---BG---RC---TR---TSC---,AS,PS,EM,CU,MQ,OV,PR,RAD,HS,'  src="../assets/images/2UPFANCY1.jpg" alt="" class="img-fluid w-100"/></a>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="div_30vh">
                                    <a href='#' onClick={handleImageClick}><img data-value="0.30-1.99" data-shape='OTHER---BG---RC---TR---TSC---,AS,PS,EM,CU,MQ,OV,PR,RAD,HS,'  src="../assets/images/2DOWNFANCY1.jpg" alt="" class="img-fluid w-100"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="search-stone">
                <div className="container max-container">
                    <div className="search-stone">
                        <div className="heading text-center">
                            <h2 className="mb-5">{t('searchdiamond')}</h2>
                        </div>

                        <div className="row g-4" id="rglr-search">
                            <div className="col-lg-6 col-md-6">
                                <div className="select-stone--wrapper">
                                    <div className="select-stone--label">{t('shape')}:</div>
                                    <div className="select-stone--shape">
                                        <ul className="list-unstyled d-flex mb-0">
                                            {
                                                itemShapes.map(shape=>(
                                                    <li key={shape.MAPVALUE}  id={shape.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" title={shape.SHAPENAME} >
                                                    <img  src={shape.IMAGEURL} className="img-fluid px-2 d_iconI" alt="" data-shapemapvalue={shape.MAPVALUE} onClick={handleShapeClick}  />
                                                    </li>
                                               ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="select-stone--wrapper caret">
                                    <div className="select-stone--label">{t('carat')}:</div>

                                      <div className="row align-items-end">
                                        <div className="form-row col-lg-2 col-3">
                                                <label htmlFor="">From</label>
                                                <input type="text" value={caratAddFrom} onChange={e => setCaratAddFrom(e.target.value)}  className="form-control bg-white mb-0" />
                                            </div>
                                            <div className="form-row col-lg-2 col-3">
                                                <label htmlFor="">To</label>
                                                <input type="text" value={caratAddTo} onChange={e => setCaratAddTo(e.target.value)}  className="form-control bg-white mb-0" />
                                            </div>
                                            <div className="form-row col-6 col-lg-3 pt-4">
                                              <button className="hero_btn" onClick={handleAddCaratClick}>Add Carat</button>                                              
                                            </div>
                                            <div className='list-unstyled mb-0 mt-2 d-flex gap-2 flex-wrap box_range'>
                                            {
                                                searchCarats.selectedcarats.length>0
                                                ?
                                                 searchCarats.selectedcarats.map(scarat=>(
                                                    <p key={scarat}>
                                                        <span>{scarat}</span>
                                                        <button className='btn-close' data-caratmapvalue={scarat} onClick={ handleRemoveCaratClick } ></button>
                                                   </p>
                                                 ))
                                                 :''
                                            }
                                        </div>                                            
                                    </div>

                                    <div className="select-stone--shape">
                                        <ul className="list-unstyled d-flex mb-0 mt-2">
                                            {
                                                itemCarats.map(carat=>(
                                                     <li key={carat.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" data-caratmapvalue={carat.MAPVALUE} title={carat.CARATNO} onClick={handleCaratClick}>{carat.DISPLAYNAME}</li>
                                               ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="select-stone--wrapper caret">
                                    <div className="select-stone--label">{t('color')}:</div>
                                    <div className="select-stone--shape">
                                        <ul className="list-unstyled d-flex mb-0">
                                            {
                                                itemColors.map(color=>(
                                                    <li key={color.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" data-colormapvalue={color.MAPVALUE} title={color.COLORNAME} onClick={handleColorClick}>{color.COLORNAME}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="select-stone--wrapper caret">
                                    <div className="select-stone--label">{t('clarity')}:</div>
                                    <div className="select-stone--shape">
                                        <ul className="list-unstyled d-flex mb-0">
                                            {
                                                itemClarities.map(clarity=>(
                                                    <li key={clarity.MAPVALUE} data-bs-toggle="tooltip" data-bs-placement="bottom" title={clarity.CLARITYNAME} data-claritymapvalue={clarity.MAPVALUE} onClick={handleClarityClick} >{clarity.CLARITYNAME}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sbtn-wrapper justify-content-between mt-5 mb-4">
                            <button className="btn" id="adv-btn" onClick={handleAdvanceSearch}>{t('viewadvancesearch')}</button>
                            <button className="btn" type='button' onClick={handleSearchNow}>{t('searchnow')}</button>
                        </div>

                      
                    </div>
                </div>

              
            </section>


            <Footer />
          
        </Fragment>

    )
}
export default Index;