import React, { Fragment,useState } from 'react'
import { 
  Link,useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
function MyAccNav() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  return (
    <Fragment>
      <div className='container max-container mb-3 p-1'>
        <ul className="nav nav-pills cartTab gap-md-3 gap-sm-2 gap-1">
          <li className="nav-item" >
            <Link className={location.pathname=="/myaccount/summary"?"nav-link active":"nav-link"} id="nav_cart"  aria-current="page" to="/myaccount/summary">Summary</Link>
          </li>
          <li className="nav-item" >          
            <Link className={location.pathname=="/myaccount/detail"?"nav-link active":"nav-link"}  id="nav_order"   to="/myaccount/detail"  >Your Detail</Link>
          </li>
          <li className="nav-item" >          
            <Link className={location.pathname=="/myaccount/changepassword"?"nav-link active":"nav-link"}  id="nav_watch"   to="/myaccount/changepassword"  >Change Password</Link>
          </li>
        </ul>
      </div>
    </Fragment>
  )
}
export default MyAccNav;